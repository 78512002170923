/* eslint-disable react/prop-types */
import { Box, Button, Typography } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { useApprovedProductFinalPriceMutation } from "@/services/products";
import { toast } from "react-toastify";
import { moneyFormat } from "@/utils/moneyFormat";

const ApprovedProductPrice = (props) => {
  const [approvedProductFinalPrice, { isLoading }] =
    useApprovedProductFinalPriceMutation();

  return (
    <ModalComponent>
      <Box sx={{ p: 4, display: "flex", flexDirection: "column" }}>
        <Typography fontSize={18}>
          آیا{" "}
          {props?.price ? (
            <Typography fontWeight="bold" component="span" color="green">
              {moneyFormat(props?.price)} ریال
            </Typography>
          ) : (
            ""
          )}{" "}
          را به عنوان قیمت نهایی محصول{" "}
          <Typography fontWeight="bold" component="span" color="red">
            {props?.product}
          </Typography>{" "}
          تایید می نمایید؟
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={props?.onClose}
            variant="outlined"
            sx={{ mt: 4, mx: 1 }}
          >
            بستن
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => {
              approvedProductFinalPrice({
                productPriceId: props?.id,
                data: { price: props?.price, approved: true },
              })
                .unwrap()
                .then(() => {
                  toast.success("با موفقیت انجام شد!");
                  props?.onClose();
                });
            }}
            variant="contained"
            sx={{ mt: 4, mx: 1 }}
          >
            تایید
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default ApprovedProductPrice;
