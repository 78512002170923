/* eslint-disable react/prop-types */
import ModalComponent from "../common/Modal/ModalComponent";
import { Box, Button, Typography } from "@mui/material";

const LastOpenContract = (props) => {
  return (
    <ModalComponent open={props.open}>
      <Box
        sx={{
          p: 4,
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography fontWeight="bold">
            شما یک قرارداد تکمیل نشده دارید، لطفا آن را{" "}
            <Typography fontWeight="bold" color="green" component="span">
              تکمیل
            </Typography>{" "}
            یا{" "}
            <Typography fontWeight="bold" color="error" component="span">
              حذف
            </Typography>{" "}
            نمایید.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <Button
            sx={{ mr: 1 }}
            onClick={() => {
              props?.onContinue();
              props?.onClose();
            }}
            variant="contained"
          >
            ادامه قرارداد
          </Button>
          <Button
            disabled={props?.deleteIsLoading}
            sx={{ ml: 1 }}
            onClick={() => {
              props?.onDelete(props?.onClose);
            }}
            variant="outlined"
          >
            حذف فرآیند
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default LastOpenContract;
