import { RolePath } from "@/constants/global";
import { Warning } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.authentication.user);
  const role = user?.user_info?.type?.name;

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Warning color="error" sx={{ width: 100, height: 100, mb: 2 }} />
      <Typography fontWeight="bold" variant="h6">
        ورود شما به این صفحه غیرمجاز است.
      </Typography>
      <Button
        onClick={() => navigate(RolePath?.[role])}
        sx={{ my: 4 }}
        variant="contained"
      >
        بازگشت به صفحه اصلی
      </Button>
    </Box>
  );
};

export default Unauthorized;
