/* eslint-disable react/prop-types */
import { Box, IconButton, Typography } from "@mui/material";

import { DeleteOutline, MoreHoriz } from "@mui/icons-material";

const ReportCard = ({
  src,
  reportNumber,
  receiveDate,
  farmName,
  cultivationType,
  expertName,
  disableDelete,
  handleMoreAction = () => {},
  // handlePacketAction = () => {},
  handleDeleteAction = () => {},
}) => {
  return (
    <Box
      sx={{
        width: "350px",
        height: "280px",
        borderRadius: 10,
        background: "#E8E9EA",
        p: 1,
      }}
    >
      <Box sx={{ height: "20%", px: 2, display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "85%",
          }}
        >
          <Typography
            fontWeight="bold"
            variant="subtitle2"
            className="text-ellipsis overflow-hidden whitespace-nowrap"
          >
            شماره گزارش: {reportNumber}
          </Typography>
          <Typography variant="subtitle2">
            تاریخ گزارش: {receiveDate}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: "40%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#F3DDD3",
          userSelect: "none",
        }}
      >
        <img style={{ height: "100%" }} src={src} alt="season" />
      </Box>
      <Box
        sx={{
          height: "25%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          px: 2,
        }}
      >
        <Typography fontWeight="bold" variant="subtitle2">
          نام زمین: {farmName}
        </Typography>
        <Typography variant="subtitle2">محصول: {cultivationType}</Typography>
        <Typography variant="subtitle2">تهیه کننده: {expertName}</Typography>
      </Box>
      <Box
        sx={{
          height: "15%",
          display: "flex",
          justifyContent: "flex-end",
          px: 2,
        }}
      >
        <IconButton onClick={handleMoreAction}>
          <MoreHoriz fontSize="small" />
        </IconButton>
        {!disableDelete && (
          <IconButton onClick={handleDeleteAction}>
            <DeleteOutline fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ReportCard;
