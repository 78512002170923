/* eslint-disable react/prop-types */
import formikInputTypes from "@/constants/formikInputTypes";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Autocomplete, MenuItem, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import moment from "moment-jalaali";
import CDatePicker from "../CDatePicker";

const FormikInput = ({ name, label, inputType, inputProps, ...props }) => {
  const formik = useFormikContext();

  if (inputType === formikInputTypes.TEXT_FIELD) {
    return (
      <TextField
        sx={{ width: 240 }}
        dir="rtl"
        value={formik?.values?.[name] ?? ""}
        onChange={formik?.handleChange}
        name={name}
        label={label}
        error={formik?.touched[name] && Boolean(formik?.errors[name])}
        helperText={formik?.touched[name] && formik?.errors[name]}
        {...props}
      />
    );
  } else if (inputType === formikInputTypes.DATE_FIELD) {
    return (
      <CDatePicker
        sx={{ width: "240px", direction: "ltr" }}
        slots={{ leftArrowIcon: ArrowForwardIos, rightArrowIcon: ArrowBackIos }}
        slotProps={{
          textField: {
            helperText: formik?.touched[name] && formik?.errors[name],
            error: formik?.touched[name] && Boolean(formik?.errors[name]),
          },
          leftArrowIcon: { style: { width: 16, height: 16 } },
          rightArrowIcon: { style: { width: 16, height: 16 } },
          desktopPaper: {
            sx: {
              "& .MuiDayCalendar-weekContainer button": {
                fontSize: 14,
              },
              "& .muirtl-cwhad8-MuiDateCalendar-root": {
                width: 328,
              },
              "& .MuiPickersCalendarHeader-root": {
                direction: "ltr",
                "& .MuiPickersArrowSwitcher-root": {
                  direction: "rtl",
                  margin: 0,
                  "& button": {
                    margin: 0,
                  },
                  "& .MuiPickersArrowSwitcher-spacer": {
                    display: "none",
                  },
                },
              },
            },
          },
        }}
        value={formik?.values[name] ? new Date(formik?.values[name]) : null}
        onChange={(value) =>
          formik.setFieldValue(name, moment(value).format("YYYY-MM-DD"))
        }
        name={name}
        label={label}
        {...props}
      />
    );
  } else if (inputType === formikInputTypes.AUTO_COMPLETE) {
    return (
      <Autocomplete
        sx={{ width: 240 }}
        isOptionEqualToValue={(option, value) => option === value}
        dir="rtl"
        value={formik.values[name]}
        noOptionsText="موردی یافت نشد"
        onBlur={formik.handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            onBlur={formik.handleBlur}
            helperText={formik?.touched[name] && formik?.errors[name]}
            error={formik?.touched[name] && Boolean(formik?.errors[name])}
            label={label}
          />
        )}
        onChange={(e, newValue) =>
          !props.freeSolo && formik?.setFieldValue(name, newValue)
        }
        onInputChange={(e, newValue) =>
          props.freeSolo && formik?.setFieldValue(name, newValue)
        }
        {...props}
      />
    );
  } else if (inputType === formikInputTypes.SELECT_FIELD) {
    return (
      <TextField
        sx={{ width: 240 }}
        SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
        select
        dir="rtl"
        value={formik?.values[name]}
        onChange={formik?.handleChange}
        name={name}
        label={label}
        error={formik?.touched[name] && Boolean(formik?.errors[name])}
        helperText={formik?.touched[name] && formik?.errors[name]}
        {...props}
      >
        {props?.options ? (
          props?.options?.map((item, index) => (
            <MenuItem key={index} value={props?.optionValueRender(item)}>
              {props?.optionRender(item)}
            </MenuItem>
          ))
        ) : (
          <Typography sx={{ textAlign: "center" }}>موردی یافت نشد</Typography>
        )}
      </TextField>
    );
  }
  return null;
};

export default FormikInput;
