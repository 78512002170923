import { mainSplitApi } from ".";

export const productsApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postProductFinalPrice: builder.mutation({
      query: ({ productId, data }) => ({
        url: `/api/contract-mgmt/api/v1/company-direct/add/final-price/product/${productId}/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ProductSales"],
    }),
    getProductFinalPricesList: builder.query({
      query: (params) => ({
        url: `/api/contract-mgmt/api/v1/admins/final-price/product/`,
        method: "GET",
        params: params ?? {},
      }),
      providesTags: ["ProductSales"],
    }),
    getLastProductFinalPrice: builder.query({
      query: (params) => ({
        url: `/api/contract-mgmt/api/v1/admins/final-price/product/last/update/`,
        method: "GET",
        params: params ?? {},
      }),
      providesTags: ["ProductSales"],
    }),
    approvedProductFinalPrice: builder.mutation({
      query: ({ productPriceId, data }) => ({
        url: `/api/contract-mgmt/api/v1/admin/approved/final-price/product/${productPriceId}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ProductSales"],
    }),
    getProductsTitles: builder.query({
      query: (params) => ({
        url: `/api/contract-mgmt/api/v1/admins/input/`,
        params,
      }),
    }),
  }),
});

export const {
  usePostProductFinalPriceMutation,
  useGetProductFinalPricesListQuery,
  useApprovedProductFinalPriceMutation,
  useGetLastProductFinalPriceQuery,
  useGetProductsTitlesQuery,
} = productsApi;
