/* eslint-disable react/prop-types */
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  DescriptionOutlined,
  DocumentScanner,
  InfoOutlined,
  ShoppingBag,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import Error from "../common/Error";
import modalService from "../common/Modal/modalService";
import { useState } from "react";
import SetStateModal from "../modals/SetStateModal";
import MgmtContractPrices from "../modals/MgmtContractPrices";
import GuaranteedPurchaseModal from "../modals/GuaranteedPurchaseModal";

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  font-size: 16px;
`;

const TableLoading = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        sx={{
          position: "relative",
          height: 500,
        }}
      >
        <CircularProgress sx={{ position: "absolute", left: "50%" }} />
      </TableCell>
    </TableRow>
  );
};

const EmptyTable = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        sx={{
          position: "relative",
          height: 500,
        }}
      >
        <Box
          sx={{
            height: "200px",
            width: "200px",
            position: "absolute",
            left: "calc(50% - 100px)",
            top: "calc(50% - 100px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DescriptionOutlined sx={{ width: "100px", height: "100px" }} />
          <Typography fontWeight="bold" sx={{ mt: 2 }}>
            هیچ قراردادی یافت نشد!
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const Data = ({ rows, states }) => {
  const [rowStates, setRowStates] = useState(null);

  return rows.map((row) => (
    <TableRow
      key={row?.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-of-type(odd)": {
          background: "#F4F6F8",
        },
      }}
    >
      {/* <TableCell
        sx={{ background: "#00581558" }}
        align='center'
        component='th'
        scope='row'
      >
        {page * rowsPerPage + index + 1}
      </TableCell> */}
      <TableCell align="center">{row?.profile?.first_name ?? "-"}</TableCell>
      <TableCell align="center">{row?.profile?.last_name ?? "-"}</TableCell>
      <TableCell align="center">{row?.profile?.phone_number ?? "-"}</TableCell>
      <TableCell align="center">{row?.farm_info?.city ?? "-"}</TableCell>
      <TableCell align="center">{row?.farm_info?.product ?? "-"}</TableCell>
      <TableCell align="center">{row?.contract_number ?? "-"}</TableCell>
      <TableCell align="center">
        {row?.start_date
          ? Intl.DateTimeFormat("fa-IR-u-nu-latn", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            }).format(new Date(row?.start_date))
          : "-"}
      </TableCell>
      <TableCell align="center">
        {row?.end_date
          ? Intl.DateTimeFormat("fa-IR-u-nu-latn", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            }).format(new Date(row?.end_date))
          : "-"}
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={() =>
            modalService.open(MgmtContractPrices, { contractId: row?.id })
          }
        >
          <DocumentScanner />
        </IconButton>
      </TableCell>
      {/* <TableCell align="center">
        <IconButton
          onClick={() => {
            // navigate(`${contractsPath}/${row?.id}`);
            fetch(
              baseUrl +
                `/api/contract-mgmt/api/v1/company-direct/${row?.id}/document/`,
              {
                headers: {
                  Authorization: `Bearer ${user?.access_token}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => {
                res.blob().then((blob) => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement("a");
                  a.href = url;
                  a.download = `فایل قراداد - ${row?.contract_number}`;
                  a.click();
                });
              })
              .catch(() => toast.error("خطا در بارگیری فایل!"));
          }}
        >
          <DownloadRounded />
        </IconButton>
      </TableCell> */}
      <TableCell align="center">
        {/* {row?.state.title === "waiting" ? (
          <Box sx={{ display: "flex" }}>
            <img
              onClick={() => {
                modalService.open(SetStateModal, {
                  state: "active",
                  contractId: row?.id,
                });
              }}
              style={{ cursor: "pointer" }}
              src={acceptImg}
              alt="accept"
            />
            <img
              onClick={() => {
                modalService.open(SetStateModal, {
                  state: "reject",
                  contractId: row?.id,
                });
              }}
              style={{ cursor: "pointer" }}
              src={rejectImg}
              alt="reject"
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {row?.state.title === "denied" && (
              <Tooltip title={row?.denial_reason} arrow>
                <InfoOutlined sx={{ mr: 1, width: 16, height: 16 }} />
              </Tooltip>
            )}
            <Typography component="span" variant="caption">
              <Typography component="p" variant="button">
                {row?.state.label ?? "-"}
              </Typography>
            </Typography>
          </Box>
        )} */}
        {row?.state.title === "waiting" ? (
          <Box>
            <TextField
              value={rowStates?.[row?.id] || null}
              onChange={(e) =>
                setRowStates((prev) => {
                  if (!prev) {
                    prev = {};
                  }
                  return { ...prev, [row?.id]: e.target.value };
                })
              }
              select
              label="تعیین وضعیت"
              size="small"
              sx={{ width: 150, mt: 1 }}
            >
              {states.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
            <Button
              disabled={!rowStates?.[row?.id]}
              onClick={() =>
                modalService.open(SetStateModal, {
                  state: rowStates?.[row?.id],
                  contractId: row?.id,
                })
              }
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
            >
              تایید
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {row?.state.title === "denied" && (
              <Tooltip title={row?.denial_reason} arrow>
                <InfoOutlined sx={{ mr: 1, width: 16, height: 16 }} />
              </Tooltip>
            )}
            <Typography component="span" variant="caption">
              <Typography component="p" variant="button" textAlign="center">
                {row?.state?.label ?? "-"}
              </Typography>
            </Typography>
          </Box>
        )}
      </TableCell>

      <TableCell align="center">
        <IconButton
          // disabled={!row?.purchase}
          onClick={() => {
            modalService.open(GuaranteedPurchaseModal, { data: row });
          }}
        >
          <ShoppingBag />
        </IconButton>
      </TableCell>
    </TableRow>
  ));
};

const Table = ({
  data = [],
  isLoading,
  isSuccess,
  isFetching,
  states = [],
}) => {
  const Body = () => {
    if (isLoading || isFetching) {
      return <TableLoading />;
    } else if (isSuccess) {
      if (data.length > 0) {
        return <Data rows={data} states={states} />;
      } else {
        return <EmptyTable />;
      }
    } else {
      return <Error />;
    }
  };

  return (
    <TableContainer component={Box}>
      <MuiTable sx={{ minWidth: 650 }} size="medium" aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: "#00581558" }}>
            <StyledTableCell align="center">نام</StyledTableCell>
            <StyledTableCell align="center">نام خانوادگی</StyledTableCell>
            <StyledTableCell align="center">شماره تماس</StyledTableCell>
            <StyledTableCell align="center">شهر</StyledTableCell>
            <StyledTableCell align="center">نوع محصول</StyledTableCell>
            <StyledTableCell align="center">شناسه قرارداد</StyledTableCell>
            <StyledTableCell align="center">تاریخ شروع</StyledTableCell>
            <StyledTableCell align="center">تاریخ پایان</StyledTableCell>
            <StyledTableCell align="center">جزئیات</StyledTableCell>
            {/* <StyledTableCell align="center">دریافت قرارداد</StyledTableCell> */}
            <StyledTableCell align="center">وضعیت</StyledTableCell>
            <StyledTableCell align="center">خرید تضمینی</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Body />
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
