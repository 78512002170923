/* eslint-disable react/prop-types */
import { Button, Divider, Grid, TextField } from "@mui/material";
import { Field, Form, Formik, useFormikContext } from "formik";
import FormikInput from "../common/FormikInput/FormikInput";
import formikInputTypes from "@/constants/formikInputTypes";
import { useSelector } from "react-redux";
import {
  useLazyGetCompanyDirectsQuery,
  useLazyGetStatesQuery,
  usePostProfileMutation,
} from "@/services/contracts";
import { toast } from "react-toastify";
import { useEffect } from "react";
import * as Yup from "yup";

const Input = ({
  label,
  name,
  xs = 6,
  md = 4,
  width,
  readOnly,
  type,
  ...props
}) => {
  return (
    <Grid item container xs={xs} md={md} justifyContent="center">
      <FormikInput
        sx={{ width }}
        inputType={formikInputTypes.TEXT_FIELD}
        label={label}
        name={name}
        InputProps={{ readOnly, type }}
        {...props}
      />
    </Grid>
  );
};

const CityState = ({ data, isSuccess, triggerStates, states }) => {
  // const [trigger, { data: cities }] = useLazyGetCitiesQuery();

  useEffect(() => {
    if (isSuccess && !data?.is_completed) {
      triggerStates();
    }
  }, [data, isSuccess, triggerStates]);

  // useEffect(() => {
  //   if (isSuccess && !data?.is_completed) {
  //     !!values.state && trigger(values.state);
  //   }
  // }, [data, isSuccess, trigger, values, states]);

  return (
    <>
      <Grid item container xs={12} md={6} justifyContent="center">
        {data?.state ? (
          <Field
            name="state.title"
            label="استان"
            component={({ field, ...props }) => (
              <TextField
                fullWidth
                {...field}
                {...props}
                inputProps={{ readOnly: true }}
              />
            )}
          />
        ) : (
          <FormikInput
            sx={{ width: "100%" }}
            options={states}
            optionRender={(item) => item.title}
            optionValueRender={(item) => item.id}
            inputType={formikInputTypes.SELECT_FIELD}
            label="استان"
            name="state"
          />
        )}
      </Grid>
      {/* <Grid item container xs={6} md={4} justifyContent="center">
        {data?.city ? (
          <Field
            name="city.title"
            label="شهر محل سکونت"
            component={({ field, ...props }) => (
              <TextField
                {...field}
                {...props}
                inputProps={{ readOnly: true }}
              />
            )}
          />
        ) : (
          <FormikInput
            options={cities}
            optionRender={(item) => item.title}
            optionValueRender={(item) => item.id}
            inputType={formikInputTypes.SELECT_FIELD}
            label="شهر محل سکونت"
            name="city"
          />
        )}
      </Grid> */}
    </>
  );
};

const CompanyProviderInput = ({ data, isSuccess }) => {
  const { values } = useFormikContext();
  const [getCompanyProvider, { data: companies }] =
    useLazyGetCompanyDirectsQuery();

  useEffect(() => {
    if (isSuccess && !data?.is_completed) {
      !!values?.state && getCompanyProvider({ state: values?.state });
    }
  }, [getCompanyProvider, values, data, isSuccess]);

  return (
    <Grid item container xs={12} md={6} justifyContent="center">
      {data?.direct ? (
        <TextField
          fullWidth
          value={values?.direct?.first_name + " " + values?.direct?.last_name}
          inputProps={{ readOnly: true }}
        />
      ) : (
        <FormikInput
          sx={{ width: "100%" }}
          options={companies || []}
          optionRender={(item) => item.first_name + " " + item.last_name}
          optionValueRender={(item) => item.id}
          inputType={formikInputTypes.SELECT_FIELD}
          label="مباشر"
          name="direct"
        />
      )}
    </Grid>
  );
};

const PVInfoForm = ({ data, isSuccess }) => {
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const [postProfile] = usePostProfileMutation();

  const REQUIRED_TEXT = "این فیلد الزامی است";

  const selectValidate = Yup.mixed("")
    .nullable()
    .test("test", "این مقدار الزامی است", function (value) {
      if (value) {
        return true;
      } else {
        return false;
      }
    });

  const [triggerStates, { data: states }] = useLazyGetStatesQuery();

  const ExecutiveRepresentative = () => {
    const { values } = useFormikContext();

    if (!values.state?.title) {
      const foundItem = states?.find((ele) => ele?.id === values?.state);
      return (
        <Input
          value={`اتحادیه شرکت های تعاونی تولید روستایی استان ${foundItem?.title}`}
          width="100%"
          label="نماینده مجری"
          xs={12}
          md={8}
          readOnly
        />
      );
    }
    return (
      <Input
        value={`اتحادیه شرکت های تعاونی تولید روستایی استان ${values.state?.title}`}
        width="100%"
        label="نماینده مجری"
        xs={12}
        md={8}
        readOnly
      />
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={Yup.object({
        first_name: Yup.string().required(REQUIRED_TEXT),
        last_name: Yup.string().required(REQUIRED_TEXT),
        national_id: Yup.string().required(REQUIRED_TEXT),
        father_name: Yup.string().required(REQUIRED_TEXT),
        phone_number: Yup.string().required(REQUIRED_TEXT),
        bank_account_number: Yup.string().required(REQUIRED_TEXT),
        address: Yup.string().required(REQUIRED_TEXT),
        postal_code: Yup.string().required(REQUIRED_TEXT),
        birth_date: Yup.string().required(REQUIRED_TEXT),
        birth_certificate_id: Yup.string().required(REQUIRED_TEXT),
        direct: selectValidate,
        state: selectValidate,
        city: Yup.string().required(REQUIRED_TEXT),
        gender: selectValidate,
      })}
      onSubmit={(values, { setSubmiting }) => {
        let body = values;
        if (values?.is_completed) {
          body = {};
        }
        postProfile({ data: body, contractId })
          .unwrap()
          .then(() => {
            toast.success("اطلاعات تایید شد!");
          });
        setSubmiting(false);
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Grid container columnSpacing={2} rowGap={4} sx={{ mt: 4 }}>
            <Input
              label="نام"
              name="first_name"
              readOnly={Boolean(data?.first_name)}
            />
            <Input
              label="نام خانوادگی"
              name="last_name"
              readOnly={Boolean(data?.last_name)}
            />
            <Input label="کد ملی" name="national_id" readOnly />
            <Input
              label="نام پدر"
              name="father_name"
              readOnly={Boolean(data?.father_name)}
            />
            <Input label="شماره همراه" name="phone_number" readOnly />
            <Input
              label="شماره شناسنامه"
              name="birth_certificate_id"
              readOnly={Boolean(data?.birth_certificate_id)}
            />
            <Input
              label="محل صدور"
              name="city"
              readOnly={Boolean(data?.city)}
            />
            <Grid item container xs={6} md={4} justifyContent="center">
              {data?.is_completed ? (
                <Field
                  label="جنسیت"
                  name="gender.label"
                  component={({ field, ...props }) => (
                    <TextField
                      {...field}
                      {...props}
                      inputProps={{ readOnly: true }}
                    />
                  )}
                />
              ) : (
                <FormikInput
                  options={[
                    { id: 1, title: "male", label: "مرد" },
                    { id: 2, title: "male", label: "زن" },
                  ]}
                  optionRender={(item) => item.label}
                  optionValueRender={(item) => item.id}
                  inputType={formikInputTypes.SELECT_FIELD}
                  label="جنسیت"
                  name="gender"
                />
              )}
            </Grid>
            <Grid item container xs={6} md={4} justifyContent="center">
              <FormikInput
                name="birth_date"
                label="تاریخ تولد"
                inputType={formikInputTypes.DATE_FIELD}
                readOnly={Boolean(data?.birth_date)}
              />
            </Grid>
            <Input
              label="شماره حساب بانک کشاورزی"
              name="bank_account_number"
              width="96%"
              xs={6}
              md={6}
              readOnly={Boolean(data?.bank_account_number)}
            />
            <Input
              label="کدپستی"
              name="postal_code"
              width="96%"
              xs={6}
              md={6}
              readOnly={Boolean(data?.postal_code)}
            />
            <Input
              width="98%"
              label="آدرس محل سکونت"
              xs={12}
              md={12}
              name="address"
              readOnly={Boolean(data?.address)}
            />
            <Grid item xs={12}>
              <Divider flexItem />
            </Grid>

            <Input
              value={`توسعه کشاورزی قند خاورمیانه`}
              width="100%"
              label="نماینده مجری"
              xs={12}
              md={12}
              readOnly
            />
            <CityState
              triggerStates={triggerStates}
              states={states}
              data={data}
              isSuccess={isSuccess}
            />
            <CompanyProviderInput data={data} isSuccess={isSuccess} />
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              sx={{ mx: "15px" }}
            >
              <Button disabled={isSubmitting} type="submit" variant="contained">
                {isSubmitting ? "در حال تایید..." : "تایید اطلاعات و ادامه"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PVInfoForm;
