import { Roles } from "@/constants/global";
import useSearchByDelay from "@/hooks/useSearchByDelay";
import {
  useGetAdminContractsQuery,
  useGetContractListQuery,
} from "@/services/contracts";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const TechnologyKnowledgeTransfer = () => {
  const [page, setPage] = useState(1);
  const [contracts, setContracts] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const role = useSelector((state) => state.authentication.user);

  const { currentSearch, setCurrentSearch, debounceSearch } =
    useSearchByDelay();
  const isMainAdmin = role?.user_info?.type?.name === Roles.ADMIN;
  const isFarmer = role?.user_info?.type?.name === Roles.FARMER;
  const isCompanyDirect = role?.user_info?.type?.name === Roles.COMPANY_DIRECT;

  // farmer contracts
  const { data: farmerContracts, isFetching: isFetchingFarmerContract } =
    useGetContractListQuery(
      {
        state: 2,
        page,
        search: debounceSearch,
      },
      { skip: isMainAdmin || isCompanyDirect }
    );

  // main admin contracts
  const { data: adminContracts, isFetching: isFetchingadminContracts } =
    useGetAdminContractsQuery(
      {
        state: 2,
        page,
        search: debounceSearch,
      },
      { skip: isFarmer }
    );

  const data =
    isMainAdmin || isCompanyDirect ? adminContracts : farmerContracts;
  const isFetching =
    isMainAdmin || isCompanyDirect
      ? isFetchingadminContracts
      : isFetchingFarmerContract;

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && data?.links?.next) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, data?.links?.next]
  );

  useEffect(() => {
    if (data) {
      setContracts((prev) => [...prev, ...(data?.results ?? [])]);
    }
    // return () => setContracts([]);
  }, [data]);

  useEffect(() => {
    if (contracts?.length > 0 && !selectedFarm) {
      contracts[0]?.has_satplat_farm_id && setSelectedFarm(contracts[0]);
    }
  }, [contracts, selectedFarm]);

  return (
    <Outlet
      context={{
        data,
        currentSearch,
        setContracts,
        setPage,
        setCurrentSearch,
        contracts,
        lastElementRef,
        selectedFarm,
        setSelectedFarm,
      }}
    />
  );
};

export default TechnologyKnowledgeTransfer;
