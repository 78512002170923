/* eslint-disable react/prop-types */
import OTP from "@/components/common/OTP";
import Timer from "@/components/common/Timer";
import { Box, Typography, Button } from "@mui/material";
import { useState } from "react";

const EnterCode = ({ code, setCode, handleSubmit, handleReset }) => {
  const [disableResend, setDisableResend] = useState(true);

  const enableTimer = () => {
    setDisableResend(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>
        کد تایید 4 رقمی به شماره {sessionStorage.getItem("phone")} ارسال شد
      </Typography>
      <Box
        sx={{
          width: "80%",
          margin: "20px 0",
        }}
      >
        <OTP value={code} onChange={(text) => setCode(text)} />
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        {disableResend && (
          <Timer
            h={0}
            m={2}
            s={0}
            onFinished={() => setDisableResend(false)}
            render={({ m, s }) => (
              <Typography variant='subtitle2'>
                <Typography
                  variant='subtitle1'
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    direction: "rtl",
                  }}
                >
                  {`${m} : ${s}`}
                </Typography>
                مانده تا دریافت مجدد کد دیگر
              </Typography>
            )}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
        }}
      >
        <Button onClick={handleSubmit} sx={{ width: 180 }} variant='contained'>
          تایید
        </Button>
        <Button
          onClick={() => {
            handleReset(enableTimer);
          }}
          sx={{ width: 180 }}
          disabled={disableResend}
        >
          ارسال مجدد کد
        </Button>
      </Box>
    </Box>
  );
};

export default EnterCode;
