import {
  Box,
  Paper,
  TablePagination,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import Table from "@/components/company-direct-contracts-modules/Table";
import { debounce } from "lodash";
import {
  useGetAdminGeneralContractStateListQuery,
  useLazyGetAdminContractsQuery,
} from "@/services/contracts";

const CompanyDirectContracts = () => {
  const [requestState, setRequestState] = useState({
    page: 1,
    search: "",
    ordering: "",
    state: "",
    page_size: 5,
  });
  const [trigger, { data, isLoading, isSuccess, isFetching }] =
    useLazyGetAdminContractsQuery();
  const { data: states } = useGetAdminGeneralContractStateListQuery();
  const { data: filterItems } = useGetAdminGeneralContractStateListQuery(
    {},
    {
      selectFromResult: ({ data, error, isLoading, isSuccess }) => ({
        data: isSuccess
          ? [{ id: "", label: "بدون فیلتر", title: "" }, ...data]
          : [],
        error,
        isLoading,
      }),
    }
  );

  useEffect(() => {
    trigger(requestState);
  }, []);

  const handleChangePage = (event, newPage) => {
    setRequestState((prev) => {
      const newState = { ...prev, page: newPage + 1 };
      trigger(newState);
      return newState;
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRequestState((prev) => {
      const newState = {
        ...prev,
        page: 1,
        page_size: parseInt(event.target.value, 10),
      };
      trigger(newState);
      return newState;
    });
  };

  const debounceRequest = debounce((requestParam) => {
    trigger(requestParam);
  }, 1000);

  const handleDebounceRequest = useCallback((value) => {
    debounceRequest(value);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "30px",
          pb: 4,
          alignItems: { xs: "", xl: "center" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: "30px",
            flex: "0 1 auto",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            پنل مباشر کشت قراردادی
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Paper sx={{ width: "90%", maxWidth: 1200, p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                value={requestState.search}
                onChange={(e) =>
                  setRequestState((prev) => {
                    const copiedData = { ...prev };
                    copiedData.search = e.target.value;
                    handleDebounceRequest(copiedData);
                    copiedData.page = 1;
                    return copiedData;
                  })
                }
                label="جستجو"
                size="small"
                placeholder="نام زمین / شماره قرارداد"
              />
              <TextField
                onChange={(e) =>
                  setRequestState((prev) => {
                    const newState = {
                      ...prev,
                      page: 1,
                      state: e.target.value,
                    };
                    trigger(newState);
                    return newState;
                  })
                }
                value={requestState.state}
                sx={{ width: 200 }}
                select
                size="small"
                label="فیلتر براساس وضعیت"
              >
                {filterItems?.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ pt: 4 }}>
              <Table
                rowsPerPage={requestState.page_size}
                page={requestState.page - 1}
                data={data?.results}
                isLoading={isLoading}
                isSuccess={isSuccess}
                isFetching={isFetching}
                states={states}
              />
              <TablePagination
                sx={{ mt: 2 }}
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={data?.total_items ?? 0}
                rowsPerPage={requestState.page_size}
                page={requestState.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="ردیف در هر صفحه: "
                labelDisplayedRows={({ count, page }) =>
                  count > 0 && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ fontSize: "14px" }}>
                        تعداد کل ردیف ها: {count}
                      </Typography>
                      <Typography sx={{ ml: 4, fontSize: "14px" }}>
                        صفحه: {page + 1} از{" "}
                        {Math.ceil(count / requestState.page_size)}
                      </Typography>
                    </Box>
                  )
                }
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default CompanyDirectContracts;
