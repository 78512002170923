import CurrentWeatherInfoMeteorology from "@/components/meteorology-modules/CurrentWeatherInfoMeteorology";
import DailyInformation from "@/components/meteorology-modules/DailyInformation";
import RainChart from "@/components/meteorology-modules/RainChart";
import TempretureChart from "@/components/meteorology-modules/TempretureChart";
import WindBlowChart from "@/components/meteorology-modules/WindBlowChart";
import { useGetWeatherInformationQuery } from "@/services/meteorology";
import { Box, Typography } from "@mui/material";
import { useOutletContext } from "react-router";
import styled from "@emotion/styled";
import HandleServerData from "@/components/common/HandleServerData";

// eslint-disable-next-line react/prop-types
const Title = ({ children }) => {
  return (
    <Typography
      sx={{
        fontWeight: "bold",
        color: "#fff",
        pl: (theme) => theme.spacing(1),
        pt: (theme) => theme.spacing(2),
        display: "inline-block",
        borderRadius: 2,
      }}
      variant="subtitle2"
    >
      {children}
    </Typography>
  );
};

const ChartContainer = styled(Box)`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Prediction = () => {
  const context = useOutletContext();

  const { data, isError, isSuccess, isLoading } = useGetWeatherInformationQuery(
    { farmId: context?.farm_info?.id },
    { skip: !context?.farm_info?.id }
  );

  return (
    <Box
      sx={{
        p: (theme) => theme.spacing(4),
        // backgroundImage: `url(${BG})`,
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center center",
        // backgroundSize: "cover",
        overflow: "auto",
        // backgroundAttachment: "fixed",
        height: "87.5vh",
      }}
    >
      {/* <Box dir="ltr">
        <IconButton title="بازگشت" onClick={() => navigate(meteorologyPath)}>
          <ArrowBack />
        </IconButton>
      </Box> */}
      <HandleServerData
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: {
                xs: "100%",
                md: "20%",
              },
            }}
          >
            <CurrentWeatherInfoMeteorology data={data?.currentConditions} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: {
                xs: "100%",
                md: "80%",
              },
            }}
          >
            <ChartContainer
              sx={{ height: 250, background: "rgba(255, 138, 0, 0.32)" }}
            >
              <Title>دمای هوا (&#8451;)</Title>
              <TempretureChart data={data?.days?.[0]?.hours} />
            </ChartContainer>
            <ChartContainer
              sx={{
                height: 140,
                margin: "20px 0",
                background: "rgba(0, 107, 255, 0.46);",
              }}
            >
              <Title>بارش روزانه (mm)</Title>
              <RainChart data={data?.days?.[0]?.hours} />
            </ChartContainer>
            <ChartContainer
              sx={{ height: 140, background: "rgba(22, 169, 116, 0.41)" }}
            >
              <Title>وزش باد (m/s)</Title>
              <WindBlowChart data={data?.days?.[0]?.hours} />
            </ChartContainer>
          </Box>
        </Box>

        <DailyInformation data={data?.days} />
      </HandleServerData>
    </Box>
  );
};

export default Prediction;
