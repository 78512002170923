import { Box, Button, Typography } from "@mui/material";
import { profileBannerImg } from "@/assets";
import { step1, step2, step3, step4, step5, step6 } from "@/assets";
import { useNavigate } from "react-router-dom";

const STEPS = [step1, step2, step3, step4, step5, step6];

const DashboardPage = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          backgroundImage: `url(${profileBannerImg}) `,
          backgroundSize: "cover",
          boxShadow: "0px 5px 10px #00000050",
          position: "relative",
          backgroundPosition: `center center`,
          backgroundRepeat: "no-repeat",
          minHeight: 800,
          flexDirection: { xs: "column-reverse", md: "column" },
        }}
        my={4}
        bgcolor="#F4F6F8"
        borderRadius={10}
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        p={4}
      >
        {/* <Box
          sx={{
            position: "absolute",
            left: 0,
            top: -50,
            border: "1px solid #000",
            borderRadius: "100%",
            width: 50,
            height: 50,
            p: 2,
            backgroundColor: "white",
            backgroundImage: `url(${farmerImg}) `,
            backgroundPosition: `center center`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundOrigin: "content-box",
          }}
        /> */}

        <Box
          sx={{
            display: { xs: "none", md: "initial" },
            alignSelf: "flex-start",
            mb: { xs: 4, md: 6 },
          }}
        >
          <Typography fontSize="20px" fontWeight="bold">
            کاربر گرامی
          </Typography>
          <Typography mt={2}>
            برای انعقاد قرار داد در منو به بخش کشت قرار دادی مراجعه کنید.
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            maxWidth: "1440px",
            display: "flex",
            flexWrap: { xs: "wrap", md: "nowrap" },
            justifyContent: { xs: "center", md: "space-between" },
            borderRadius: 8,
            border: "1px solid #80D6DB",
            p: 2,
          }}
        >
          {STEPS.map((url, index) => (
            <Box key={index}>
              <img src={url} alt="" />
            </Box>
          ))}
        </Box>

        <Typography
          fontWeight="bold"
          sx={{ textAlign: "center", my: { xs: 4, md: 0 } }}
        >
          <Typography color="error" component="span">
            *
          </Typography>{" "}
          برای انعقاد قرارداد انجام مراحل ۱ تا ۴ الزامیست.
        </Typography>

        <Button
          sx={{ height: 45, width: 240, fontSize: "16px" }}
          variant="contained"
          onClick={() => {
            navigate("/farmer/contract-registration");
          }}
        >
          فرآیند انعقاد قرارداد
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardPage;
