/* eslint-disable react/prop-types */
import Thermometer from "@/assets/images/Meteorology/thermometer.png";
import humidity from "@/assets/images/Meteorology/humidity.png";
import sunset from "@/assets/images/Meteorology/sunset.svg";
import windSpeed from "@/assets/images/Meteorology/windSpeed.png";
import airPressure from "@/assets/images/Meteorology/airPressure.png";
import { Box, Grid, Typography } from "@mui/material";
import { WEATHER_IMAGES } from "@/constants/weatherImages";
import styled from "@emotion/styled";

const Item = styled(Box)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const CurrentWeatherInfoMeteorology = ({ data }) => {
  const elementOfInfo = [
    {
      icon: Thermometer,
      title: `دما`,
      value: `${data?.feelslike}°C`,
    },
    {
      icon: humidity,
      title: `رطوبت`,
      value: `${data?.humidity}%`,
    },
    {
      icon: windSpeed,
      title: `سرعت باد`,
      value: `${data?.windspeed} km/h`,
    },
    {
      icon: airPressure,
      title: `فشار هوا`,
      value: `${data?.pressure} mb`,
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "rgba(92, 159, 202, 0.63)",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginBottom: {
          xs: 4,
          md: 0,
        },
        width: {
          xs: "100%",
          md: "90%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          color: "#fff",
        }}
      >
        <Item sx={{ my: 4 }}>
          <img src={WEATHER_IMAGES[data?.icon]} width={70} alt="" />
        </Item>
        <Item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: "30px", marginRight: "11px" }}
              dir="ltr"
            >
              {data?.feelslike}&deg;
            </Typography>
            <Typography>درجه سانتی گراد</Typography>
          </Box>
          <Typography
            sx={{
              marginLeft: 1,
              fontSize: "14px",
            }}
            variant="overline"
          >
            {Intl.DateTimeFormat("fa-IR-u-nu-latn", {
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date())}
          </Typography>
        </Item>
        <Item>
          <Typography
            sx={{
              marginLeft: 1,
              fontSize: "14px",
            }}
            variant="overline"
            dir="ltr"
          >
            {`${Intl.DateTimeFormat("fa-IR-u-nu-latn", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(data?.datetimeEpoch * 1000))} `}
            آخرین به روز رسانی ساعت
          </Typography>
        </Item>
      </Box>

      <Box
        sx={{
          width: "80%",
          height: "75px",
          background: " rgba(92, 159, 202, 0.31)",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "space-around",
          py: 1,
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">طلوع</Typography>
          <img style={{ width: 17, rotate: "180deg" }} src={sunset} alt="" />
          <Typography variant="caption">{data?.sunrise}</Typography>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">غروب</Typography>
          <img style={{ width: 17 }} src={sunset} alt="" />
          <Typography variant="caption">{data?.sunset}</Typography>
        </Box>
      </Box>

      {/* <Item>
        <Typography dir='ltr'>{`ساعت آفتابی`}</Typography>
        <Typography
          sx={{ color: (theme) => theme.palette.pinkSwan }}
          variant='overline'
          dir='ltr'
        >
          {`11:52`} ساعت
        </Typography>
      </Item> */}
      <Grid container>
        {elementOfInfo.map((item, index) => (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: 2,
            }}
            xs={6}
            item
            key={index}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                width={15}
                style={{ marginBottom: 8, marginLeft: 5 }}
                src={item.icon}
                alt=""
              />
              <Typography sx={{ color: "#365279" }} dir="ltr">
                {item.title}
              </Typography>
            </Box>

            <Box
              sx={{
                background: "#fff",
                width: 70,
                height: 35,
                borderRadius: 2,
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "rgba(1, 173, 183, 0.25)",
                  borderRadius: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "-5%",
                  left: "-4%",
                }}
              >
                <Typography
                  sx={{
                    color: "#365279",
                    textAlign: "center",
                    marginTop: "5%",
                  }}
                  variant="overline"
                  dir="ltr"
                >
                  {item.value}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CurrentWeatherInfoMeteorology;
