/* eslint-disable react/prop-types */
import { TYPE_TRANSLATOR } from "@/constants/global";
import { useDeleteInputSelectionMutation } from "@/services/contracts";
import { moneyFormat } from "@/utils/moneyFormat";
import { Delete } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

const CATEGORIES = [
  { title: "بذر", value: "seed" },
  { title: "سم", value: "poison" },
  { title: "کود", value: "fertilizer" },
];

const Card = ({ item, deleteInputSelection, contractId }) => {
  return (
    <Box
      key={item?.id}
      sx={{
        "&:last-child": {
          mb: 0,
        },
        mb: 2,
        display: "flex",
        justifyContent: "space-between",
        height: { xs: "fit-content", md: 90 },
        border: "1px solid #68C1C3",
        borderRadius: 6,
        p: 1.5,
      }}
    >
      <Box display="flex">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRight: "1px solid #68C1C3",
            pr: { xs: 0.8, md: 1.5 },
          }}
        >
          <IconButton
            onClick={() => deleteInputSelection({ contractId, id: item?.id })}
          >
            <Delete
              sx={{ width: { xs: 20, md: 24 }, height: { xs: 20, md: 24 } }}
              color="error"
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            fontWeight="bold"
            sx={{ textAlign: "left", fontSize: { xs: 14, md: 16 } }}
          >
            {item?.selected_input?.title}
          </Typography>
          <Box display="flex">
            <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>
              تعداد:{" "}
            </Typography>

            <Typography sx={{ fontSize: { xs: 14, md: 16 }, ml: 1 }}>
              {item?.needed_bags}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>
              مبلغ:{" "}
            </Typography>
            <Typography
              sx={{ textAlign: "left", mx: 1, fontSize: { xs: 14, md: 16 } }}
              fontWeight="bold"
              color="primary"
            >
              {item?.cost > 0 ? moneyFormat(item?.cost) : "بعدا محاسبه می شود."}
            </Typography>
            {item?.cost > 0 && (
              <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>
                ریال
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ alignSelf: "flex-end" }}>
        <Box
          sx={{
            width: { xs: 50, md: 80 },
            height: { xs: 50, md: 80 },
            backgroundSize: "contain",
            backgroundImage: `url(${
              item?.selected_input?.logo ??
              TYPE_TRANSLATOR?.[item?.selected_input?.input_type?.title]
                ?.defaultImg
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        />
      </Box>
    </Box>
  );
};

const CartDrawer = ({ open, onClose, inputValidationData }) => {
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const [selectedTab, setSelectedTab] = useState("seed");
  const [deleteInputSelection] = useDeleteInputSelectionMutation();

  const handleChange = (_e, value) => {
    setSelectedTab(value);
  };

  const filterBankValidationData = (type) => {
    // eslint-disable-next-line react/prop-types
    return inputValidationData?.input_selections?.filter(
      (ele) => ele?.selected_input?.input_type?.title === type
    );
  };

  const displayData = filterBankValidationData(selectedTab);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: { xs: 300, md: 400 },
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          px: 1,
        }}
      >
        <Typography
          my={2}
          sx={{ textAlign: "center", fontSize: 18 }}
          fontWeight="bold"
        >
          سبد نهاده انتخاب شده
        </Typography>
        <Divider />
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {CATEGORIES.map((item, index) => (
            <Tab
              key={index}
              label={`${item.title} (${
                filterBankValidationData(item.value)?.length
              })`}
              value={item.value}
              sx={{ fontWeight: "bold", fontSize: 16 }}
            />
          ))}
        </Tabs>

        <Box
          sx={{
            pt: 2,
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflowY: "auto",
          }}
        >
          {displayData?.length > 0 ? (
            displayData?.map((item) => (
              <Card
                key={item?.id}
                item={item}
                contractId={contractId}
                deleteInputSelection={deleteInputSelection}
              />
            ))
          ) : (
            <Typography fontWeight="bold" textAlign="center">
              سبد خالی است!
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            p: 2,
            my: 2,
            height: 80,
            border: "1px solid #68C1C3",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>تعداد کل: </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography>
                {filterBankValidationData("seed")?.length} بذر
              </Typography>
              <Typography mx={2}>
                {filterBankValidationData("fertilizer")?.length} کود
              </Typography>
              <Typography>
                {filterBankValidationData("poison")?.length} سم
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>جمع سبد خرید: </Typography>
            {inputValidationData?.payable_cost > 0 ? (
              <Typography>
                <Typography component="span" fontWeight="bold" color="primary">
                  {moneyFormat(inputValidationData?.payable_cost)}
                </Typography>{" "}
                ریال
              </Typography>
            ) : (
              <Typography fontWeight="bold" color="primary">
                بعدا محاسبه می شود.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CartDrawer;
