import { createSlice } from "@reduxjs/toolkit";

export const FarmManagementStates = {
  selectedFarm: "selectedFarm",
  selectedIndex: "selectedIndex",
  selectedImageId: "selectedImageId",
  farmsList: "farmsList",
  farmsListPage: "farmsListPage",
};

const initialState = {
  [FarmManagementStates.selectedFarm]: null,
  [FarmManagementStates.selectedIndex]: "NDVI",
  [FarmManagementStates.selectedImageId]: null,
  [FarmManagementStates.farmsList]: [],
  [FarmManagementStates.farmsListPage]: 1,
};

const farmManagementSlice = createSlice({
  name: "farmManagement",
  initialState,
  reducers: {
    setFarmManageMentState: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

const { reducer } = farmManagementSlice;

export const { setFarmManageMentState } = farmManagementSlice.actions;
export default { reducer };
