/* eslint-disable react/prop-types */
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DescriptionOutlined } from "@mui/icons-material";
import styled from "@emotion/styled";
import Error from "../common/Error";
import { TYPE_TRANSLATOR } from "@/constants/global";
import { moneyFormat } from "@/utils/moneyFormat";

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  font-size: 16px;
  min-width: 100px;
`;

const TableLoading = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        sx={{
          position: "relative",
          height: 500,
        }}
      >
        <CircularProgress sx={{ position: "absolute", left: "50%" }} />
      </TableCell>
    </TableRow>
  );
};

const EmptyTable = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        sx={{
          position: "relative",
          height: 500,
        }}
      >
        <Box
          sx={{
            height: "200px",
            width: "200px",
            position: "absolute",
            left: "calc(50% - 100px)",
            top: "calc(50% - 100px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DescriptionOutlined sx={{ width: "100px", height: "100px" }} />
          <Typography fontWeight="bold" sx={{ mt: 2 }}>
            هیچ قراردادی یافت نشد!
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const Data = ({ rows }) => {
  return rows.map((row) => (
    <TableRow
      key={row?.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-of-type(odd)": {
          background: "#F4F6F8",
        },
      }}
    >
      <TableCell align="center">
        {row?.input_type?.title
          ? TYPE_TRANSLATOR?.[row?.input_type?.title]?.title
          : "-"}
      </TableCell>
      <TableCell align="center">{row?.title ?? "-"}</TableCell>
      <TableCell align="center">{row?.provider?.title ?? "-"}</TableCell>
      <TableCell align="center">
        {row?.total_amount ? (
          <>
            {moneyFormat(row?.total_amount)}{" "}
            {row?.input_type?.title === "seed" ? "کیلوگرم" : "بسته"}
          </>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="center">
        {row?.total_price ? `${moneyFormat(row?.total_price)} ریال` : "-"}
      </TableCell>
    </TableRow>
  ));
};

const Table = ({ data = [], isLoading, isSuccess, isFetching }) => {
  const Body = () => {
    if (isLoading || isFetching) {
      return <TableLoading />;
    } else if (isSuccess) {
      if (data.length > 0) {
        return <Data rows={data} />;
      } else {
        return <EmptyTable />;
      }
    } else {
      return <Error />;
    }
  };

  return (
    <TableContainer component={Box}>
      <MuiTable sx={{ minWidth: 250 }} size="medium" aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: "#00581558" }}>
            <StyledTableCell align="center">نوع نهاده</StyledTableCell>
            <StyledTableCell align="center">نام محصول</StyledTableCell>
            <StyledTableCell align="center">شرکت تامین کننده</StyledTableCell>
            <StyledTableCell align="center">حجم</StyledTableCell>
            <StyledTableCell align="center">مبلغ کل</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Body />
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
