import { mainSplitApi } from ".";

export const meteorologyApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimeSeries: builder.query({
      query: ({ farmId, index, startDate, endDate }) => {
        const params =
          startDate && endDate
            ? { start_date: startDate, end_date: endDate }
            : "";
        return {
          url: `/api/satplat/api/v1/time_series/${farmId}/${index}/`,
          params,
          method: "GET",
        };
      },
    }),
    getCardinalTemperature: builder.query({
      query: ({ farmId }) => ({
        url: `/api/satplat/api/v1/weather_cardinal/${farmId}/`,
        method: "GET",
      }),
    }),
    getWeatherInformation: builder.query({
      query: (data) => ({
        url: `/api/satplat/api/v1/weather_forecast/${data?.farmId}/`,
        params: {
          lon: data.lng,
          lat: data.lat,
        },
        method: "GET",
      }),
    }),
    getECMWF: builder.query({
      query: (farmId) => ({
        url: `/api/satplat/api/v1/weather_ecmwf/${farmId}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetWeatherInformationQuery,
  useGetTimeSeriesQuery,
  useGetCardinalTemperatureQuery,
  useGetECMWFQuery,
} = meteorologyApi;
