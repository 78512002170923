/* eslint-disable react/prop-types */
import { Box, Button, IconButton, Typography } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { Close, Download, FileCopy } from "@mui/icons-material";
import { useState } from "react";
import { baseUrl } from "@/services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { contractsPath } from "@/constants/pagePath";
import { RolePath } from "@/constants/global";

const SuccessSign = (props) => {
  const [copyText, setCopyText] = useState("کپی کردن");
  const { user } = useSelector((state) => state.authentication);
  const navigate = useNavigate();

  return (
    <ModalComponent open={props.open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: "42px 60px",
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: "10px", left: "10px" }}
          onClick={() => {
            props.onClose();
          }}
        >
          <Close />
        </IconButton>
        <Typography>قرارداد شما با موفقیت ثبت شد.</Typography>
        <Typography>برای مشاهده وضعیت به بخش قراردادها مراجعه کنید.</Typography>
        <Box sx={{ display: "flex", my: 4, border: "1px solid #000", p: 1 }}>
          <Typography>شناسه قرارداد:</Typography>
          <Typography sx={{ color: "#188417" }}>
            {props?.contractNumber}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button
            onClick={() => {
              navigator.clipboard
                .writeText(props?.contractNumber)
                .then(() => {
                  setCopyText("کپی شد");
                  setTimeout(() => {
                    setCopyText("کپی کردن");
                  }, 1000);
                })
                .catch(() => {
                  throw Error("not copied");
                });
            }}
            variant="contained"
          >
            <FileCopy sx={{ mr: 1 }} />
            <Typography>{copyText}</Typography>
          </Button>
          <Button
            onClick={() => {
              props?.onClose();
              navigate(
                `${RolePath[user?.user_info?.type?.name]}${contractsPath}/${
                  props?.contractId
                }`
              );
            }}
            variant="contained"
            sx={{ ml: 2 }}
          >
            <Download />
            <Typography>دانلود قرارداد</Typography>
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default SuccessSign;
