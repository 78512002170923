import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawer: false,
};

const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.drawer = true;
    },
    closeDrawer: (state) => {
      state.drawer = false;
    },
  },
});

const { reducer } = globalSlice;

export const { openDrawer, closeDrawer } = globalSlice.actions;
export default { reducer };
