/* eslint-disable react/prop-types */
import DateSwiper from "@/components/farm-management-modules/DateSwiper";
import IndexesSelect from "@/components/farm-management-modules/IndexesSelect";
import {
  FarmManagementStates,
  setFarmManageMentState,
} from "@/redux/slices/farmManagementSlice";
import { TonalityOutlined } from "@mui/icons-material";
import {
  Box,
  Fade,
  IconButton,
  List,
  Menu,
  Paper,
  Typography,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const CustomColorsMenu = ({
  anchorEl = null,
  handleClose = () => {},
  children,
}) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      PaperProps={{
        sx: {
          border: (theme) => `1px solid ${theme.palette.grey["100"]}`,
          maxHeight: 200,
        },
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      {children}
    </Menu>
  );
};

const ElementsOntheMap = ({ indexImage, ranges, dates }) => {
  const dispatch = useDispatch();
  const [anchorColorsEl, setAnchorColorsEl] = useState(null);
  // const [getShapeFileLink] = useGetShapeFileLinkMutation();

  const selectedIndex = useSelector(
    (state) => state.farmManagement[FarmManagementStates.selectedIndex]
  );

  const handleOpenColorsMenu = useCallback((e) => {
    setAnchorColorsEl(e.currentTarget);
  }, []);

  return (
    <>
      <CustomColorsMenu
        anchorEl={anchorColorsEl}
        handleClose={() => setAnchorColorsEl(null)}
      >
        <List
          sx={{ width: "180px" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {indexImage?.index_info?.colors ? (
            indexImage?.index_info?.colors?.map((item, index) => (
              <Box
                key={index}
                sx={{ display: "flex", px: 2, justifyContent: "space-between" }}
              >
                <div style={{ background: item, width: 20, height: 20 }} />
                <Typography>
                  {ranges[index]?.toFixed(2)} - {ranges[index + 1]?.toFixed(2)}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography textAlign="center" style={{ textAlign: "center" }}>
              یافت نشد
            </Typography>
          )}
        </List>
      </CustomColorsMenu>

      <>
        <IndexesSelect
          sx={{
            width: 150,
            position: "absolute",
            top: { xs: "60px", md: "18px" },
            left: { xs: "2%", md: "10px" },
          }}
          value={selectedIndex}
          onChange={(e) =>
            dispatch(
              setFarmManageMentState({
                [FarmManagementStates.selectedIndex]: e.target.value,
              })
            )
          }
        />

        <Paper
          sx={{
            width: { xs: 300, md: 350, lg: 500 },
            px: 1,
            height: 55,
            position: "absolute",
            top: { xs: "120px", md: "18px" },
            left: { xs: "2%", md: "180px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: (theme) => `1px solid ${theme.palette.grey["100"]}`,
          }}
        >
          <DateSwiper dates={dates} />
          {/* تاریخ تصویر:
            {convertToJalali(
              dates?.find((ele) => ele.id === selectedImageId)?.date
            ) ?? "بدون تاریخ"} */}
        </Paper>
        <Paper
          sx={{
            width: { xs: 150, md: 120 },
            height: 55,
            position: "absolute",
            top: { xs: "182px", md: "18px" },
            left: { xs: "2%", md: "570px", lg: "710px" },
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            border: (theme) => `1px solid ${theme.palette.grey["100"]}`,
          }}
        >
          <IconButton onClick={handleOpenColorsMenu}>
            <TonalityOutlined />
          </IconButton>
        </Paper>
      </>
    </>
  );
};

export default memo(ElementsOntheMap);
