/* eslint-disable react/prop-types */
import { Line } from "react-chartjs-2";

const TempretureChart = ({ data: chartData }) => {
  const data = {
    labels: chartData?.map((item) =>
      Intl.DateTimeFormat("fa-IR-u-nu-latn", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(item?.datetimeEpoch * 1000))
    ),
    datasets: [
      {
        label: "دمای هوا",
        data: chartData?.map((item) => item?.feelslike),
        fill: true,
        borderWidth: 0.8,
        borderColor: "#b70000",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradientBg = ctx.createLinearGradient(0, 0, 0, 400);

          gradientBg.addColorStop(0, "#b7000060");
          gradientBg.addColorStop(0.5, "transparent");
          gradientBg.addColorStop(1, "transparent");

          return gradientBg;
        },
        tension: 0.1,
      },
    ],
  };
  const config = {
    type: "line",
    data: data,
    options: {
      plugins: {
        legend: { display: false },
      },

      responsive: true,
      maintainAspectRatio: false,

      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          display: false,
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
      },
    },
  };
  return (
    <div>
      <Line {...config} />
    </div>
  );
};

export default TempretureChart;
