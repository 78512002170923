import { mainSplitApi } from ".";

export const authenticationApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/api/accounts/api/v1/login/",
        body: { phone_number: data.phoneNumber, captcha: data.captcha },
        method: "POST",
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: "/api/accounts/api/v1/register/",
        body: {
          phone_number: data.phoneNumber,
          national_id: data.nationalId,
          captcha: data.captcha,
        },
        method: "POST",
      }),
    }),
    registerOtpVerify: builder.mutation({
      query: (token) => ({
        url: "/api/accounts/api/v1/login/otp-verify/",
        body: {
          token,
        },
        method: "POST",
      }),
    }),
    loginOtpVerify: builder.mutation({
      query: (token) => ({
        url: "/api/accounts/api/v1/login/otp-verify/",
        body: {
          token,
        },
        method: "POST",
      }),
    }),
    postRereshToken: builder.mutation({
      query: (refresh_token) => ({
        url: "/api/accounts/api/v1/refresh-token/",
        body: {
          refresh_token,
        },
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useRegisterOtpVerifyMutation,
  useLoginOtpVerifyMutation,
  usePostRereshTokenMutation,
} = authenticationApi;
