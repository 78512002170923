/* eslint-disable react/prop-types */
import Thermometer from "@/assets/images/Meteorology/thermometer.png";
import humidity from "@/assets/images/Meteorology/humidity.png";
import windSpeed from "@/assets/images/Meteorology/windSpeed.png";
import airPressure from "@/assets/images/Meteorology/airPressure.png";
import shadow from "@/assets/images/weather/shadow.svg";
import { Box, Divider, Typography } from "@mui/material";
import { WEATHER_IMAGES } from "@/constants/weatherImages";
import styled from "@emotion/styled";
import HandleServerData from "@/components/common/HandleServerData";

const Item = styled(Box)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const CurrentWeatherInfo = ({
  data,
  isLoadingWeatherData,
  isErrorWeatherData,
  isSuccessWeatherData,
}) => {
  const elementOfInfo = [
    {
      icon: Thermometer,
      title: `دما`,
      value: `${data?.feelslike}°C`,
    },
    {
      icon: humidity,
      title: `رطوبت`,
      value: `${data?.humidity}%`,
    },
    {
      icon: windSpeed,
      title: `سرعت باد`,
      value: `${data?.windspeed} km/h`,
    },
    {
      icon: airPressure,
      title: `فشار هوا`,
      value: `${data?.pressure} mb`,
    },
  ];

  return (
    <Box
      sx={{
        height: 80,
        display: "flex",
        alignItems: "center",
        px: (theme) => theme.spacing(2),
        py: (theme) => theme.spacing(2),
        overflow: "hidden",
        borderBottom: "1px #00000091 solid",
      }}
    >
      <HandleServerData
        isLoading={isLoadingWeatherData}
        isError={isErrorWeatherData}
        isSuccess={isSuccessWeatherData}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30%",
            }}
          >
            <Item sx={{ position: "relative" }}>
              <Box
                sx={{
                  backgroundImage: `url(${shadow})`,
                  backgroundRepeat: "no-repeat",
                  width: "80px",
                  height: "80px",
                  position: "absolute",
                  backgroundSize: "120% 150%",
                  backgroundPosition: "center center",
                  top: "calc(50% - 40px)",
                  zIndex: 3,
                  opacity: 0.8,
                }}
              />
              <img
                style={{ zIndex: 4 }}
                width={45}
                src={WEATHER_IMAGES[data?.icon]}
                alt=""
              />
            </Item>
            <Item>
              <Typography dir="ltr">{data?.feelslike}&#8451;</Typography>
              <Typography
                sx={{ color: (theme) => theme.palette.pinkSwan, marginLeft: 1 }}
                variant="overline"
                dir="ltr"
              >
                {data?.datetimeEpoch &&
                  `${Intl.DateTimeFormat("fa-IR-u-nu-latn", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(new Date(data?.datetimeEpoch * 1000))} `}
                آخرین به روز رسانی ساعت
              </Typography>
            </Item>
            <Item>
              <Typography
                sx={{ color: (theme) => theme.palette.pinkSwan }}
                variant="overline"
              >
                {data?.datetimeEpoch &&
                  Intl.DateTimeFormat("fa-IR-u-nu-latn", {
                    month: "long",
                    day: "numeric",
                  }).format(new Date(data?.datetimeEpoch * 1000))}
              </Typography>
              <Typography
                sx={{ color: (theme) => theme.palette.pinkSwan }}
                dir="ltr"
                variant="overline"
              >
                {`${Intl.DateTimeFormat("fa-IR-u-nu-latn", {
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date())} `}
                ساعت
              </Typography>
            </Item>
            <Divider orientation="vertical" flexItem />
          </Box>

          <Item>
            <Typography dir="ltr">{`ساعت آفتابی`}</Typography>
            <Typography
              sx={{ color: (theme) => theme.palette.pinkSwan }}
              variant="overline"
              dir="ltr"
            >
              {`11:52`} ساعت
            </Typography>
          </Item>
          <Box
            sx={{
              display: "flex",
              width: "40%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {elementOfInfo.map((item, index) => (
              <Item key={index}>
                <img
                  width={15}
                  style={{ marginBottom: 8 }}
                  src={item.icon}
                  alt=""
                />
                <Typography dir="ltr">{item.title}</Typography>
                <Box>
                  <Typography
                    sx={{ color: (theme) => theme.palette.pinkSwan, mr: 0.5 }}
                    variant="overline"
                  >
                    {`${item.title}:`}
                  </Typography>
                  <Typography
                    sx={{ color: (theme) => theme.palette.pinkSwan }}
                    variant="overline"
                    dir="ltr"
                  >
                    {item.value}
                  </Typography>
                </Box>
              </Item>
            ))}
          </Box>
        </Box>
      </HandleServerData>
    </Box>
  );
};

export default CurrentWeatherInfo;
