import modalService from "@/components/common/Modal/modalService";
import ContractDetailTemplate from "@/components/contract-registration-modules/ContractDetailTemplate";
import FinalizationSign from "@/components/modals/FinalizationSign";
import {
  useGetFinaliztionQuery,
  useSendOTPFinaliztionMutation,
} from "@/services/contracts";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const SigningAgreement = () => {
  // const [check, setCheck] = useState(false);
  const [isReaded, setIsReaded] = useState(false);
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const [sendOTPFinaliztion, { isLoading }] = useSendOTPFinaliztionMutation();
  const { data } = useGetFinaliztionQuery(contractId, {
    skip: !contractId,
  });
  // console.log(data);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          height: 400,
          direction: "rtl",
          p: "40px 20px",
          overflowY: "auto",
          border: "1px solid #00000033",
          borderRadius: "10px",
          background: "#fff",
        }}
      >
        <ContractDetailTemplate
          contractDetail={data}
          disableSign
          disableContractNumber
        />
      </Box>

      <Box sx={{ mt: 2, mx: 4 }}>
        <FormControlLabel
          label="قرارداد را به طور دقیق مطالعه نمودم و با آن موافق هستم"
          control={
            <Checkbox
              checked={isReaded}
              onChange={(e) => setIsReaded(e.target.checked)}
            />
          }
        />
      </Box>

      {isReaded && (
        <>
          {/* <Box
            sx={{
              mt: 4,
              mb: "22px",
              width: { xs: "80%", md: "455px" },
              background: "#E4F4F6",
              borderRadius: 2,
              padding: "5px 20px",
            }}
          >
            <Typography fontSize="12px" textAlign="center" lineHeight={2}>
              کاربر گرامی ادامه فرآیند قرارداد نیازمند دریافت کد به عنوان رمز
              یکبار مصرف در قالب پیامک است. این کد به منزله امضای دیجیتال شماست
              ، آن را در دسترس دیگران قرار ندهید .
            </Typography>
          </Box> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              disabled={!isReaded}
              onClick={() =>
                modalService.open(FinalizationSign, {
                  sendOTPFinaliztion,
                  sign: true,
                })
              }
              // onClick={() => {
              //   sendOTPFinaliztion(contractId)
              //     .unwrap()
              //     .then(() => {
              //       toast.success("کد با موفقیت ارسال شد!");
              //       modalService.open(FinalizationSign, {
              //         sendOTPFinaliztion,
              //         sign: check,
              //       });
              //     });
              // }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "210px",
                height: "40px",
                background: "#005815",
                borderRadius: "8px",
                cursor: "pointer",
                color: "#fff",
                "&:hover": {
                  background: "#01711b",
                },
              }}
            >
              <Typography fontWeight="bold">ثبت امضا</Typography>
            </Button>
            {/* <Box>
              <FormControlLabel
                sx={{ mt: 2 }}
                label="تمایل به ثبت امضای دیجیتال دارم (اختیاری)"
                control={
                  <Checkbox
                    checked={check}
                    onChange={(e) => setCheck(e.target.checked)}
                  />
                }
              />
            </Box> */}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SigningAgreement;
