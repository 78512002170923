/* eslint-disable react/prop-types */
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { Fragment } from "react";

const SelectProduct = ({
  productLsit,
  value = "",
  handleChange,
  loading,
  width = 250,
  mx,
}) => {
  return (
    <TextField
      sx={{ width, mx }}
      size="small"
      InputProps={{
        endAdornment: (
          <Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
          </Fragment>
        ),
      }}
      label="محصول"
      select
      value={value}
      onChange={handleChange}
    >
      {productLsit.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectProduct;
