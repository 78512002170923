/* eslint-disable react/prop-types */
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

import { useLazyGetContractSatplatQuery } from "@/services/contracts";
import { LayersRounded, Search } from "@mui/icons-material";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";

const MapBox = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Item = ({ title, value, my, valueColor = "#000" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        my,
      }}
    >
      {title && <Typography color="GrayText">{title}</Typography>}
      <Typography textAlign="left" color={valueColor}>
        {value}
      </Typography>
    </Box>
  );
};

const Card = ({
  item,
  onClick,
  selected,
  lastElementRef,
  updateContractSatplatId,
}) => {
  const [getContractSatplat] = useLazyGetContractSatplatQuery();

  return (
    <Box
      ref={lastElementRef}
      onClick={onClick}
      sx={{
        border: "1px solid",
        borderColor: selected ? "green" : "gray",
        background: selected ? "#a4a4a454" : "",
        p: 1,
        borderRadius: 2,
        cursor: "pointer",
        mb: 1,
        direction: "ltr",
        position: "relative",
        "&:last-child": {
          mb: 0,
        },
      }}
    >
      {!item?.has_satplat_farm_id && (
        <Box
          sx={{
            position: "absolute",
            borderRadius: 2,
            inset: 0,
            background: "#551900ce",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              getContractSatplat(item?.id)
                .unwrap()
                .then(() => {
                  updateContractSatplatId(item);
                });
            }}
          >
            فعالسازی
          </Button>
        </Box>
      )}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography sx={{ textAlign: "center" }}>
          {item?.contract_number}
        </Typography>
      </Box>
      <Divider variant="middle" sx={{ mb: 1, mt: 1 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* <Item title="شناسه قرارداد:" value={item?.contract_number} /> */}
        <Item title="مساحت زمین:" value={`${item?.farm_info?.area} هکتار`} />
        <Item title="محصول:" value={item?.farm_info?.product} />
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color="GrayText">مباشر:</Typography>
          <Typography textAlign="center">
            {item?.cooperative_company?.cooperative_company_title}
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

const Meteorology = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.pathname.split("/").at(-1);

  const {
    currentSearch,
    setContracts,
    setPage,
    setCurrentSearch,
    contracts,
    lastElementRef,
    selectedFarm,
    setSelectedFarm,
  } = useOutletContext() ?? {};

  return (
    <MapBox>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            display: { xs: "none", md: "initial" },
            width: "260px",

            borderRight: `1px solid gray`,
            height: "100vh",
          }}
        >
          <Box sx={{ height: "80px", px: 2, pt: 2 }}>
            <TextField
              fullWidth
              InputProps={{
                endAdornment: <Search />,
              }}
              label="جستجو"
              placeholder="شناسه قرارداد/نام نام خانوادگی"
              value={currentSearch}
              onChange={(e) => {
                setContracts([]);
                setPage(1);
                setCurrentSearch(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ height: "calc(100% - 100px)", overflowY: "auto", px: 2 }}>
            {contracts?.map((item, index) => (
              <Card
                lastElementRef={
                  index === contracts?.length - 1 ? lastElementRef : null
                }
                updateContractSatplatId={(data) => {
                  const cpoiedContracts = [...contracts];
                  const foundeIndex = cpoiedContracts?.findIndex(
                    (ele) => ele?.id === data?.id
                  );
                  if (foundeIndex >= 0) {
                    cpoiedContracts[foundeIndex] = data;
                    setSelectedFarm(data);
                    setContracts(cpoiedContracts);
                  }
                }}
                selected={item?.farm_info?.id === selectedFarm?.farm_info?.id}
                key={item?.id}
                item={item}
                onClick={() => {
                  setSelectedFarm(item);
                  // setPolygon(item?.farm?.polygon?.coordinates);
                }}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "calc(100% - 260px)" },
            height: "100%",
          }}
        >
          <Tabs
            sx={{ borderBottom: "0.5px solid gray", mb: 0.5 }}
            value={type}
            onChange={(e, value) => navigate(`${value}`)}
            aria-label="basic tabs example"
          >
            <Tab value="prediction" label="پیش بینی" />
            <Tab value="analyze" label="تحلیل" />
          </Tabs>
          {selectedFarm ? (
            <Outlet context={selectedFarm} />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "94.5vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LayersRounded sx={{ width: 60, height: 60, mb: 4 }} />
              <Typography variant="h5">
                زمین موردنظرتان را انتخاب کنید.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </MapBox>
  );
};

export default Meteorology;
