import { mainSplitApi } from ".";

export const contractsApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getContractList: builder.query({
      query: (data) => ({
        url: "/api/contract-hub/api/v1/contract/",
        params: {
          search: data.search,
          ordering: data.ordering,
          page: data.page,
          page_size: data.page_size,
          state: data.state,
        },
        method: "GET",
      }),
      providesTags: ["contract", "contractDelete"],
    }),
    validatePolygon: builder.mutation({
      query: (data) => ({
        url: "/api/contract-hub/api/v1/farm/validate/polygon/",
        body: data,
        method: "POST",
      }),
    }),
    createContract: builder.mutation({
      query: (data) => ({
        url: "/api/contract-hub/api/v1/contract/create/",
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getProfileInfo: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/profile/validation/`,
        method: "GET",
      }),
    }),
    getProfile: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/profile/validation/`,
        method: "GET",
      }),
    }),
    postProfile: builder.mutation({
      query: ({ data, contractId }) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/profile/validation/`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getContractState: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/state/`,
        method: "GET",
      }),
      providesTags: ["contract", "contract-state"],
    }),
    postContractStateChange: builder.mutation({
      query: ({ contractId, stage }) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/state/`,
        body: { stage },
        method: "POST",
      }),
      invalidatesTags: ["contract-state"],
    }),
    getStates: builder.query({
      query: () => ({
        url: `/api/locations/api/v1/state/brief-list/`,
        method: "GET",
      }),
    }),
    getCities: builder.query({
      query: (stateId) => ({
        url: `/api/locations/api/v1/city/brief-list/`,
        params: { state: stateId },
        method: "GET",
      }),
    }),
    getPahneList: builder.query({
      query: (params) => ({
        url: `/api/contract-hub/api/v1/farm/pahne/list/`,
        params,
        method: "GET",
      }),
    }),
    getProductList: builder.query({
      query: () => ({
        url: `/api/providers/api/v1/product/`,
        method: "GET",
      }),
      providesTags: ["Product"],
    }),
    declareFarm: builder.mutation({
      query: ({ contractId, data }) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/farm/declaration/`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getBankValidation: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/bank/validation/`,
        method: "GET",
      }),
      providesTags: ["contract", "input_selection"],
    }),
    postBankValidation: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/bank/validation/`,
        body: {},
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getInsuranceValidation: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/insurance/validation/`,
        method: "GET",
      }),
    }),
    postInsuranceValidation: builder.mutation({
      query: ({ contractId, data }) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/insurance/validation/`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getSupportValidation: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/support/validation/`,
        method: "GET",
      }),
    }),
    postSupportValidation: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/support/validation/`,
        body: {},
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getKnowledgeValidation: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/knowledge/agreement/`,
        method: "GET",
      }),
    }),
    postKnowledgeValidation: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/knowledge/agreement/`,
        body: {},
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getPurchaseValidation: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/purchase/agreement/`,
        method: "GET",
      }),
    }),
    postPurchaseValidation: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/purchase/agreement/`,
        body: {},
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    sendOTPFinaliztion: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/finalization/send-otp/`,
        body: {},
        method: "POST",
      }),
    }),
    getFinaliztion: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/finalization/`,
        method: "GET",
      }),
    }),
    postFinaliztion: builder.mutation({
      query: ({ contractId, data }) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/finalization/`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getGeneralContractStateList: builder.query({
      query: () => ({
        url: `/api/contract-hub/api/v1/generals/contract/state/list/`,
        method: "GET",
      }),
    }),
    getlastOpenContract: builder.query({
      query: () => ({
        url: `/api/contract-hub/api/v1/last-open-contract/`,
        method: "GET",
      }),
    }),
    getStageList: builder.query({
      query: () => ({
        url: `/api/contract-hub/api/v1/generals/contract/stage/list/`,
        method: "GET",
      }),
    }),
    getCompanyProvider: builder.query({
      query: (params) => ({
        url: `/api/providers/api/v1/company-provider/`,
        params,
        method: "GET",
      }),
    }),
    getCooperativeBrief: builder.query({
      query: (params) => ({
        url: `/api/providers/api/v1/cooperative-provider/brief-list/`,
        params,
        method: "GET",
      }),
    }),
    getCompanyDirects: builder.query({
      query: (params) => ({
        url: `/api/profiles/api/v1/direct/brief-list/`,
        params,
        method: "GET",
      }),
    }),
    getCompanyProviderInputs: builder.query({
      query: (params) => ({
        url: `/api/providers/api/v1/company-provider/input/`,
        params,
        method: "GET",
      }),
    }),
    getCompanyProviderBrief: builder.query({
      query: (params) => ({
        url: `/api/providers/api/v1/company-provider/brief-list/`,
        params,
        method: "GET",
      }),
    }),
    getInputTypesBrief: builder.query({
      query: () => ({
        url: `/api/providers/api/v1/company-provider/input-type/brief-list/`,
        method: "GET",
      }),
    }),
    getDeliverySilo: builder.query({
      query: () => ({
        url: `/api/providers/api/v1/delivery-silo/`,
        method: "GET",
      }),
    }),
    deleteContract: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["contractDelete"],
    }),
    getContractDetail: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/`,
        method: "GET",
      }),
    }),
    // admin
    getAdminContracts: builder.query({
      query: (data) => ({
        url: `/api/contract-mgmt/api/v1/admins/contract/`,
        params: {
          search: data.search,
          ordering: data.ordering,
          page: data.page,
          page_size: data.page_size,
          state: data.state,
          location: data.location,
          cooperative_company: data.cooperative_company,
        },
        method: "GET",
      }),
      providesTags: ["contract", "contractDelete"],
    }),
    getAdminGeneralContractStateList: builder.query({
      query: (params) => ({
        url: `/api/contract-mgmt/api/v1/admins/contract/state/list/`,
        method: "GET",
        params,
      }),
    }),
    getAdminTotalContractStat: builder.query({
      query: (params) => ({
        url: `/api/contract-mgmt/api/v1/admin/contract-stats/total/`,
        method: "GET",
        params,
      }),
      providesTags: ["contractDelete"],
    }),
    getAdminContractById: builder.query({
      query: (contractId) => ({
        url: `/api/contract-mgmt/api/v1/admins/contract/${contractId}/`,
        method: "GET",
      }),
      providesTags: ["contract"],
    }),
    deleteAdminContract: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-mgmt/api/v1/admins/contract/${contractId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["contractDelete"],
    }),
    getCompanyDirectStats: builder.query({
      query: () => ({
        url: `/api/contract-mgmt/api/v1/company-direct/contract/stats/`,
        method: "GET",
      }),
      providesTags: ["contract"],
    }),
    postAdminContractSetState: builder.mutation({
      query: ({ contractId, data }) => {
        return {
          url: `/api/contract-mgmt/api/v1/admins/contract/${contractId}/set-state/`,
          body: { state: data.state, denial_reason: data.denialReason },
          method: "POST",
        };
      },
      invalidatesTags: ["contract"],
    }),
    postInputSelection: builder.mutation({
      query: ({ contractId, data }) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/provider/input/selection/`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["input_selection"],
    }),
    deleteInputSelection: builder.mutation({
      query: ({ contractId, id }) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/provider/input/selection/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["input_selection"],
    }),
    postInputValidation: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/provider/input/validation/`,
        body: {},
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getInputValidation: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/provider/input/validation/`,
        method: "GET",
      }),
      providesTags: ["input_selection"],
    }),
    postGuaranteeValidation: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/guarantee/validation/`,
        body: {},
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    getGuaranteeValidation: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/guarantee/validation/`,
        method: "GET",
      }),
      providesTags: ["contract"],
    }),
    getContractFile: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/${contractId}/document/`,
        method: "GET",
      }),
    }),
    getAdminStats: builder.query({
      query: (params) => ({
        url: `/api/contract-mgmt/api/v1/admins/provider-input/list/`,
        method: "GET",
        params,
      }),
    }),
    getAdminCentroids: builder.query({
      query: (params) => ({
        url: `/api/contract-mgmt/api/v1/admins/contract-farm/centroid/`,
        params,
      }),
      providesTags: ["contract", "contractDelete"],
    }),
    getContractSatplat: builder.query({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contact/satplat/add/${contractId}/`,
      }),
    }),
    putFinalPrice: builder.mutation({
      query: ({ productId, price }) => ({
        url: `/api/contract-mgmt/api/v1/admin/final-price/product/${productId}/`,
        body: { price },
        method: "PUT",
      }),
      invalidatesTags: ["Product"],
    }),
    setCompanyDirectPurchase: builder.mutation({
      query: ({ contractId, final_product_weight }) => ({
        url: `/api/contract-mgmt/api/v1/company-direct/purchase/product/${contractId}/`,
        body: { final_product_weight },
        method: "POST",
      }),
      invalidatesTags: ["contract"],
    }),
    sendPurchaseOTP: builder.mutation({
      query: (contractId) => ({
        url: `/api/contract-hub/api/v1/contract/send-otp/purchase/${contractId}/`,
        body: {},
        method: "POST",
      }),
    }),
    submitPurchaseOTP: builder.mutation({
      query: ({ purchaseId, token }) => ({
        url: `/api/contract-hub/api/v1/contract/verify/purchase/${purchaseId}/`,
        body: { token },
        method: "PUT",
      }),
      invalidatesTags: ["contract"],
    }),
    submitCompanyDirectPurchase: builder.mutation({
      query: ({ purchaseId, isPayed }) => ({
        url: `/api/contract-mgmt/api/v1/company-direct/purchase/verify/${purchaseId}/`,
        body: { isPayed },
        method: "PUT",
      }),
      invalidatesTags: ["contract"],
    }),
  }),
});

export const {
  useSubmitCompanyDirectPurchaseMutation,
  useSubmitPurchaseOTPMutation,
  useSendPurchaseOTPMutation,
  useSetCompanyDirectPurchaseMutation,
  usePutFinalPriceMutation,
  useGetAdminTotalContractStatQuery,
  useGetAdminCentroidsQuery,
  useLazyGetContractFileQuery,
  usePostContractStateChangeMutation,
  useGetGuaranteeValidationQuery,
  usePostGuaranteeValidationMutation,
  useGetInputValidationQuery,
  usePostInputValidationMutation,
  useDeleteInputSelectionMutation,
  usePostInputSelectionMutation,
  useLazyGetContractListQuery,
  useGetContractListQuery,
  useValidatePolygonMutation,
  useGetProfileQuery,
  useCreateContractMutation,
  usePostProfileMutation,
  useGetContractStateQuery,
  useLazyGetStatesQuery,
  useGetStatesQuery,
  useLazyGetCitiesQuery,
  useGetPahneListQuery,
  useGetProductListQuery,
  useDeclareFarmMutation,
  useGetBankValidationQuery,
  usePostBankValidationMutation,
  useGetInsuranceValidationQuery,
  usePostInsuranceValidationMutation,
  useGetSupportValidationQuery,
  usePostSupportValidationMutation,
  useGetKnowledgeValidationQuery,
  usePostKnowledgeValidationMutation,
  useGetPurchaseValidationQuery,
  usePostPurchaseValidationMutation,
  useSendOTPFinaliztionMutation,
  usePostFinaliztionMutation,
  useGetFinaliztionQuery,
  useGetGeneralContractStateListQuery,
  useLazyGetlastOpenContractQuery,
  useGetStageListQuery,
  useDeleteContractMutation,
  useGetDeliverySiloQuery,
  useGetCompanyProviderQuery,
  useLazyGetCooperativeBriefQuery,
  useGetContractDetailQuery,
  useLazyGetAdminContractsQuery,
  useGetAdminContractsQuery,
  useGetAdminGeneralContractStateListQuery,
  useGetAdminContractByIdQuery,
  useLazyGetAdminContractByIdQuery,
  usePostAdminContractSetStateMutation,
  useGetCompanyProviderInputsQuery,
  useGetCompanyProviderBriefQuery,
  useGetInputTypesBriefQuery,
  useGetCompanyDirectStatsQuery,
  useDeleteAdminContractMutation,
  useLazyGetAdminStatsQuery,
  useLazyGetContractSatplatQuery,
  useLazyGetCompanyDirectsQuery,
} = contractsApi;
