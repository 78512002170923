import { Box, Grid, Typography } from "@mui/material";

import ReportCard from "@/components/ReportCard";
import spring from "@/assets/images/reports/spring.png";
import summer from "@/assets/images/reports/summer.png";
import autumn from "@/assets/images/reports/autumn.png";
import winter from "@/assets/images/reports/winter.png";
import { useGetReportsListQuery } from "@/services/reports";
import { convertToJalaliDate } from "@/utils/convertToJalaliDate";
import HandleServerData from "@/components/common/HandleServerData";
import modalService from "@/components/common/Modal/modalService";
import NoReportsFound from "@/components/Error/NoReportsFound";
import { useParams } from "react-router-dom";
import PreviewReportModal from "@/components/modals/PreviewReportModal";

const images = {
  spring,
  summer,
  autumn,
  winter,
};

const Reports = () => {
  const params = useParams();
  const {
    data: reports,
    isLoading,
    isSuccess,
    isError,
  } = useGetReportsListQuery(
    {
      farmId: params?.id,
    },
    { skip: !params?.id }
  );

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {reports?.count && (
          <Typography fontWeight="bold" className="mt-4 mx-8">
            شماره گزارش : {reports?.count}
          </Typography>
        )}
        <HandleServerData
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
        >
          <Grid container>
            {reports?.data?.length > 0 ? (
              reports?.data?.map((report) => (
                <Grid
                  key={report?.id}
                  item
                  md={12}
                  lg={4}
                  xl={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 6,
                  }}
                >
                  <ReportCard
                    disableDelete
                    src={images[report?.season] ?? ""}
                    reportNumber={report?.report_number}
                    receiveDate={convertToJalaliDate(report?.date)}
                    farmName={report?.farm?.name}
                    cultivationType={report?.farm_info?.product_type}
                    expertName={`${report?.writer?.first_name} ${report?.writer?.last_name}`}
                    handleMoreAction={() => {
                      modalService.open(PreviewReportModal, {
                        id: report?.id,
                        farmId: params?.id,
                      });
                    }}
                  />
                </Grid>
              ))
            ) : (
              <NoReportsFound />
            )}
          </Grid>
        </HandleServerData>
      </Box>
    </Box>
  );
};

export default Reports;
