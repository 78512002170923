const useChartConfig = ({ labels, dataSets, callbacks, scales, type }) => {
  const data = {
    labels: labels,
    datasets: dataSets,
  };
  const config = {
    type: type ?? "line",
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      hover: {
        mode: "nearest",
        intersect: true,
      },
      plugins: {
        tooltip: {
          intersect: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
        ...scales,
      },
    },
  };

  return { config };
};

export default useChartConfig;
