/* eslint-disable react/prop-types */
import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { moneyFormat } from "@/utils/moneyFormat";
import { useState } from "react";
import {
  useSetCompanyDirectPurchaseMutation,
  useSubmitCompanyDirectPurchaseMutation,
} from "@/services/contracts";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { useGetLastProductFinalPriceQuery } from "@/services/products";

const ItemTitle = ({ title, value, valueColor = "#000" }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Typography sx={{ mr: 1, fontWeight: "bold" }}>{title}</Typography>
      <Typography sx={{ color: valueColor }}>{value}</Typography>
    </Box>
  );
};

const PriceTitle = ({ title, value }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ mr: 1, fontWeight: "bold" }}>{title}</Typography>
      <Typography sx={{ background: "#F4F6F8", px: 2, py: 0.5 }}>
        {value}
      </Typography>
    </Box>
  );
};

const GuaranteedPurchaseModal = (props) => {
  const { profile, farm_info, contract_number, total_cost, purchase, id } =
    props?.data ?? {};
  const [error, setError] = useState("");
  const [finalProductWeight, setFinalProductWeight] = useState(
    purchase?.final_product_weight ?? null
  );
  const hasWeight = !!purchase?.final_product_weight;
  const isWaitingForFarmerSubmit = purchase?.state === "waiting";
  const isApprovedByFarmer = purchase?.state === "approved";
  const isPayedPurchase = !!purchase?.is_payed;
  const hasPurchase = !!purchase;

  const [setCompanyDirectPurchase, { isLoading }] =
    useSetCompanyDirectPurchaseMutation();
  const [submitCompanyDirectPurchase, { isLoading: submitLoading }] =
    useSubmitCompanyDirectPurchaseMutation();
  const { data: lastFinalPrice } = useGetLastProductFinalPriceQuery();
  const lastPriceObj = lastFinalPrice?.find(
    (ele) => ele.product_id === farm_info?.product_id
  );

  console.log(lastPriceObj);

  return (
    <ModalComponent open={props.open}>
      <Box
        sx={{
          p: { xs: 4, md: 6 },
          maxWidth: { xs: "100%", md: "800px" },
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: "10px", left: "10px" }}
          onClick={props.onClose}
        >
          <Close />
        </IconButton>

        <Box
          sx={{
            border: "1px solid #00AD73",
            position: "relative",
            borderRadius: 1,
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            columnGap: 4,
            rowGap: 4,
          }}
        >
          <Box
            sx={{
              background: "#fff",
              position: "absolute",
              top: -12,
              left: 20,
              px: 2,
            }}
          >
            <Typography fontWeight="bold" fontSize="17px">
              اطلاعات کشاورز
            </Typography>
          </Box>
          <ItemTitle title="نام:" value={profile?.first_name} />
          <ItemTitle title="نام خانوادگی:" value={profile?.last_name} />
          <ItemTitle title="محصول:" value={farm_info?.product} />
          <ItemTitle title="شماره قرارداد:" value={contract_number} />
          <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <PriceTitle
              title="هزینه قرار داد:"
              value={`${moneyFormat(total_cost)} ریال`}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 4,
            mt: 4,
          }}
        >
          <NumericFormat
            value={lastPriceObj?.product_price}
            customInput={TextField}
            size="small"
            sx={{ width: 200 }}
            InputProps={{
              endAdornment: <Typography>ریال</Typography>,
              readOnly: true,
            }}
            label="قیمت تضمینی"
            thousandSeparator
          />

          <NumericFormat
            value={finalProductWeight}
            error={error}
            helperText={error}
            onValueChange={(values) => {
              setFinalProductWeight(values.value);
            }}
            customInput={TextField}
            size="small"
            sx={{ width: 200 }}
            InputProps={{
              endAdornment: <Typography>کیلوگرم</Typography>,
              readOnly: hasWeight,
            }}
            label="حجم خرید"
            thousandSeparator
          />

          <NumericFormat
            customInput={TextField}
            value={lastPriceObj?.product_price * finalProductWeight}
            sx={{ width: 200 }}
            size="small"
            InputProps={{
              endAdornment: <Typography>ریال</Typography>,
              readOnly: true,
            }}
            label="مبلغ خرید تضمینی"
            thousandSeparator
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            my: 4,
          }}
        >
          <PriceTitle
            title="مبلغ کل:"
            value={
              <div style={{ display: "flex" }}>
                <p style={{ direction: "ltr", marginLeft: 5 }}>
                  {moneyFormat(
                    lastPriceObj?.product_price * finalProductWeight -
                      total_cost
                  )}
                </p>
                <span>ریال</span>
              </div>
            }
          />
          <Typography
            color="error"
            variant="overline"
            sx={{ ml: 1, fontWeight: "bold" }}
          >
            * این مبلغ بدهی شما به کشاورز می باشد.
          </Typography>
        </Box>
        {(isApprovedByFarmer || isWaitingForFarmerSubmit) && (
          <Box>
            <ItemTitle
              valueColor={isApprovedByFarmer ? "green" : "red"}
              title="وضعیت"
              value={
                isApprovedByFarmer
                  ? "تایید شده توسط کشاورز"
                  : "در انتظار تایید کشاورز"
              }
            />
          </Box>
        )}
        {!isPayedPurchase && (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              my: 2,
            }}
          >
            {hasPurchase ? (
              <Button
                disabled={submitLoading || isWaitingForFarmerSubmit}
                variant="contained"
                onClick={() => {
                  submitCompanyDirectPurchase({
                    purchaseId: purchase?.id,
                    isPayed: true,
                  })
                    .unwrap()
                    .then(() => {
                      toast.success("با موفقیت تایید شد!");
                      props?.onClose();
                    });
                }}
              >
                تایید خرید
              </Button>
            ) : (
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={() => {
                  if (!finalProductWeight > 0) {
                    setError("لطفا حجم خرید را وارد کنید.");
                    return;
                  }
                  setCompanyDirectPurchase({
                    contractId: id,
                    final_product_weight: finalProductWeight,
                  })
                    .unwrap()
                    .then(() => {
                      toast.success("با موفقیت ثبت شد");
                      props?.onClose();
                    });
                }}
              >
                ثبت خرید
              </Button>
            )}
          </Box>
        )}
      </Box>
    </ModalComponent>
  );
};

export default GuaranteedPurchaseModal;
