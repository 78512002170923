/* eslint-disable react/prop-types */
import {
  fertializerDefaultImg,
  poisonDefaultImg,
  seedDefaultImg,
} from "@/assets";
import { useGetCompanyDirectStatsQuery } from "@/services/contracts";
import { moneyFormat } from "@/utils/moneyFormat";
import { Box, Divider, Typography } from "@mui/material";

const AcquireCard = ({ data, title, img, unit }) => {
  return (
    <Box>
      <Box sx={{ height: 40 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 300,
          height: 240,
          p: 2,
          borderRadius: 8,
          background: "#F4F6F8",
          mx: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mt: -6 }}>
          <Box
            sx={{
              width: 60,
              height: 60,
              backgroundImage: `url(${img})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
          <Typography fontWeight="bold" fontSize="24px" ml={1}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
            <Typography>مقدار کل:</Typography>
            <Typography>
              {data?.total_usage} {unit}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>مبلغ کل:</Typography>
            <Typography>{moneyFormat(data?.total_cost)} ریال</Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography>شرکت های تامین کننده:</Typography>
          <Box sx={{ display: "flex", mt: 2 }}>
            {data?.companies?.map((item) => (
              <Box key={item?.id}>
                <img
                  title={item?.title}
                  width={50}
                  src={`${import.meta.env.VITE_PRODUCTION_BASE_URL}${
                    item?.logo
                  }`}
                  alt={item?.title}
                />
                {/* <Typography>{item?.title}</Typography> */}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const CompanyDirectAcquireManagement = () => {
  const { data } = useGetCompanyDirectStatsQuery();

  const inputSelection = data ? data?.inputs : [];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 6,
          justifyContent: "center",
          my: { xs: 4, lg: "auto" },
        }}
      >
        <AcquireCard
          data={inputSelection?.["seed"]}
          title="بذر"
          img={seedDefaultImg}
          unit={"کیلوگرم"}
        />
        <AcquireCard
          data={inputSelection?.["fertilizer"]}
          title="کود"
          img={fertializerDefaultImg}
          unit={"بسته"}
        />
        <AcquireCard
          data={inputSelection?.["poison"]}
          title="سم"
          img={poisonDefaultImg}
          unit={"بسته"}
        />
      </Box>
      <Box>
        <Box
          sx={{
            border: "1px #00AD73 solid",
            borderRadius: 8,
            my: 6,
            p: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: 200,
              }}
            >
              <Typography>تعداد کل قرار داد ها:</Typography>
              <Typography>{data?.total_contract}</Typography>
            </Box>
            <Divider
              sx={{ mx: 1, borderColor: "#00AD73" }}
              orientation="vertical"
              flexItem
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: 200,
              }}
            >
              <Typography>مساحت کل:</Typography>
              <Typography>{data?.total_farm_area} هکتار</Typography>
            </Box>
            <Divider
              sx={{ mx: 1, borderColor: "#00AD73" }}
              orientation="vertical"
              flexItem
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: 200,
              }}
            >
              <Typography>مبلغ کل:</Typography>
              <Typography>
                {moneyFormat(data?.inputs?.total_cost)} ریال
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyDirectAcquireManagement;
