import clearDay from "@/assets/images/weather/clear-day.svg";
import clearNight from "@/assets/images/weather/clear-night.svg";
import cloudy from "@/assets/images/weather/cloudy.svg";
import partlyCloudyDay from "@/assets/images/weather/partly-cloudy-day.svg";
import partlyCloudyNight from "@/assets/images/weather/partly-cloudy-night.svg";
import rain from "@/assets/images/weather/rain.svg";
import sleet from "@/assets/images/weather/sleet.svg";
import snow from "@/assets/images/weather/snow.svg";
import wind from "@/assets/images/weather/wind.svg";

export const WEATHER_IMAGES = {
  "clear-day": clearDay,
  "clear-night": clearNight,
  cloudy: cloudy,
  "partly-cloudy-day": partlyCloudyDay,
  "partly-cloudy-night": partlyCloudyNight,
  rain: rain,
  sleet: sleet,
  snow: snow,
  wind: wind,
};
