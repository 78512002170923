/* eslint-disable react/prop-types */
import { moneyFormat } from "@/utils/moneyFormat";
import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import Counter from "./Counter";
import { usePostInputSelectionMutation } from "@/services/contracts";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TYPE_TRANSLATOR } from "@/constants/global";
import modalService from "../common/Modal/modalService";
import AcquireInputDetails from "../modals/AcquireInputDetails";

const Card = ({ item, max }) => {
  const [count, setCount] = useState(1);
  const { title, unit_price, id, input_type, logo } = item ?? {};
  const { contractId } = useSelector((state) => state.contractRegistraion);

  const [postInputSelection, { isLoading: postInputSelectionIsLoading }] =
    usePostInputSelectionMutation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 380,
        background: "#fff",
        width: 248,
        p: 2,
        borderRadius: 2,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          rotate: "45deg",
          background: TYPE_TRANSLATOR?.[input_type?.title]?.color,
          width: 150,
          left: -35,
          top: 30,
        }}
      >
        <Typography color="#fff" textAlign={"center"}>
          {TYPE_TRANSLATOR?.[input_type?.title]?.title}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            height: 180,
            backgroundImage: `url(${
              logo ?? TYPE_TRANSLATOR?.[input_type?.title]?.defaultImg
            })`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      </Box>
      <Box>
        <Typography textAlign="center" fontWeight="bold" mb={1}>
          {title}
        </Typography>
        {unit_price > 0 ? (
          <Typography fontSize={14} textAlign="center">
            {input_type?.title !== "seed" ? "هر کیلوگرم/لیتر" : "هر کیلوگرم"}{" "}
            <Typography fontSize={14} component="span" fontWeight="bold">
              {moneyFormat(unit_price)}
            </Typography>{" "}
            ریال
          </Typography>
        ) : (
          <Typography textAlign="center" fontSize={14}>
            قیمت بعدا محاسبه می شود.
          </Typography>
        )}
      </Box>
      <Box sx={{ my: 2 }}>
        <Counter counter={count} setCounter={setCount} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          disabled={
            !count || count <= 0 || count > max || postInputSelectionIsLoading
          }
          onClick={() => {
            postInputSelection({
              contractId,
              data: {
                selected_input: id,
                needed_bags: count,
              },
            })
              .unwrap()
              .then(() => toast.success("به سبد اضافه شد!"));
          }}
        >
          افزودن
        </Button>

        <Button
          disabled={!item?.pdf_file}
          onClick={() =>
            modalService.open(AcquireInputDetails, {
              pdf: item?.pdf_file,
              // pdf: "https://service.curcp.com/media/static_data/book/grassland/%DA%AF%DB%8C%D8%A7%D9%87%D8%A7%D9%86%20%D8%AF%D8%A7%D8%B1%D9%88%DB%8C%DB%8C%20%20%D8%A7%D9%93%D8%AE%D8%B1%DB%8C%D9%86%20%D9%86%D8%B3%D8%AE%D9%87.pdf",
            })
          }
          sx={{ p: 1 }}
          size="small"
          endIcon={<ArrowBackIos fontSize="small" />}
          variant="text"
        >
          جزئیات بیشتر
        </Button>
      </Box>
    </Box>
  );
};

export default Card;
