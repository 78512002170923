import { RolePath } from "./global";

export const dashboardPath = "/";
export const addWalletPath = "/add-wallet";
export const contractRegistrationPath = "/contract-registration";
export const contractsPath = "/contracts";
export const reportsPath = "/reports";
export const meteorologyPath = "/meteorology";
export const farmManagementPath = "/farm-management";
export const PVInfoVerificationPath =
  "/contract-registration/pv-info-Verification";
export const addNewFarmPath = "/contract-registration/add-new-farm";
export const verificationsPath = "/contract-registration/verifications";
export const bankValidationPath =
  "/contract-registration/verifications/bank-validation";
export const acquireInputsPath =
  "/contract-registration/verifications/receive-inputs";
export const insurancePolicyIssuancePath =
  "/contract-registration/verifications/insurance-policy-issuance";
export const supportServicesCompanyPath =
  "/contract-registration/verifications/support-services-company";
export const knowledgeTransferPath =
  "/contract-registration/verifications/knowledge-transfer";
export const guaranteedPurchasePath =
  "/contract-registration/verifications/guaranteed-purchase";
export const signingAgreementPath = "/contract-registration/signing-agreement";

export const companyDirectContractsManagementPath = `${RolePath.company_direct}/contracts-management`;
export const companyDirectAcquireManagementPath = `${RolePath.company_direct}/acquire-management`;
export const companyDirectCoveredFieldPath = `${RolePath.company_direct}/covered-field`;

export const adminContractsManagementPath = `${RolePath.admin}/contracts-management`;
export const adminAcquireManagementPath = `${RolePath.admin}/acquire-management`;
export const adminCoveredFieldPath = `${RolePath.admin}/covered-field`;

export const satelliteMonitoringPath = "/transferKnowledge/satelliteMonitoring";
export const weatherPath = "/transferKnowledge/weathrtPath";
