/* eslint-disable react/prop-types */
import { useCallback } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  useGetCardinalTemperatureQuery,
  useGetTimeSeriesQuery,
  useGetECMWFQuery,
} from "../../../services/meteorology";

import HandleServerData from "@/components/common/HandleServerData";
import useChartConfig from "@/hooks/useChartConfig";
import orderedDates from "@/utils/orderedDates";

import staticCardinalData from "@/assets/jsons/cardinalData.json";
import moment from "moment-jalaali";
import _ from "lodash";
import { Box, Button } from "@mui/material";
import { useRef } from "react";
import download from "../../../utils/download";
import { useOutletContext } from "react-router-dom";
import convert2Jalali from "@/utils/convert2Jalali";

const ChartTitle = ({ children }) => {
  return (
    <Box
      sx={{
        background: "#16A97414",
        borderRadius: 10,
        display: "inline-block",
        px: 4,
        py: 1,
        color: "GrayText",
      }}
    >
      {children}
    </Box>
  );
};

const Analyze = () => {
  const context = useOutletContext();
  // console.log(context);
  const {
    data: weatherData,
    isLoading,
    isSuccess,
    isError,
  } = useGetECMWFQuery(context?.farm_info?.id, {
    skip: !context?.farm_info?.id,
  });
  const { data: NDVIData } = useGetTimeSeriesQuery(
    {
      farmId: context?.farm_info?.id,
      index: "NDVI",
    },
    { skip: !context?.farm_info?.id }
  );
  const selectedProduct = useCallback(() => {
    const product = context?.farm_info?.product;
    // console.log(product);
    if (product === "گندم") {
      return { product: "wheat", shouldRequest: true };
    } else if (product === "ذرت") {
      return { product: "corn", shouldRequest: true };
    } else if (product === "برنج") {
      return { product: "rice", shouldRequest: true };
    } else if (
      staticCardinalData?.find(
        (ele) => ele.en_name === product || ele.fa_name === product
      )
    ) {
      return {
        product: staticCardinalData?.find(
          (ele) => ele.en_name === product || ele.fa_name === product
        )?.en_name,
        shouldRequest: false,
      };
    }
    return null;
  }, [context?.farm_info?.product]);

  const { data: cardinalData, isSuccess: isSuccessCardinal } =
    useGetCardinalTemperatureQuery(
      {
        farmId: context?.farm_info?.id,
        plantingDate: context?.start_date,
        productType: selectedProduct()?.product,
      },
      { skip: !selectedProduct()?.shouldRequest }
    );

  const tempChartLabels = useCallback(() => {
    return NDVIData && weatherData
      ? [
          ...new Set(
            orderedDates([
              ...(weatherData?.map((item) => item?.date) ?? []),
              ...(NDVIData?.time_series?.map((item) => item?.label) ?? []),
            ])
          ),
        ]
      : [];
  }, [NDVIData, weatherData]);

  const cardinalChartLabels = useCallback(() => {
    const temp = [];
    NDVIData?.time_series?.forEach((item) => {
      if (moment(item.label).isSameOrAfter(context?.start_date)) {
        temp.push(item.label);
      }
    });
    weatherData?.forEach((item) => {
      if (moment(item.date).isSameOrAfter(context?.start_date)) {
        temp.push(item.date);
      }
    });
    if (NDVIData && weatherData) {
      const uniqTemp = _.uniq(temp);
      return orderedDates(uniqTemp);
    } else {
      return [];
    }
  }, [NDVIData, context?.start_date, weatherData]);

  const renderCardinalData = useCallback(() => {
    if (selectedProduct()?.shouldRequest) {
      if (isSuccessCardinal) {
        return cardinalData?.data;
      }
    } else if (selectedProduct()?.product) {
      return [
        {
          ...staticCardinalData?.find(
            (ele) => ele.en_name === selectedProduct().product
          ),
          date: context?.start_date,
        },
      ];
    }
    return [];
  }, [
    selectedProduct,
    isSuccessCardinal,
    cardinalData?.data,
    context?.start_date,
  ]);

  // console.log(renderCardinalData());

  const filteredWeatherData = useCallback(() => {
    const index = weatherData?.findIndex((ele) =>
      moment(ele.date).isSameOrAfter(context?.start_date)
    );
    const temp = _.take(weatherData, index);
    return _.difference(weatherData, temp);
  }, [weatherData, context]);

  const filteredNDVIData = useCallback(() => {
    const index = NDVIData?.time_series?.findIndex((ele) =>
      moment(ele.label).isSameOrAfter(context?.start_date)
    );
    const temp = _.take(NDVIData?.time_series, index);
    // console.log(_.difference(NDVIData?.time_series, temp));
    return _.difference(NDVIData?.time_series, temp);
  }, [NDVIData, context]);

  const { config: tempChartConfig } = useChartConfig({
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "دما",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "NDVI",
        },

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
    },
    labels: tempChartLabels()?.map((item) => convert2Jalali(item)),
    dataSets: [
      {
        label: "NDVI",
        data: tempChartLabels()?.map((item) => {
          const y = NDVIData?.time_series?.find((ele) => ele.label === item)?.y;
          if (y) {
            return y;
          }
          return null;
        }),
        fill: false,
        borderColor: "rgb(118,198,144)",
        segment: {
          borderColor: "rgb(118,198,144)",
        },
        spanGaps: true,
        tension: 0.1,
        yAxisID: "y1",
        pointRadius: 0,
        pointHoverRadius: 15,
      },
      {
        label: "میانگین دما",
        data: weatherData?.map((item) => item?.T),
        fill: false,
        borderColor: "rgb(199, 0, 100)",
        tension: 0.1,
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 10,
      },
      // {
      //   label: t("weather.maxTempreture"),
      //   data: weatherData?.map((item) => item?.Tmax),
      //   fill: false,
      //   borderColor: "rgb(165,163,168)",
      //   tension: 0.1,
      //   yAxisID: "y",
      // },
      // {
      //   label: t("weather.minTempreture"),
      //   data: weatherData?.map((item) => item?.Tmin),
      //   fill: false,
      //   borderColor: "rgb(93,85,155)",
      //   tension: 0.1,
      //   yAxisID: "y",
      // },
    ],
  });
  const { config: cardinalChartConfig } = useChartConfig({
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "دما",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "NDVI",
        },

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
    },
    labels: cardinalChartLabels()?.map((item) => convert2Jalali(item)),
    dataSets: [
      {
        label: "NDVI",
        data: filteredNDVIData()?.map((item) => ({
          x: convert2Jalali(item.label),
          y: item.y,
        })),
        fill: false,
        borderColor: "rgb(118,198,144)",
        segment: {
          borderColor: "rgb(118,198,144)",
        },
        spanGaps: true,
        tension: 0.1,
        yAxisID: "y1",
        pointRadius: 0,
        pointHoverRadius: 15,
      },
      {
        label: "ماکسیمم کاردینال",
        data: cardinalChartLabels()?.map((item, index, array) => {
          let y;
          if (index === array?.length - 1) {
            y = renderCardinalData()?.at(-1)?.max_cardinal_temp;
          } else {
            y = renderCardinalData()?.find(
              (ele) => ele.date === item
            )?.max_cardinal_temp;
          }

          if (y) {
            return y;
          }
          return null;
        }),
        fill: false,
        borderColor: "rgb(246, 126, 109)",
        tension: 0.1,
        yAxisID: "y",
        segment: {
          borderColor: "rgb(246, 126, 109)",
        },
        spanGaps: true,
        pointRadius: 0,
        pointHoverRadius: 15,
      },
      {
        label: "مینیمم کاردینال",
        data: cardinalChartLabels()?.map((item, index, array) => {
          let y;
          if (index === array?.length - 1) {
            y = renderCardinalData()?.at(-1)?.min_cardinal_temp;
          } else {
            y = renderCardinalData()?.find(
              (ele) => ele.date === item
            )?.min_cardinal_temp;
          }

          if (y) {
            return y;
          }
          return null;
        }),
        fill: false,
        borderColor: "rgb(58, 168, 240)",
        tension: 0.1,
        yAxisID: "y",
        segment: {
          borderColor: "rgb(58, 168, 240)",
        },
        spanGaps: true,
        pointRadius: 0,
        pointHoverRadius: 15,
      },
      // {
      //   label: t("weather.averageTempreture"),
      //   data: weatherData?.map((item) => item?.T),
      //   fill: false,
      //   borderColor: "rgb(199, 0, 100)",
      //   tension: 0.1,
      //   yAxisID: "y",
      // },

      {
        label: "بیشینه دما",
        data: filteredWeatherData()?.map((item) => item?.Tmax),
        fill: false,
        borderColor: "rgb(165,163,168)",
        tension: 0.1,
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 15,
      },
      {
        label: "کمینه دما",
        data: filteredWeatherData()?.map((item) => item?.Tmin),
        fill: false,
        borderColor: "rgb(93,85,155)",
        tension: 0.1,
        yAxisID: "y",
        pointRadius: 0,
        pointHoverRadius: 15,
      },
    ],
  });

  const rainChartConfig = {
    type: "bar",
    data: {
      labels: weatherData?.map((item) => convert2Jalali(item.date)),
      datasets: [
        {
          label: "بارش روزانه",
          data: weatherData?.map((item) => item?.TP),
          backgroundColor: ["rgba(54, 162, 235, 0.2)"],
          borderColor: ["rgb(54, 162, 235)"],
          borderWidth: 1,
        },
      ],
    },
    options: { responsive: true, maintainAspectRatio: false },
  };

  const { config: windChartConfig } = useChartConfig({
    labels: weatherData?.map((item) => convert2Jalali(item.date)),
    dataSets: [
      {
        label: "پیش بینی",
        data: weatherData?.map((item) => item?.TP_Accumulated),
        fill: false,
        borderColor: "rgb(255, 99, 132)",
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 15,
      },
    ],
  });

  const tempChrRef = useRef();
  const cardinalChrRef = useRef();
  const dailyRainChrRef = useRef();
  const cumulativeRainChrRef = useRef();

  return (
    <div
      style={{
        height: "94.5vh",
        overflowY: "auto",
      }}
    >
      <HandleServerData
        isError={isError}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Box sx={{ display: "flex", mt: 4, px: 4 }}>
          <ChartTitle>دما (&#8451;)</ChartTitle>
          <Button
            variant="contained"
            sx={{ marginLeft: 2 }}
            onClick={() =>
              download(tempChrRef.current.toBase64Image(), "دما", true)
            }
          >
            دانلود نمودار
          </Button>
        </Box>

        <Box
          sx={{
            height: "500px",
            border: "2px solid #000",
            borderRadius: 10,
            m: 8,
            p: 2,
          }}
        >
          <Line ref={tempChrRef} {...tempChartConfig} />
        </Box>
        <Box sx={{ display: "flex", mt: 4, px: 4 }}>
          <ChartTitle>دمای کاردینال (&#8451;)</ChartTitle>
          <Button
            variant="contained"
            onClick={() =>
              download(
                cardinalChrRef.current.toBase64Image(),
                "دمای کاردینال",
                true
              )
            }
            sx={{ marginLeft: 2 }}
          >
            دانلود نمودار
          </Button>
        </Box>

        <Box
          sx={{
            height: "500px",
            border: "2px solid #000",
            borderRadius: 10,
            m: 8,
            p: 2,
          }}
        >
          <Line ref={cardinalChrRef} {...cardinalChartConfig} />
        </Box>
        <Box sx={{ display: "flex", mt: 4, px: 4 }}>
          <ChartTitle>بارش روزانه (mm)</ChartTitle>
          <Button
            variant="contained"
            onClick={() =>
              download(
                dailyRainChrRef.current.toBase64Image(),
                "بارش روزانه",
                true
              )
            }
            sx={{ marginLeft: 2 }}
          >
            دانلود نمودار
          </Button>
        </Box>
        <Box
          sx={{
            height: "500px",
            border: "1px solid #8f8f8f",
            borderRadius: 10,
            m: 8,
            p: 2,
          }}
        >
          <Bar ref={dailyRainChrRef} {...rainChartConfig} />
        </Box>
        <Box sx={{ display: "flex", mt: 4, px: 4 }}>
          <ChartTitle>بارش تجمعی (mm)</ChartTitle>
          <Button
            variant="contained"
            onClick={() =>
              download(
                cumulativeRainChrRef.current.toBase64Image(),
                "بارش تجمعی",
                true
              )
            }
            sx={{ marginLeft: 2 }}
          >
            دانلود نمودار
          </Button>
        </Box>
        <Box
          sx={{
            height: "500px",
            border: "2px solid #000",
            borderRadius: 10,
            m: 8,
            p: 2,
          }}
        >
          <Line ref={cumulativeRainChrRef} {...windChartConfig} />
        </Box>
      </HandleServerData>
    </div>
  );
};

export default Analyze;
