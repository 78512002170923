import moment from "moment-jalaali";

const convert2Jalali = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format("jYYYY/jMM/jDD");
  }
  return null;
};

export default convert2Jalali;
