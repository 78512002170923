/* eslint-disable react/prop-types */
import airFlow from "@/assets/images/air-flow.png";
import { Box, Typography } from "@mui/material";

const WindBlowChart = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        overflowX: "auto",
      }}
    >
      <Box dir='ltr' sx={{ display: "flex", mx: "auto", width: "100%" }}>
        {data?.map((item, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            key={index}
          >
            <img
              width={35}
              alt=''
              src={airFlow}
              style={{ rotate: `${item?.winddir}deg`, marginBottom: 5 }}
            />
            <Typography
              variant='caption'
              component='div'
              sx={{
                background: "rgba(54, 82, 121, 0.38)",
                borderBottomRightRadius: index === 0 ? "10px" : 0,
                borderBottomLeftRadius: index === data.length - 1 ? "10px" : 0,
                color: "white",
                width: "100%",
                textAlign: "center",
              }}
            >
              {Intl.DateTimeFormat("fa-IR-u-nu-latn", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(item?.datetimeEpoch * 1000))}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WindBlowChart;
