/* eslint-disable react/prop-types */
import ModalComponent from "../common/Modal/ModalComponent";
import { Box, Button, Typography } from "@mui/material";

const DeleteContracts = (props) => {
  return (
    <ModalComponent open={props?.open} onClose={props?.onClose}>
      <Box
        sx={{
          p: 4,
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography fontWeight='bold'>
            آیا از حذف این قرارداد اطمینان دارید؟
          </Typography>
        </Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <Button
            sx={{ mr: 1 }}
            onClick={() => props?.handleSubmit(props?.onClose)}
            variant='contained'
          >
            بله
          </Button>
          <Button sx={{ ml: 1 }} onClick={props?.onClose} variant='outlined'>
            خیر
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default DeleteContracts;
