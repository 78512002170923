import { Box, Paper, Typography } from "@mui/material";

/* eslint-disable react/prop-types */
const GuideCard = ({ src, title, children }) => {
  return (
    <Paper sx={{ padding: 4, maxWidth: 460, height: "fit-content" }}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box sx={{ mb: 2, width: { xs: 200, sm: 400 } }}>
          <img width='100%' src={src} alt={title} />
        </Box>
      </Box>
      <Typography fontWeight='bold' fontSize='18px' sx={{ mb: 2 }}>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};

export default GuideCard;
