/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import ImageText from "./ImageText";
import TitleSection from "./TitleSection";

const ReportSection = ({ title, data, textEditable, imageEditable }) => {
  return (
    <Box sx={{ px: 8, py: 2 }}>
      {data?.length > 0 && (
        <div>
          <TitleSection title={title} />
        </div>
      )}
      {data?.map((item, index) => {
        return (
          <ImageText
            key={index}
            item={item}
            isRtl
            textEditable={textEditable}
            imageEditable={item?.type === "INDEX" && imageEditable}
          />
        );
      })}
    </Box>
  );
};

export default ReportSection;
