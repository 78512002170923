import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useUserGeoLocation = (disabled = false) => {
  const [location, setLocation] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      toast.error("GPS پشتیبانی نمی شود!", {
        toastId: "user-location-support-error",
      });
    }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      if (latitude && longitude) {
        setLocation({ latitude, longitude });
      }
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }

    function error(err) {
      console.log("Unable to retrieve your location", err);
      toast.error("نمیتوانیم به موقعیت شما دسترسی داشته باشیم!", {
        toastId: "user-location-not-access-error",
      });
    }
  };

  useEffect(() => {
    if (!disabled) {
      getLocation();
    }
  }, [disabled]);

  return { location, getLocation };
};

export default useUserGeoLocation;
