import { mainSplitApi } from ".";

export const farmsApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFarms: builder.query({
      query: (params) => ({
        url: "/v1/users/farms/",
        params,
        method: "GET",
      }),
      providesTags: ["Farms"],
    }),
    getFarmImageProcess: builder.query({
      query: (farmId) => ({
        url: `/api/satplat/api/v1/process_satellite_images/${farmId}/`,
        method: "GET",
      }),
    }),
    getPolygons: builder.query({
      query: () => ({
        url: `/v1/users/farms/list/polygon/`,
        method: "GET",
      }),
      providesTags: ["Farms"],
    }),
    postFarmIndexImage: builder.mutation({
      query: ({ imageId, index, farmId }) => ({
        url: `/api/satplat/api/v1/index/${farmId}/`,
        body: { image_id: imageId, index },
        method: "POST",
      }),
    }),
    getOneFarm: builder.query({
      query: (id) => ({
        url: `/v1/users/farms/${id}/`,
        method: "GET",
      }),
      providesTags: ["Farms"],
    }),
    deleteFarm: builder.mutation({
      query: (farmId) => ({
        url: `/v1/users/farm/delete/${farmId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Farms"],
    }),
    addFarm: builder.mutation({
      query: (data) => ({
        url: "/v1/users/farm/add/",
        body: {
          name: data.name,
          polygon: data.polygon,
          product: data.product,
          cultivation_date: data.cultivation_date,
          cultivation_type: data.cultivation_type,
          irrigation_type: data.irrigation_type,
          based_fertilizer: data.based_fertilizer,
          seed_number: data.seed_number,
          soil_texture: data.soil_texture,
          based_fertilizer_value: data.based_fertilizer_value,
          seed_value: data.seed_value,
        },
        method: "POST",
      }),
      invalidatesTags: ["Farms"],
    }),
    editFarm: builder.mutation({
      query: ({ farmId, data }) => ({
        url: `/v1/users/farm/update/${farmId}/`,
        body: data,
        method: "PUT",
      }),
      invalidatesTags: ["Farms"],
    }),
    getShapeFileLink: builder.mutation({
      query: (farmId) => ({
        url: "/v1/users/download-shapefile/",
        method: "POST",
        body: {
          farm_id: farmId,
        },
      }),
    }),
    getRGBImage: builder.query({
      query: (imageId) => ({
        url: "/v3/images/rgb/",
        method: "GET",
        params: {
          image_id: imageId,
        },
      }),
    }),
    validatePolygon: builder.mutation({
      query: (data) => ({
        url: "/v1/users/farm/validate-polygon/",
        body: data,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetFarmsQuery,
  useLazyGetFarmsQuery,
  useGetFarmImageProcessQuery,
  usePostFarmIndexImageMutation,
  useGetOneFarmQuery,
  useAddFarmMutation,
  useEditFarmMutation,
  useGetShapeFileLinkMutation,
  useGetRGBImageQuery,
  useValidatePolygonMutation,
  useDeleteFarmMutation,
  useGetPolygonsQuery,
} = farmsApi;
