import {
  fertializerDefaultImg,
  poisonDefaultImg,
  seedDefaultImg,
} from "@/assets";

export const TYPE_TRANSLATOR = {
  seed: {
    id: 1,
    title: "بذر",
    value: "seed",
    color: "#A1D066",
    defaultImg: seedDefaultImg,
  },
  fertilizer: {
    id: 2,
    title: "کود",
    value: "fertilizer",
    color: "#D09666",
    defaultImg: fertializerDefaultImg,
  },
  poison: {
    id: 3,
    title: "سم",
    value: "poison",
    color: "#68C1C3",
    defaultImg: poisonDefaultImg,
  },
};

export const IndexesList = ["NDVI", "NDWI", "NDRE", "CHL", "LAI", "MSAVI"];

export const Roles = {
  ADMIN: "admin",
  COMPANY_DIRECT: "company_direct",
  FARMER: "farmer",
  STATE_ADMIN: "state_admin",
  SUPERVISOR_ADMIN: "supervisor_admin",
};

export const RolePath = {
  admin: "/admin",
  company_direct: "/company-direct",
  farmer: "/farmer",
  state_admin: "/admin",
  supervisor_admin: "/admin",
};

export const roleMainPage = {
  admin: ``,
  company_direct: ``,
  farmer: ``,
  state_admin: ``,
  supervisor_admin: ``,
};
