/* eslint-disable react/prop-types */
import {
  FarmManagementStates,
  setFarmManageMentState,
} from "@/redux/slices/farmManagementSlice";
import { ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const DateSwiper = ({ dates }) => {
  const dispatch = useDispatch();
  const selectedImageId = useSelector(
    (state) => state.farmManagement[FarmManagementStates.selectedImageId]
  );
  const matches1420px = useMediaQuery("(min-width:1420px)");
  const matches1240px = useMediaQuery("(min-width:1240px)");

  const renderSlideNumber = () => {
    if (matches1420px) {
      return 4;
    }
    if (matches1240px) {
      return 3;
    }
    return 2;
  };

  return (
    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
      <div className="prev">
        <Box>
          <IconButton>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </div>

      <Swiper
        style={{ width: "80%" }}
        slidesPerView={renderSlideNumber()}
        spaceBetween={10}
        modules={[Navigation]}
        navigation={{ clickable: true, nextEl: ".next", prevEl: ".prev" }}
      >
        {dates?.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              onClick={() =>
                dispatch(
                  setFarmManageMentState({
                    [FarmManagementStates.selectedImageId]: item?.id,
                  })
                )
              }
              sx={{
                transition: "all 0.5s",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                p: 1,
                borderRadius: 2,
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                background: selectedImageId === item?.id ? "#00000039" : "",
              }}
            >
              <Typography variant="button">
                {Intl.DateTimeFormat("fa-IR-u-nu-latn", {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                }).format(new Date(item?.date))}
              </Typography>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="next">
        <Box>
          <IconButton>
            <ArrowForwardIos sx={{ rotate: "180deg" }} />
          </IconButton>
        </Box>
      </div>
    </Box>
  );
};

export default DateSwiper;
