import { createSlice } from "@reduxjs/toolkit";

const localStorageData = JSON.parse(localStorage.getItem("auth"));

const initialState = {
  user: localStorageData,
  auth: Boolean(localStorageData),
  hasWallet: true,
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setLoginState: (state, action) => {
      state.user = action.payload;
      state.auth = true;
      // CHANGE ME
      state.hasWallet = action.payload?.wallet;
      localStorage.setItem("auth", JSON.stringify(action.payload ?? null));
    },
    setLogoutState: (state) => {
      state.user = null;
      state.auth = false;
      state.hasWallet = false;
      localStorage.removeItem("auth");
    },
    setRefreshToken: (state, action) => {
      const savedAuth = localStorage.getItem("auth");
      const newAuth = {
        ...JSON.parse(savedAuth),
        access_token: action.payload,
      };
      localStorage.setItem("auth", JSON.stringify(newAuth));
      state.user.access_token = action.payload;
    },
  },
});

const { reducer } = authSlice;

export const { setLoginState, setLogoutState, setRefreshToken } =
  authSlice.actions;
export default { reducer };
