import * as Turf from "@turf/turf";
import { Layer, Source } from "react-map-gl";
import PropTypes from "prop-types";

const Polygon = ({
  sourceId,
  layerId,
  coordinates,
  fill = {
    background: "#0080ff",
    opacity: 0.5,
  },
  border = {
    color: "#000",
    width: 3,
  },
}) => {
  const lineStringVar = Turf.lineString(coordinates);
  const polygonBbox = Turf.bbox(lineStringVar);

  return (
    <Source
      id={sourceId}
      type="geojson"
      data={{
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [coordinates],
          bbox: polygonBbox,
        },
      }}
    >
      <Layer
        {...{
          id: layerId,
          type: "fill",
          source: sourceId, // reference the data source
          layout: {},
          paint: {
            "fill-color": fill.background, // blue color fill
            "fill-opacity": fill.opacity,
          },
        }}
      />
      <Layer
        {...{
          id: `${layerId}-1`,
          type: "line",
          source: sourceId,
          layout: {},
          paint: {
            "line-color": border.color,
            "line-width": border.width,
          },
        }}
      />
    </Source>
  );
};

Polygon.propTypes = {
  sourceId: PropTypes.string,
  layerId: PropTypes.string,
  coordinates: PropTypes.array,
  fill: PropTypes.object,
  border: PropTypes.object,
};

export default Polygon;
