import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import Table from "@/components/admin-contracts-modules/Table";
import { debounce } from "lodash";
import {
  useGetAdminGeneralContractStateListQuery,
  useGetAdminTotalContractStatQuery,
  useGetStatesQuery,
  useLazyGetAdminContractsQuery,
  useLazyGetCooperativeBriefQuery,
} from "@/services/contracts";
import { moneyFormat } from "@/utils/moneyFormat";
import { useSelector } from "react-redux";
import { Roles } from "@/constants/global";
import { Download } from "@mui/icons-material";

const AdminContracts = () => {
  const user = useSelector((state) => state.authentication.user);
  const isMainAdmin = user?.user_info?.type?.name === Roles.ADMIN;
  const isStateAdmin = user?.user_info?.type?.name === Roles.STATE_ADMIN;
  const [requestState, setRequestState] = useState({
    page: 1,
    search: "",
    ordering: "",
    state: "",
    location: isStateAdmin ? user?.state : "",
    page_size: 5,
    cooperative_company: "",
  });
  const [trigger, { data, isLoading, isSuccess, isFetching }] =
    useLazyGetAdminContractsQuery();

  const downloadCSV = async () => {
    try {
      const response = await fetch(
        import.meta.env.VITE_PRODUCTION_BASE_URL +
          `/api/contract-mgmt/api/v1/admins/contract/csv/?` +
          new URLSearchParams(requestState).toString(),
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Assuming the response is already in the correct CSV format and UTF-8 encoded
      const csvData = await response.text();

      // Correctly handle UTF-8 BOM to avoid issues in Excel or other apps
      const bom = "\uFEFF";
      const blob = new Blob([bom + csvData], {
        type: "text/csv;charset=utf-8;",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.csv"; // Specify the filename for the downloaded file
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching the CSV file:", error);
    }
  };

  const { data: states } = useGetAdminGeneralContractStateListQuery(
    { with_waiting: true },
    { skip: !isMainAdmin }
  );
  const { data: provinces } = useGetStatesQuery();
  const [getCompanyProvider, { data: companies }] =
    useLazyGetCooperativeBriefQuery();

  useEffect(() => {
    trigger(requestState);
  }, []);

  const handleChangePage = (event, newPage) => {
    setRequestState((prev) => {
      const newState = { ...prev, page: newPage + 1 };
      trigger(newState);
      return newState;
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRequestState((prev) => {
      const newState = {
        ...prev,
        page: 1,
        page_size: parseInt(event.target.value, 10),
      };
      trigger(newState);
      return newState;
    });
  };

  useEffect(() => {
    if (isStateAdmin) {
      getCompanyProvider({ state: user?.state });
    }
  }, [getCompanyProvider, isStateAdmin, trigger, user?.state]);

  const debounceRequest = debounce((requestParam) => {
    trigger(requestParam);
  }, 1000);

  const handleDebounceRequest = useCallback((value) => {
    debounceRequest(value);
  }, []);

  const { data: stats } = useGetAdminTotalContractStatQuery(requestState);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "30px",
          pb: 4,
          alignItems: { xs: "", xl: "center" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: "30px",
            flex: "0 1 auto",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            پنل ادمین کشت قراردادی
          </Typography>
        </Box>
        <Box sx={{ mt: 8, mb: 2 }}>
          <Button
            onClick={() => {
              downloadCSV();
            }}
            endIcon={<Download />}
            variant="outlined"
          >
            دانلود اکسل
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Paper sx={{ width: "90%", maxWidth: 1200, p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                value={requestState.search}
                onChange={(e) =>
                  setRequestState((prev) => {
                    const copiedData = { ...prev };
                    copiedData.search = e.target.value;
                    handleDebounceRequest(copiedData);
                    copiedData.page = 1;
                    return copiedData;
                  })
                }
                label="جستجو"
                size="small"
                placeholder="نام خانوادگی / شماره قرارداد"
              />
              {!isStateAdmin && (
                <TextField
                  onChange={(e) =>
                    setRequestState((prev) => {
                      const newState = {
                        ...prev,
                        page: 1,
                        location: e.target.value,
                      };
                      newState.cooperative_company = "";
                      if (e.target.value) {
                        getCompanyProvider({ state: e.target.value });
                      }

                      trigger(newState);
                      return newState;
                    })
                  }
                  SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
                  value={requestState.location}
                  sx={{ width: 200 }}
                  select
                  size="small"
                  label="فیلتر براساس استان"
                >
                  <MenuItem value={""}>بدون فیلتر</MenuItem>
                  {provinces?.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                disabled={companies && !requestState.location}
                onChange={(e) =>
                  setRequestState((prev) => {
                    const newState = {
                      ...prev,
                      page: 1,
                      cooperative_company: e.target.value,
                    };
                    trigger(newState);
                    return newState;
                  })
                }
                SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
                value={requestState.cooperative_company}
                sx={{ width: 200 }}
                select
                size="small"
                label="فیلتر براساس مباشر"
              >
                <MenuItem value={""}>بدون فیلتر</MenuItem>
                {companies?.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                onChange={(e) =>
                  setRequestState((prev) => {
                    const newState = {
                      ...prev,
                      page: 1,
                      state: e.target.value,
                    };
                    trigger(newState);
                    return newState;
                  })
                }
                value={requestState.state}
                sx={{ width: 200 }}
                select
                size="small"
                label="فیلتر براساس وضعیت"
              >
                <MenuItem value="">بدون فیلتر</MenuItem>
                {states?.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ pt: 4 }}>
              <Table
                rowsPerPage={requestState.page_size}
                page={requestState.page - 1}
                data={data?.results}
                isLoading={isLoading}
                isSuccess={isSuccess}
                isFetching={isFetching}
                states={states}
              />
              <TablePagination
                sx={{ mt: 2 }}
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={data?.total_items ?? 0}
                rowsPerPage={requestState.page_size}
                page={requestState.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="ردیف در هر صفحه: "
                labelDisplayedRows={({ count, page }) =>
                  count > 0 && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ fontSize: "14px" }}>
                        تعداد کل ردیف ها: {count}
                      </Typography>
                      <Typography sx={{ ml: 4, fontSize: "14px" }}>
                        صفحه: {page + 1} از{" "}
                        {Math.ceil(count / requestState.page_size)}
                      </Typography>
                    </Box>
                  )
                }
              />
            </Box>
          </Paper>
        </Box>
        {stats && (
          <Box
            sx={{
              pt: 6,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "fit-content", sm: 800 },
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                border: "1px green solid",
                borderRadius: 10,
                py: 1,
                px: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                }}
              >
                <Typography mr={1}>تعداد کل قرار داد ها:</Typography>
                <Typography>
                  {stats?.total_count ? moneyFormat(stats?.total_count) : ""}
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: "green" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  my: { xs: 3, sm: 0 },
                }}
              >
                <Typography mr={1}>مساحت کل:</Typography>
                <Typography>
                  {stats?.total_area
                    ? `${moneyFormat(stats?.total_area)} هکتار`
                    : ""}
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: "green" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                }}
              >
                <Typography mr={1}>مبلغ کل:</Typography>
                <Typography textAlign="center">
                  {stats?.total_cost
                    ? `${moneyFormat(stats?.total_cost)} ریال`
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AdminContracts;
