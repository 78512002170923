import { Box, Typography } from "@mui/material";

// eslint-disable-next-line react/prop-types
const Balance = ({ title, value }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: { xs: 4, md: 0 } }}>
      <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
        {title}
      </Typography>
      <Box
        sx={{
          borderRadius: "8px",
          background: "#01adb716",
          width: "150px",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "14px",
        }}
      >
        <Typography sx={{ color: "#919EAB" }}>{value} ریال</Typography>
      </Box>
    </Box>
  );
};

export default Balance;
