/* eslint-disable react/prop-types */
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { productImage } from "@/constants/productImages";
import { useState } from "react";
import { usePostProductFinalPriceMutation } from "@/services/products";
import { useGetProductListQuery } from "@/services/contracts";
import { toast } from "react-toastify";

const AddNewProductPrice = (props) => {
  const [price, setPrice] = useState("");
  const [product, setProduct] = useState("");
  const [postProductFinalPrice] = usePostProductFinalPriceMutation();
  const { data } = useGetProductListQuery();

  return (
    <ModalComponent>
      <Box sx={{ p: 4, pt: 0, display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{ fontWeight: "bold", my: 4, fontSize: 18, textAlign: "center" }}
        >
          ثبت قیمت جدید
        </Typography>
        <TextField
          SelectProps={{
            SelectDisplayProps: {
              style: { display: "flex", alignItems: "center" },
            },
          }}
          value={product}
          onChange={(e) => setProduct(e.target.value)}
          sx={{ width: 240, mb: 2 }}
          select
          label="محصول"
          size="small"
        >
          {data?.map((item) => (
            <MenuItem
              sx={{ display: "flex", alignItems: "center" }}
              key={item?.id}
              value={item?.id}
            >
              <img
                style={{ marginLeft: 10 }}
                width={20}
                src={productImage?.[item?.title_en]}
                alt={item?.title}
              />
              <Typography>{item?.title}</Typography>
            </MenuItem>
          ))}
        </TextField>

        <TextField
          sx={{ width: 240 }}
          label="قیمت"
          size="small"
          value={price}
          type="number"
          InputProps={{
            endAdornment: <Typography>ریال</Typography>,
          }}
          onChange={(e) => setPrice(e.target.value)}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={props?.onClose}
            variant="outlined"
            sx={{ mt: 4, mx: 1 }}
          >
            بستن
          </Button>
          <Button
            onClick={() => {
              postProductFinalPrice({ productId: product, data: { price } })
                .unwrap()
                .then(() => {
                  toast.success("با موفقیت ثبت شد!");
                  props?.onClose();
                });
            }}
            variant="contained"
            sx={{ mt: 4, mx: 1 }}
          >
            ثبت
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default AddNewProductPrice;
