/* eslint-disable react/prop-types */
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Tooltip,
} from "@mui/material";
import { Check, DescriptionOutlined } from "@mui/icons-material";
import styled from "@emotion/styled";
import Error from "../common/Error";
import modalService from "../common/Modal/modalService";
import { moneyFormat } from "@/utils/moneyFormat";
import ApprovedProductPrice from "../modals/ApprovedProductPrice";
import convert2Jalali from "@/utils/convert2Jalali";

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  font-size: 16px;
`;

const TableLoading = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        sx={{
          position: "relative",
          height: 500,
        }}
      >
        <CircularProgress sx={{ position: "absolute", left: "50%" }} />
      </TableCell>
    </TableRow>
  );
};

const EmptyTable = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        sx={{
          position: "relative",
          height: 500,
        }}
      >
        <Box
          sx={{
            height: "200px",
            width: "200px",
            position: "absolute",
            left: "calc(50% - 100px)",
            top: "calc(50% - 100px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DescriptionOutlined sx={{ width: "100px", height: "100px" }} />
          <Typography fontWeight="bold" sx={{ mt: 2 }}>
            هیچ قراردادی یافت نشد!
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const Data = ({ rows }) => {
  return rows.map((row) => (
    <TableRow
      key={row?.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-of-type(odd)": {
          background: "#F4F6F8",
        },
      }}
    >
      <TableCell align="center">{row?.profile?.first_name ?? "-"}</TableCell>
      <TableCell align="center">{row?.profile?.last_name ?? "-"}</TableCell>
      <TableCell align="center">{row?.profile?.phone_number ?? "-"}</TableCell>
      <TableCell align="center">{row?.product ?? "-"}</TableCell>
      <TableCell align="center">
        {row?.created_date ? convert2Jalali(row?.created_date) : "-"}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          color: row?.price ? "green" : "",
          fontWeight: row?.price ? "bold" : "",
        }}
      >
        {row?.price ? `${moneyFormat(row?.price)} ریال` : "-"}
      </TableCell>

      <TableCell align="center">
        {!row?.approved ? (
          <Button
            variant="contained"
            onClick={() =>
              modalService.open(ApprovedProductPrice, {
                ...row,
              })
            }
          >
            در انتظار تایید
          </Button>
        ) : (
          <Tooltip title="تایید شده">
            <Check sx={{ color: "green" }} />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  ));
};

const Table = ({
  data = [],
  isLoading,
  isSuccess,
  isFetching,
  states = [],
}) => {
  const Body = () => {
    if (isLoading || isFetching) {
      return <TableLoading />;
    } else if (isSuccess) {
      if (data.length > 0) {
        return <Data rows={data} states={states} />;
      } else {
        return <EmptyTable />;
      }
    } else {
      return <Error />;
    }
  };

  return (
    <TableContainer component={Box}>
      <MuiTable sx={{ minWidth: 650 }} size="medium" aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: "#00581558" }}>
            <StyledTableCell align="center">نام</StyledTableCell>
            <StyledTableCell align="center">نام خانوادگی</StyledTableCell>
            <StyledTableCell align="center">شماره تماس</StyledTableCell>
            <StyledTableCell align="center">نوع محصول</StyledTableCell>
            <StyledTableCell align="center">تاریخ ثبت</StyledTableCell>
            <StyledTableCell align="center">قیمت پیشنهادی</StyledTableCell>
            <StyledTableCell align="center">وضعیت</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Body />
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
