import modalService from "@/components/common/Modal/modalService";
import Balance from "@/components/dashboard-modules/Balance";
import LastOpenContract from "@/components/modals/LastOpenContract";
import STEP_ROUTES from "@/constants/stepRoutes";
import {
  resetContractState,
  updateContractState,
} from "@/redux/slices/contractRegistraionSlice";
import {
  useDeleteContractMutation,
  useGetContractStateQuery,
  useGetStageListQuery,
  useLazyGetlastOpenContractQuery,
} from "@/services/contracts";
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const ContractRegistrationLayout = () => {
  const balance = useSelector((state) => state.contractRegistraion.balance);
  const { contractId } = useSelector((state) => state.contractRegistraion);
  const dispatch = useDispatch();
  const { data, isSuccess } = useGetContractStateQuery(contractId, {
    skip: !contractId,
  });
  const { data: stages } = useGetStageListQuery();
  const [getLastOpenContract] = useLazyGetlastOpenContractQuery();
  const [deleteContract, { isLoading: deleteIsLoading }] =
    useDeleteContractMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        updateContractState({
          balance: data?.total_cost,
          stage: data?.stage,
        })
      );
      navigate(STEP_ROUTES[data?.stage?.title]);
    } else {
      navigate("/farmer/contract-registration");
    }
  }, [data, dispatch, isSuccess, navigate]);

  useEffect(() => {
    getLastOpenContract()
      .unwrap()
      .then((data) => {
        if (data?.id) {
          modalService.open(LastOpenContract, {
            onContinue: () =>
              dispatch(updateContractState({ contractId: data?.id })),
            onDelete: (onClose) =>
              deleteContract(data?.id)
                .unwrap()
                .then(() => {
                  onClose();
                }),
            deleteIsLoading,
          });
        }
      })
      .catch(() => dispatch(resetContractState()));
  }, [deleteContract, deleteIsLoading, dispatch, getLastOpenContract]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px 40px",
          flex: "0 1 auto",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: { xs: 6, sm: 0 },
            fontWeight: { xs: "bold", sm: "normal" },
          }}
        >
          مراحل ثبت قرارداد
        </Typography>
        {data?.stage?.id >= 3 && (
          <Button
            variant="outlined"
            endIcon={<Add />}
            onClick={() => dispatch(updateContractState({ contractId: "" }))}
          >
            قرارداد جدید
          </Button>
        )}
        {balance > 0 && (
          <Balance
            title="کیف پول اعتباری:"
            value={`${Intl.NumberFormat("en").format(balance)} -`}
          />
        )}
      </Box>
      {contractId && (
        <Box sx={{ mb: 6, display: { xs: "none", sm: "initial" } }}>
          <Stepper activeStep={data?.stage?.id - 1} alternativeLabel>
            {stages?.map((stage) => (
              <Step key={stage.id}>
                <StepLabel sx={{ fontWeight: "bold" }}>{stage.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      <Box sx={{ display: "flex", flex: "1 1 auto", pb: 4 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default ContractRegistrationLayout;
