/* eslint-disable react/prop-types */
import { WEATHER_IMAGES } from "@/constants/weatherImages";
import { Box, Tooltip, Typography } from "@mui/material";

import shadow from "@/assets/images/weather/shadow.svg";

const DailyCard = ({ dailyInfo }) => {
  const items = [
    {
      title: "بیشترین دما",
      value: `${dailyInfo?.feelslikemax}\u2103`,
    },
    {
      title: "کمترین دما",
      value: `${dailyInfo.feelslikemin}\u2103`,
    },
    { title: "رطوبت", value: `${dailyInfo.humidity}%` },
    { title: "سرعت باد", value: `${dailyInfo.windspeed} km/h` },
    { title: "فشار هوا", value: `${dailyInfo.pressure} mb` },
    {
      title: "طلوع آفتاب",
      value: dailyInfo.sunrise,
    },
    {
      title: "غروب آفتاب",
      value: dailyInfo.sunset,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        my: 4,
        background: "rgba(118, 95, 147, 0.42)",
        borderRadius: "10px",
        position: "relative",
        maxHeight: "600px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundImage: `url(${shadow})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          width: "200px",
          height: "200px",
          top: "10%",
          left: "calc(50% - 100px)",
          zIndex: 2,
          opacity: 0.8,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          my: 2,
          px: 2,
          color: "#fff",
        }}
      >
        <Typography variant='subtitle2' sx={{ mb: 1 }}>
          {new Date(dailyInfo.datetimeEpoch * 1000).toLocaleDateString(
            "fa-IR",
            {
              weekday: "long",
            }
          )}
        </Typography>
        <Typography variant='subtitle2'>
          {Intl.DateTimeFormat("fa-IR-u-nu-latn", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }).format(new Date(dailyInfo.datetimeEpoch * 1000))}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          whiteSpace: "nowrap",
          py: "33px",
          px: "38px",
          color: "white",
          zIndex: 3,
        }}
      >
        <Tooltip title={dailyInfo.description}>
          <img
            style={{ width: 64 }}
            src={WEATHER_IMAGES[dailyInfo.icon]}
            alt={dailyInfo.icon}
          />
        </Tooltip>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", px: 2, mt: 3, mb: 5 }}
      >
        {items.map((item, index) => (
          <Box key={index} sx={{ display: "flex", my: 1, color: "GrayText" }}>
            <Typography variant='body2' sx={{ color: "#fff" }}>
              {item.title}:
            </Typography>
            <Typography
              dir='ltr'
              variant='body2'
              sx={{ marginLeft: 0.5, color: "#365279" }}
            >
              {item.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DailyCard;
