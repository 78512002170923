import React from "react";
import ReactDOM from "react-dom/client";
import Routes from "./Routes.jsx";
import "./index.css";
import { HashRouter } from "react-router-dom";
import MuiRtlConfig from "./MUI/MuiRtlConfig";
import MuiGlobalThemeProvider from "./MUI/MuiGlobalThemeProvider";
import { Provider } from "react-redux";
import store from "./redux/store.js";

// eslint-disable-next-line no-unused-vars
import Chart from "chart.js/auto";
// eslint-disable-next-line no-unused-vars
import { CategoryScale } from "chart.js";
import 'chartjs-adapter-moment';

import "@sentry/react";
import { pdfjs } from "react-pdf";

import "mapbox-gl/dist/mapbox-gl.css";

// import styles bundle
import "swiper/css/bundle";
import MuiLocalizationProvider from "./MUI/MuiLocalizationProvider/index.jsx";

// Sentry.init({
//   dsn: "https://b48155e1a82d1ce8d8c3a6f573bf11d6@sentry.hamravesh.com/5802",
//   integrations: [
//     new Sentry.GlobalHandlers({ onunhandledrejection: false }),
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [/^https:\/\/curcp\.com/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // // Performance Monitoring
//   // tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // // Session Replay
//   // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

Chart.register(CategoryScale);
Chart.defaults.font.family = "IranSans";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HashRouter>
      <MuiRtlConfig>
        <MuiGlobalThemeProvider>
          <MuiLocalizationProvider>
            <Provider store={store}>
              <Routes />
            </Provider>
          </MuiLocalizationProvider>
        </MuiGlobalThemeProvider>
      </MuiRtlConfig>
    </HashRouter>
  </React.StrictMode>
);
