const STEP_ROUTES = {
  user_info: "pv-info-Verification",
  farm_declaration: "add-new-farm",
  acquire_input: "verifications/receive-inputs",
  insurance_validation: "verifications/insurance-policy-issuance",
  bank_validation: "verifications/bank-validation",
  support_validation: "verifications/support-services-company",
  knowledge_agreement: "verifications/knowledge-transfer",
  purchase_agreement: "verifications/guaranteed-purchase",
  finalized_deal: "signing-agreement",
  dealt_contract: "/contract-registration",
};

export default STEP_ROUTES;
