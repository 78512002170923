import { dashboardPath } from "@/constants/pagePath";
import { setLogoutState } from "@/redux/slices/authSlice";
import { Person, Phone, Wallet } from "@mui/icons-material";
import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddWallet = () => {
  const [wallet, setWallet] = useState("");
  const { hasWallet, auth, user } = useSelector(
    (state) => state.authentication
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth && hasWallet) {
      navigate(dashboardPath, { replace: true });
    } else if (!auth) {
      navigate("/auth", { replace: true });
    }
  }, [auth, hasWallet, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box width={500} flexDirection="column" display="flex">
        <Typography
          sx={{ mb: 6 }}
          variant="h4"
          textAlign="center"
          fontWeight="bold"
        >
          ثبت کیف پول
        </Typography>
        <TextField
          InputProps={{ endAdornment: <Phone /> }}
          label="شماره تماس"
          disabled
          value={user?.phone_number}
        />
        <TextField
          InputProps={{ endAdornment: <Person /> }}
          sx={{ my: 3 }}
          label="کد ملی"
          disabled
          value={""}
        />
        <TextField
          label="کیف پول"
          value={wallet}
          onChange={(e) => setWallet(e.target.value)}
          InputProps={{ endAdornment: <Wallet /> }}
        />
        <Button
          sx={{ mt: 4 }}
          variant="contained"
          disabled={wallet.length <= 3}
        >
          ثبت کد
        </Button>
        <Box>
          <Link
            onClick={() => dispatch(setLogoutState())}
            sx={{ cursor: "pointer" }}
          >
            <Typography textAlign="center" sx={{ mt: 2 }}>
              ورود با اکانت دیگر
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default AddWallet;
