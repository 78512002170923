/* eslint-disable react/prop-types */
import { Box, Typography } from "@mui/material";

import diactiveRain from "@/assets/images/diactiveRain.svg";
import activeRain from "@/assets/images/activeRain.png";
import backRainChart from "@/assets/images/weather/back-rain-chart.svg";

const RainChart = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        overflowX: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mx: "auto",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${backRainChart})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        {data?.map((item, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            key={index}
          >
            <img
              width={25}
              alt=''
              src={item?.precip === 0 ? diactiveRain : activeRain}
              style={{ marginBottom: 8 }}
            />
            <Typography
              variant='caption'
              component='div'
              sx={{
                background: "rgba(54, 82, 121, 0.38)",
                borderBottomRightRadius: index === data.length - 1 ? "10px" : 0,
                borderBottomLeftRadius: index === 0 ? "10px" : 0,
                color: "white",
                width: "100%",
                textAlign: "center",
              }}
            >
              {item?.precip}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default RainChart;
