import { closeDrawer } from "@/redux/slices/globalSlice";
import { Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";

const GlobalDrawer = () => {
  const drawerState = useSelector((state) => state.global.drawer);
  const dispatch = useDispatch();

  return (
    <Drawer
      anchor='left'
      open={drawerState}
      onClose={() => dispatch(closeDrawer())}
      onClick={() => dispatch(closeDrawer())}
    >
      <Sidebar />
    </Drawer>
  );
};

export default GlobalDrawer;
