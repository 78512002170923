/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { useRef, useState } from "react";
import { usePostFinaliztionMutation } from "@/services/contracts";
import modalService from "../common/Modal/modalService";
import { useDispatch, useSelector } from "react-redux";
import SuccessSign from "./SuccessSign";
import Timer from "../common/Timer";
import CanvasDraw from "react-canvas-draw";
import { Close } from "@mui/icons-material";
import { resetContractState } from "@/redux/slices/contractRegistraionSlice";

const FinalizationSign = (props) => {
  const [code, setCode] = useState("");
  const [disableResend, setDisableResend] = useState(true);

  const [postFinaliztion, { isLoading }] = usePostFinaliztionMutation();

  const canvasRef = useRef();
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const dispatch = useDispatch();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <ModalComponent open={props.open}>
      <Box
        sx={{
          p: { xs: 4, md: 6 },
          maxWidth: { xs: "100%", md: "500px" },
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: "10px", left: "10px" }}
          onClick={props.onClose}
        >
          <Close />
        </IconButton>
        {props?.sign && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{ mb: 1, width: 140 }}
              onClick={() => canvasRef.current.clear()}
            >
              امضا مجدد
            </Button>
            <Box sx={{ border: "1px solid #000", borderRadius: "10px" }}>
              <CanvasDraw
                ref={canvasRef}
                lazyRadius={2}
                brushRadius={2}
                canvasHeight={300}
                canvasWidth={matches ? 400 : 300}
                style={{ borderRadius: 10 }}
              />
            </Box>
          </Box>
        )}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            placeholder="کد ارسالی را وارد کنید"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="number"
            size="small"
            sx={{ width: 200, my: 2 }}
            inputProps={{
              style: { textAlign: "center", fontWeight: "bolder" },
            }}
          />
          {disableResend && (
            <Timer
              h={0}
              m={2}
              s={0}
              onFinished={() => setDisableResend(false)}
              render={({ m, s }) => (
                <Typography variant="subtitle2">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      direction: "rtl",
                    }}
                  >
                    {`${m} : ${s}`}
                  </Typography>
                  مانده تا دریافت مجدد کد دیگر
                </Typography>
              )}
            />
          )}
          {!disableResend && (
            <Button
              onClick={() => {
                props
                  .sendOTPFinaliztion(contractId)
                  .unwrap()
                  .then(() => setDisableResend(true));
              }}
            >
              ارسال مجدد کد
            </Button>
          )}
        </Box> */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{ mt: 2 }}
            onClick={() => {
              const fomData = new FormData();
              // fomData.append("token", code);
              props?.sign &&
                fomData.append(
                  "signature_base64",
                  canvasRef.current
                    .getDataURL()
                    .replace("data:image/png;base64,", "")
                );
              fomData.append("contract_id", contractId);
              postFinaliztion({
                contractId,
                data: fomData,
              })
                .unwrap()
                .then((data) => {
                  dispatch(resetContractState());
                  modalService.open(SuccessSign, {
                    contractNumber: data?.contract_number,
                    contractId,
                  });
                });
            }}
            variant="contained"
          >
            {!isLoading ? "ثبت امضا" : "درحال ثبت ..."}
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default FinalizationSign;
