import { mainSplitApi } from ".";

export const reportsApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    updateReportDetail: builder.mutation({
      query: (data) => ({
        url: `/v2/consulting/reports/detail/${data.id}/`,
        method: "PUT",
        body: data,
      }),
    }),
    getFinalReport: builder.query({
      query: ({ reportId, farmId }) => ({
        url: `/api/satplat/api/v1/reports_detail/${farmId}/${reportId}/`,
        method: "GET",
      }),
    }),
    getReportsList: builder.query({
      query: ({ farmId, report_state, date__gte, date__lte }) => ({
        url: `/api/satplat/api/v1/reports/${farmId}/`,
        method: "GET",
        params: {
          report_state,
          date__gte,
          date__lte,
        },
      }),
    }),
  }),
});

export const {
  useGetReportsListQuery,
  useGetFinalReportQuery,
  useUpdateReportDetailMutation,
} = reportsApi;
