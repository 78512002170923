/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import Preview from "../Preview";

const PreviewReportModal = (props) => {
  return (
    <ModalComponent
      onClose={props?.onClose}
      open={props.open}
      paperProps={{ style: { width: "fit-content" } }}
    >
      <Box
        sx={{
          overflowY: "auto",
          height: "90vh",
          display: "flex",
        }}
      >
        <Preview id={props?.id} editable={false} farmId={props?.farmId} />
      </Box>
    </ModalComponent>
  );
};

export default PreviewReportModal;
