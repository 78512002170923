/* eslint-disable react/prop-types */
import { FarmManagementStates } from "@/redux/slices/farmManagementSlice";
import { useGetTimeSeriesQuery } from "@/services/meteorology";
import convert2Jalali from "@/utils/convert2Jalali";
import moment from "moment-jalaali";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import HandleServerData from "./common/HandleServerData";
import { Line } from "react-chartjs-2";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const ChartOnMap = ({ selectedFarm }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const selectedIndex = useSelector(
    (state) => state.farmManagement[FarmManagementStates.selectedIndex]
  );
  // const farmDates = useSelector((state) => state.satellite.farmDates);
  // const dateSwiperRef = useSelector((state) => state.satellite.dateSwiperRef);

  const [currentChartRef, setCurrentChartRef] = useState(null);

  //   useEffect(() => {
  //     currentChartRef &&
  //       dispatch(
  //         setChartRef({
  //           download: () =>
  //             download(currentChartRef?.toBase64Image(), selectedIndex, true),
  //         })
  //       );
  //   }, [currentChartRef, dispatch, selectedIndex]);

  const {
    data: chartData,
    isLoading,
    isError,
    isSuccess,
  } = useGetTimeSeriesQuery(
    {
      farmId: selectedFarm?.farm_info?.id,
      index: selectedIndex,
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    },
    { skip: !selectedFarm }
  );

  const unitScales = useCallback(() => {
    const diff = moment(endDate).diff(moment(startDate), "days"); //difference between times
    if (diff <= 7) {
      return "day";
    } else if (diff <= 60) {
      return "week";
    } else {
      return "month";
    }
  }, [startDate, endDate]);

  const labels = chartData?.time_series?.map((item) => item?.label) ?? [];
  const values = chartData?.time_series?.map((item) => item?.y) ?? [];

  const data = {
    labels,
    datasets: [
      {
        label: selectedIndex,
        data: values,
        fill: false,
        borderColor: "rgb(252,161,32)",
        tension: 0.1,
      },
    ],
  };

  const config = {
    type: "line",
    interaction: {
      mode: "point",
    },
    onClick: (e) => {
      //delete point on chart
      const activePoints = currentChartRef.getElementsAtEventForMode(
        e,
        "nearest",
        {
          intersect: true,
        },
        false
      );

      if (activePoints && activePoints.length) {
        values.splice(activePoints[0].index, 1);
        labels.splice(activePoints[0].index, 1);
        currentChartRef.update(values);
      }
    },
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        beforeDraw: (chart) => {
          const ctx = chart.canvas.getContext("2d");
          ctx.save();
          ctx.globalCompositeOperation = "destination-over";
          ctx.fillStyle = "#fff";
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
          ctx.update();
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return convert2Jalali(context[0].label);
            },
          },
        },
      },
      scales: {
        x: {
          type: "time",
          time: {
            unit: unitScales(),
          },
          ticks: {
            callback: function (value) {
              return convert2Jalali(value);
            },
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <Box
      sx={{
        height: 250,
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {" "}
      <HandleServerData
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
      >
        <Box sx={{ mb: 2 }}>
          <DatePicker
            slotProps={{
              textField: { size: "small", sx: { width: 180, mr: 2 } },
            }}
            label="تاریخ شروع"
            value={startDate}
            onChange={(value) => {
              setStartDate(value);
            }}
          />
          <DatePicker
            slotProps={{ textField: { size: "small", sx: { width: 180 } } }}
            label="تاریخ پایان"
            value={endDate}
            onChange={(value) => {
              setEndDate(value);
            }}
          />
        </Box>
        <Box>
          <Line ref={(e) => setCurrentChartRef(e)} {...config} />
        </Box>
      </HandleServerData>
    </Box>
  );
};

export default ChartOnMap;
