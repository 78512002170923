/* eslint-disable react/prop-types */
import { TYPE_TRANSLATOR } from "@/constants/global";
import { moneyFormat } from "@/utils/moneyFormat";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";

const StatCard = ({ inputType, totalMount, totalPrice }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        // height: 100,
        minWidth: 200,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        mx: 2,
        border: "1.5px solid #00000059",
        p: 2,
        borderRadius: 8,
        borderTopLeftRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: 80,
            width: 80,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${TYPE_TRANSLATOR?.[inputType]?.defaultImg})`,
          }}
        />
        <Typography fontWeight="bold">
          {TYPE_TRANSLATOR?.[inputType]?.title}
        </Typography>
      </Box>

      <Divider
        orientation={matches ? "vertical" : "horizontal"}
        flexItem
        sx={{ mx: { xs: 0, md: 2 }, my: { xs: 2, md: 0 } }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          flex: 1,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontWeight="bold" mr={4}>
            مقدار کل:
          </Typography>
          <Typography>{totalMount} </Typography>
          <Typography sx={{ color: "#9BA5B7" }} component="span">
            {inputType === "seed" ? "کیلوگرم" : "بسته"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontWeight="bold" mr={4}>
            مبلغ کل:
          </Typography>
          <Typography>
            {totalPrice ? moneyFormat(totalPrice) : ""}{" "}
            <Typography sx={{ color: "#9BA5B7" }} component="span">
              ریال
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatCard;
