import {
  bankValidationPath,
  knowledgeTransferPath,
  acquireInputsPath,
} from "@/constants/pagePath";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

const STEPS = [
  { title: "دریافت نهاده", url: acquireInputsPath },
  { title: "انتقال دانش", url: knowledgeTransferPath },
  // { title: "صدور بیمه نامه", url: insurancePolicyIssuancePath },
  { title: "اعتبار سنجی بانک کشاورزی", url: bankValidationPath },
  // { title: "شرکت خدمات حمایتی", url: supportServicesCompanyPath },
  // { title: "خرید تضمینی", url: guaranteedPurchasePath },
];

const Verifications = () => {
  const location = useLocation();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          width: "90%",
          height: "fit-content",
          borderRadius: 8,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {STEPS.map((item, index) => {
            const style = matches
              ? item.url === location.pathname
                ? {
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    borderBottom: "1px #000 solid",
                  }
                : { display: "none" }
              : {};

            return (
              <Box
                key={index}
                sx={{
                  ...style,
                  fontWeight: "bold",
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  mx: 2,
                  background:
                    item.url === location.pathname ? "#d8d8d8" : "#ecececab",
                  padding: "15px 30px",
                }}
              >
                {item.title}
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            background: "#d8d8d8",
            borderRadius: 4,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Verifications;
