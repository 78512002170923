/* eslint-disable react/prop-types */
import { Box, Button, IconButton, Typography } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { Close } from "@mui/icons-material";
import { moneyFormat } from "@/utils/moneyFormat";
import EnterSaleCode from "../contracts-modules/EnterSaleCode";
import { useState } from "react";
import {
  useSendPurchaseOTPMutation,
  useSubmitPurchaseOTPMutation,
} from "@/services/contracts";

const ItemTitle = ({ title, value }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Typography sx={{ mr: 1, fontWeight: "bold" }}>{title}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

const PriceTitle = ({ title, value }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ mr: 1, fontWeight: "bold" }}>{title}</Typography>
      <Typography sx={{ background: "#F4F6F8", px: 2, py: 0.5 }}>
        {value}
      </Typography>
    </Box>
  );
};

const FarmerSaleProduct = (props) => {
  const [code, setCode] = useState("");
  const [sandCode, setSandCode] = useState(false);

  const [sendOtpMutation, { isLoading }] = useSendPurchaseOTPMutation();
  const [submitOtpMutation] = useSubmitPurchaseOTPMutation();

  const {
    fertilizers_cost,
    seeds_cost,
    poisons_cost,
    knowledge_agreement,
    total_cost,
    final_product_weight,
    product_price,
    gauranteed_purchase_price,
    final_price,
  } = props?.data?.purchase ?? {};

  return (
    <ModalComponent open={props.open}>
      <Box
        sx={{
          p: { xs: 4, md: 6 },
          maxWidth: { xs: "100%", md: "800px" },

          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: "10px", left: "10px" }}
          onClick={props.onClose}
        >
          <Close />
        </IconButton>
        <Box
          sx={{
            border: "1px solid #00AD73",
            position: "relative",
            borderRadius: 1,
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            columnGap: 4,
            rowGap: 4,
          }}
        >
          <Box
            sx={{
              background: "#fff",
              position: "absolute",
              top: -12,
              left: 20,
              px: 2,
            }}
          >
            <Typography fontWeight="bold" fontSize="17px">
              فاکتور قرارداد
            </Typography>
          </Box>
          <ItemTitle
            title="هزینه کود:"
            value={`${moneyFormat(fertilizers_cost)} ریال`}
          />
          <ItemTitle
            title="هزینه سم:"
            value={`${moneyFormat(poisons_cost)} ریال`}
          />
          <ItemTitle
            title="هزینه بذر:"
            value={`${moneyFormat(seeds_cost)} ریال`}
          />
          <ItemTitle
            title="هزینه انتقال دانش و فناوری:"
            value={`${moneyFormat(knowledge_agreement)} ریال`}
          />
          <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <PriceTitle
              title="مبلغ کل قرار داد:"
              value={`${moneyFormat(total_cost)} ریال`}
            />
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid #00AD73",
            position: "relative",
            borderRadius: 1,
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            columnGap: 4,
            rowGap: 4,
            mt: 4,
          }}
        >
          <Box
            sx={{
              background: "#fff",
              position: "absolute",
              top: -12,
              left: 20,
              px: 2,
            }}
          >
            <Typography fontWeight="bold" fontSize="17px">
              فاکتور فروش
            </Typography>
          </Box>
          <ItemTitle
            title="حجم فروش:"
            value={`${moneyFormat(final_product_weight)} کیلوگرم`}
          />
          <ItemTitle
            title="قیمت تضمینی:"
            value={`${moneyFormat(product_price)} ریال`}
          />

          <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <PriceTitle
              title="مبلغ خرید تضمینی:"
              value={`${moneyFormat(gauranteed_purchase_price)} ریال`}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flex: 1, justifyContent: "center", my: 4 }}>
          <PriceTitle
            title="مبلغ کل دریافتی بابت فروش محصول:"
            value={`${moneyFormat(final_price)} ریال`}
          />
        </Box>
        {props?.enableAction && (
          <>
            {!sandCode && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    mt: 1,
                    width: { xs: "80%", md: "455px" },
                    background: "#E4F4F6",
                    borderRadius: 2,
                    padding: "5px 20px",
                  }}
                >
                  <Typography fontSize="12px" textAlign="center" lineHeight={2}>
                    کاربر گرامی ادامه فرآیند تایید خرید نیازمند دریافت کد به
                    عنوان رمز یکبار مصرف در قالب پیامک است. این کد به منزله
                    امضای دیجیتال شماست، آن را در دسترس دیگران قرار ندهید .
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    my: 4,
                  }}
                >
                  <Button
                    disabled={isLoading}
                    variant="contained"
                    onClick={() => {
                      sendOtpMutation(props?.data?.id)
                        .unwrap()
                        .then(() => {
                          setSandCode((prev) => !prev);
                        });
                    }}
                  >
                    ارسال کد
                  </Button>
                </Box>
              </Box>
            )}
            {sandCode && (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <EnterSaleCode
                  code={code}
                  setCode={setCode}
                  handleSubmit={() => {
                    submitOtpMutation({
                      purchaseId: props?.data?.purchase?.id,
                      token: code,
                    })
                      .unwrap()
                      .then(() => {
                        props.onClose();
                      });
                  }}
                  handleReset={(enableTimer) => {
                    enableTimer();
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </ModalComponent>
  );
};

export default FarmerSaleProduct;
