import StatCard from "@/components/admin-acquire-management-modules/StatCard";
import Table from "@/components/admin-acquire-management-modules/Table";
import { Roles, TYPE_TRANSLATOR } from "@/constants/global";
import {
  useGetCompanyProviderBriefQuery,
  useGetInputTypesBriefQuery,
  useGetStatesQuery,
  useLazyGetAdminStatsQuery,
  useLazyGetCooperativeBriefQuery,
} from "@/services/contracts";
import { Download } from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AdminAcquireManagement = () => {
  const user = useSelector((state) => state.authentication.user);
  const isStateAdmin = user?.user_info?.type?.name === Roles.STATE_ADMIN;

  const [requestState, setRequestState] = useState({
    page: 1,
    input_title: "",
    ordering: "",
    state: isStateAdmin ? user?.state : "",
    page_size: 5,
    cooperative_company: "",
    provider: "",
    input_type: "",
  });

  const [trigger, { data, isLoading, isSuccess, isFetching }] =
    useLazyGetAdminStatsQuery();

  const downloadCSV = async () => {
    try {
      const response = await fetch(
        import.meta.env.VITE_PRODUCTION_BASE_URL +
          `/api/contract-mgmt/api/v1/admins/provider-input/list/csv/?` +
          new URLSearchParams(requestState).toString(),
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Assuming the response is already in the correct CSV format and UTF-8 encoded
      const csvData = await response.text();

      // Correctly handle UTF-8 BOM to avoid issues in Excel or other apps
      const bom = "\uFEFF";
      const blob = new Blob([bom + csvData], {
        type: "text/csv;charset=utf-8;",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.csv"; // Specify the filename for the downloaded file
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching the CSV file:", error);
    }
  };

  const { data: provinces } = useGetStatesQuery({}, { skip: isStateAdmin });
  const [getCompanyProvider, { data: companies }] =
    useLazyGetCooperativeBriefQuery({ state: 0 });
  const { data: companyProviderBrief } = useGetCompanyProviderBriefQuery({
    input_type_title: requestState.input_type,
  });

  useEffect(() => {
    trigger(requestState);
  }, []);

  useEffect(() => {
    if (isStateAdmin) {
      getCompanyProvider({ state: user?.state });
    }
  }, [getCompanyProvider, isStateAdmin, trigger, user?.state]);

  const handleChangePage = (event, newPage) => {
    setRequestState((prev) => {
      const newState = { ...prev, page: newPage + 1 };
      trigger(newState);
      return newState;
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRequestState((prev) => {
      const newState = {
        ...prev,
        page: 1,
        page_size: parseInt(event.target.value, 10),
      };
      trigger(newState);
      return newState;
    });
  };

  const { data: inputTypesList } = useGetInputTypesBriefQuery();

  const debounceRequest = debounce((requestParam) => {
    trigger(requestParam);
  }, 1000);

  const handleDebounceRequest = useCallback((value) => {
    debounceRequest(value);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ mt: 8, mb: 2 }}>
        <Button
          onClick={() => {
            downloadCSV();
          }}
          endIcon={<Download />}
          variant="outlined"
        >
          دانلود اکسل
        </Button>
      </Box>
      <Paper sx={{ width: "80%", maxWidth: 1200, p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            value={requestState.input_title}
            onChange={(e) =>
              setRequestState((prev) => {
                const copiedData = { ...prev };
                copiedData.input_title = e.target.value;
                handleDebounceRequest(copiedData);
                copiedData.page = 1;
                return copiedData;
              })
            }
            label="جستجو"
            size="small"
            placeholder="نام محصول"
          />

          {!isStateAdmin && (
            <TextField
              onChange={(e) =>
                setRequestState((prev) => {
                  const newState = {
                    ...prev,
                    page: 1,
                    state: e.target.value,
                  };
                  newState.cooperative_company = "";
                  if (e.target.value) {
                    getCompanyProvider({ state: e.target.value });
                  }

                  trigger(newState);
                  return newState;
                })
              }
              SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
              value={requestState.state}
              sx={{ width: 200 }}
              select
              size="small"
              label="استان"
            >
              <MenuItem value={""}>بدون فیلتر</MenuItem>
              {provinces?.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            disabled={provinces && !requestState.state}
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  cooperative_company: e.target.value,
                };
                trigger(newState);
                return newState;
              })
            }
            SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
            value={requestState.cooperative_company}
            sx={{ width: 200 }}
            select
            size="small"
            label="مباشر"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {companies?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  input_type: e.target.value,
                  provider: "",
                };
                trigger(newState);
                return newState;
              })
            }
            value={requestState.input_type}
            sx={{ width: 200 }}
            select
            size="small"
            label="نوع نهاده"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {inputTypesList?.map((item, index) => (
              <MenuItem value={item?.title} key={index}>
                {TYPE_TRANSLATOR?.[item?.title]?.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  provider: e.target.value,
                };
                trigger(newState);
                return newState;
              })
            }
            SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
            value={requestState.provider}
            sx={{ width: 200 }}
            select
            size="small"
            label="شرکت ارائه دهنده"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {companyProviderBrief?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={{ pt: 4 }}>
          <Table
            rowsPerPage={requestState.page_size}
            page={requestState.page - 1}
            data={data?.results}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isFetching={isFetching}
          />
          <TablePagination
            sx={{ mt: 2 }}
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={data?.total_items ?? 0}
            rowsPerPage={requestState.page_size}
            page={requestState.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="ردیف در هر صفحه: "
            labelDisplayedRows={({ count, page }) =>
              count > 0 && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: "14px" }}>
                    تعداد کل ردیف ها: {count}
                  </Typography>
                  <Typography sx={{ ml: 4, fontSize: "14px" }}>
                    صفحه: {page + 1} از{" "}
                    {Math.ceil(count / requestState.page_size)}
                  </Typography>
                </Box>
              )
            }
          />
        </Box>
      </Paper>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mt: 6,
          justifyContent: "center",
          rowGap: 4,
          mb: 4,
        }}
      >
        <StatCard
          inputType="seed"
          totalMount={data?.seed?.total_amount}
          totalPrice={data?.seed?.total_price}
        />
        <StatCard
          inputType="poison"
          totalMount={data?.poison?.total_amount}
          totalPrice={data?.poison?.total_price}
        />
        <StatCard
          inputType="fertilizer"
          totalMount={data?.fertilizer?.total_amount}
          totalPrice={data?.fertilizer?.total_price}
        />
      </Box>
    </Box>
  );
};

export default AdminAcquireManagement;
