/* eslint-disable react/prop-types */
import moment from "moment-jalaali";

const BG_COLOR = "#0058159e";

const Title = ({ label, center }) => {
  return (
    <div
      id="contract-title"
      style={{
        height: 50,
        background: BG_COLOR,
        display: "flex",
        alignItems: "center",
        padding: "0 25px",
        border: "1px solid #005815",
        justifyContent: center ? "center" : "flex-start",
        marginBottom: 15,
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: 22, color: "#fff" }}>{label}</p>
    </div>
  );
};

const Value = ({ children }) => {
  return (
    <span
      style={{
        display: "inline",
        color: "red",
        fontSize: 16,
      }}
    >
      {children}
    </span>
  );
};

const Description = ({ children }) => {
  return (
    <p
      style={{
        textAlign: "justify",
        direction: "rtl",
        fontSize: 16,
      }}
    >
      {children}
    </p>
  );
};

const ContentWrapper = ({ children }) => {
  return <div style={{ margin: "10px 0" }}> {children}</div>;
};

const ContractDetailTemplate = ({
  contractDetail,
  disableContractNumber = false,
}) => {
  const currentYear = moment().format("jYYYY");
  const currentDate = moment().format("jYYYY/jMM/jDD");

  const data = contractDetail?.contract_info;

  return (
    <div
      id="print-section"
      style={{
        direction: "rtl",
        maxWidth: "800px",
        width: "100%",
        height: "100%",
        background: "white",
        position: "relative",
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: 28, textAlign: "center" }}>
        بسمه تعالی
      </p>
      <h1 style={{ fontSize: 20, textAlign: "center", marginTop: 15 }}>
        قرارداد چغندر قند پاییزه در سال زراعی {parseInt(currentYear) + 1} -{" "}
        {currentYear}
      </h1>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "50px 0" }}
      >
        <table dir="rtl">
          <tbody>
            <tr>
              <th style={{ border: "1px #000 solid", padding: 10 }}>
                عنوان قرارداد:
              </th>
              <th style={{ border: "1px #000 solid", padding: 10 }}>
                قرارداد چغندر قند پاییزه
              </th>
            </tr>
            <tr>
              <th style={{ border: "1px #000 solid", padding: 10 }}>
                تاریخ قرارداد:
              </th>
              <th style={{ border: "1px #000 solid", padding: 10 }}>
                {currentDate}
              </th>
            </tr>
            {!disableContractNumber && (
              <tr>
                <th style={{ border: "1px #000 solid", padding: 10 }}>
                  شماره قرارداد:
                </th>
                <th style={{ border: "1px #000 solid", padding: 10 }}>
                  {contractDetail?.contract_number}
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div>
        <ContentWrapper>
          <Title label="مقدمه" center />
          <Description>
            این قرارداد با رضایت کامل طرفین که خود را ملزم و متعهد به اجرای کلیه
            مفاد آن و همچنین مقدمات و موخرات لازم جهت اجرای موضوع آن می دانند
            بین شرکت توسعه کشاورزی قند خاورمیانه به شناسه ملی 14010936955 به
            نشانی، کیلومتر هفت جاده شوش، دزفول که بعد از این شرکت نامیده می شود
            و خانم/آقای{" "}
            <Value>
              {data?.profile?.first_name + " " + data?.profile?.last_name}
            </Value>{" "}
            به شماره شناسنامه{" "}
            <Value>{data?.profile?.birth_crtificate_id ?? "-"}</Value> صادره از{" "}
            <Value>{data?.profile?.state?.title}</Value> و به کد ملی{" "}
            <Value>{data?.profile?.national_id}</Value> و نشانی{" "}
            <Value>{data?.profile?.address}</Value> به کدپستی{" "}
            <Value>{data?.profile?.postal_code}</Value> و شماره تلفن همراه (واتس
            اپ و دیگر پیام رسان های مجازی) :{" "}
            <Value>{data?.profile?.phone_number}</Value> که منبعد چغندرکار
            نامیده می شود، منعقد گردید.
          </Description>
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده یک – موضوع قرارداد" />
          <Description>
            {" "}
            1-1. موضوع قرارداد عبارت است از بیـع حداقل50 تن در هکتـار و تعهد به
            بیع حداکثر تمام تنـاژ چغندر تولیـدی چغندرکار در مساحـت به عدد{" "}
            <Value>{data?.farm?.area}</Value> و به حروف ....... هکتار از اراضي
            مورد تصرف ایشان ، واقع در استان{" "}
            <Value>{data?.farm?.state?.title}</Value> شهرستان{" "}
            <Value>{data?.farm?.city?.title}</Value> روستاي ......... بخش ......
            می باشد.
            <br />
            تبصره 1 : شرکت در افزایش یا کاهش موضوع قرارداد حاضر تا حداکثر 15% (
            پانزده درصد ) اختیار تام دارد و چغندرکار ( کشاورز ) ضمن اطلاع از
            مفاد این ماده ، حق هرگونه ادعا و اعتراض نزد مراجع قضایی ، شبه قضایی
            یا داوری را از خود سلب و ساقط نمود.
          </Description>
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده دو - توافقات مالی و تضامین قراردادی" />
          <Description>
            1-2. بهـای محصول پس از ابلاغ مراجع ذیصلاح و طبق نرخ مصوب شورای
            اقتصاد و بر اساس مقدار چغندر تحویلی محاسبه شده و پس از کسر بدهی های
            مربوطه (بهای نهاده ها و ماشین آلات کشت ،کرایه حمل، وجوه التزام
            متعلقه و ...... ) طبق جدول زمانبنـدی اعلام شده از سوی شرکت پرداخت
            خواهد شد.
            <br /> تبصره 2 : سهمیه تفاله و شکر بر اساس مصوبه شورای اقتصاد و بر
            حسب چغندر خالص بعد از پایان بهره برداری محاسبه و به صورت عین یا مثل
            (معادل ریالی) از سوی شرکت پرداخت می گردد. شرکت در انتخاب هر یک از
            این دو روش مختار است.
            <br /> 2-2. در صورتی که چغندرکار به هر علتی به شرکت بدهکار گردد،
            تعهد می نماید که حداکثر ظرف مدت دو ماه پس از اعلام شرکت نسبت به
            پرداخت و تسویه بدهی خود اقدام نماید. در غیر اینصورت به ازای هر روز
            تاخیر مبلغی معادل یک درصد از کل بدهی به عنوان وجه التزام محاسبه و به
            شرکت پرداخت می گردد. این وجه التـزام بلافاصله بعد از شروع تاخیر محقق
            شده و نیازمند مطالبه وجه از سوی شرکت نمی باشد.
            <br /> 3-2. چغندرکار از بابت تضمیـن حسن انجام هر یک از تعهدات
            قرارداد حاضر یک فقـره چک به شماره ............. و مبلغ ............
            ریال (به حروف ........ ریال) عهده بانک ............ صادر و به شرکت
            تحویل نمود و شرکت اختیار دارد بلافاصله پس از احراز تخلف چغندرکار این
            چک را به عنوان حداقل خسارت قراردادی وصول نماید. چنانچه خسارت شرکت
            مازاد بر این مبلغ باشد حق مراجعه از بابت مازاد برای شرکت محفوظ است.
            <br /> 4-2. به اقرار طرفین و باتوجه به ماهیت رابطه قراردادی، این
            قرارداد مشمول کسر بیمه نمی باشد. با این حال چغندرکار اقرار می نماید
            که نزد مراجع مربوطه و باتوجه به فعالیت اقتصادی خود نسبت به اخذ بیمه
            تامین اجتماعی اقدام نموده و در صورت نیاز مکلف است که مستندات مربوطه
            را جهت ارائه به اشخاص ذیربط در اختیار شرکت قرار دهد و در غیر این
            صورت مسئول کلیه هزینه های پرداخت شده و خسارات مربوطه از این بابت
            خواهد بود. تخلف از این ماده در حکم تخلف از تعهدات قرارداد بوده و
            شرکت اختیار دارد مطابق مفاد بند 3ماده 2 اقدام نمایند.
            <br /> 5-2. از بابت انجام تعهدات مالی قرارداد حاضر، شماره حساب
            .................... نزد بانک ..... به نام خانم/آقای
            ................ به شرکت معرفی گردید و هرگونه پرداخت وجه در حساب
            مذکور به منزله ی پرداخت تعهدات مالی ناشی از قرارداد حاضر می باشد.
          </Description>
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده سه - مواعد قراردادی" />
          <Description>
            1-3. زمان شروع برداشت و ورود به مزارع به منظور برگ زنی(چاپـر) از
            تاریخ 15/01/1404 می باشد که چغندرکار با علم و آگاهی از این موضوع به
            انعقاد قرارداد با شرکت اقدام نموده و موظف است بر اساس زمانبندی اعلام
            شده از سوی شرکت، مزرعه چغندر خود را به همان ترتیب با هماهنگی مسئول
            منطقه ، آماده برداشت و تحویل به شرکت نماید.ضمنا طرفین پذیرفتند گزارش
            مسئول منطقه مبنی بر تاخیر یا اهمال در انجام این تعهـد ملاک تخلف از
            این ماده می‌باشد.
            <br /> 2-3. چغندرکار موظف است براساس زمانبندی اعلام شده از سوی
            کارخانه معادل 50 درصد از مزرعه چغندر خود را ( مطابق مساحت اعلامی در
            ماده یک این قرارداد) در مرحله اول، برداشت نموده و جهت تامین به موقع
            مواد اولیه کارخانه به شرکت تحویل نماید. الباقی مساحت زیر کشت در
            مرحله دوم، برداشت و تحویل می گردد. گزارش مسئول منطقه مبنی بر تاخیر
            یا اهمال در انجام این تعهد ملاک تخلف از این ماده می‌باشد.
            <br />
            تبصره 3: سرکار خانم/جناب آقای{" "}
            <Value>
              {data?.profile?.direct?.direct_first_name +
                " " +
                data?.profile?.direct?.direct_last_name}
            </Value>{" "}
            به کد ملی <Value>{data?.profile?.direct?.direct_national_id}</Value>{" "}
            به عنوان مسئـول منطقه تعییـن گردید.
            <br />
            3-3. درصورت عدم همکاری چغندرکار و خودداری از برداشت در مرحله اول ،
            عواقب و خسارت وارده به کارخانه طبق نظر شرکت از چغندرکار وصـول می
            گردد و همچنین مزرعه ایشان ، بعد از برداشت کلیه مزارع و مشروط به
            احـراز سلامت و کیفیـت محصول ، برداشت خواهد شد. چغندرکار ضمن اطلاع از
            مفاد این ماده و اهمیت آن برای شرکت، حـق هر گونه ادعـا و اعتـراض از
            این بابت را از خود سلـب و ساقط نمود.
            <br /> 4-3. درصورتی که چغندرکار از همکاری با نماینده شرکت در طول مدت
            برداشت امتناع کند و امکان برداشت محصول چغندرکار برای شرکت به دلیل
            تعطیلی (اوایل تیرماه) شرکت فراهم نگردد، شرکت هیچگونه مسئولیتـی در
            این خصوص نخواهد داشت و چنانچه محصول چغندرکار به دیگر کارخانجات منتقل
            شود، پرداخت کامـل کرایه به عهده چغندرکار خواهد بود.
            <br /> تبصره 4: در صورتی که چغندرکار در اوایل برداشت از تحویل 50
            درصد اولیه محصول ممانعت نماید با توجه به گزارش مسئول منطقه پرداخت
            بهـای محصول دریافت شده از چغندرکار، در موعدی برابر با دو ماه بعـد از
            پرداخت به دیگر چغندرکاران صورت می گیرد. چغندر کار ضمن تعهد به رعایت
            این مواعد، حـق هرگونه ادعـا و اعتراض از این بابت را از خود سلـب و
            ساقط نمود.
            <br /> تبصره 5: با توجه به هزینه های جاری کارخانه (در طول مدت بهره
            برداری) و لزوم تامین چغندر مورد نیاز روزانه شرکت به خصوص در اوایل
            برداشت ، چنانچه در طول بهره برداری (به خصوص اوایل برداشت) چغندرکار
            از برداشت و تحویل چغندرقند به شرکت تا حداکثر مورخ ........ خودداری
            نماید، ملزم و متعهد به جبران زیان وارده به شرکت، بر اساس نظر شرکت می
            باشد، علاوه بر این، وجه التزامی معادل روزانه ........... ریال بابت
            هر روز تاخیر در تحویل چغندر محاسبه و از سوی شرکت مطالبه می گردد.
            <br /> 5-3. ثبت تاریخ کشت بر اساس تاریخ کشت بذر در مزرعه می باشد و
            به آبیاری اولیه و دیگر موارد در این خصوص ارتباطی ندارد که گزارش
            مسئول منطقه از تاریخ کشت بذر مورد پذیرش طرفین بوده و زمانبندی برداشت
            محصول مزرعه چغندرکار بر این اساس صورت می گیرد که چغندرکار ضمن متعهد
            شدن به مفاد این ماده حق هرگونه ادعا و اعتراض را از خود سلب و ساقط می
            نماید.
            <br /> 6-3. چغندرکار متعهد می گردد که در زمان عقد قرارداد آزمون خاک
            مزرعه تایید شده توسط مسئول منطقه ( مطابق ماده یک قرارداد ) را به
            شرکت تحویل نماید. گزارش مسئول منطقه مبنی بر تاخیر یا اهمال در انجام
            این تعهد ملاک تخلف از این ماده می‌باشد.
            <br /> 7-3. زمین زراعی مورد نظر نباید سال قبل در آن چغندرقند کشت شده
            باشد.
            <br /> 8-3. قبل از عقد قرارداد و در زمان بازدید و تائید زمین زراعی،
            نخست نسبت به انجام UTM اقدام شود.
          </Description>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "50px 0",
            }}
          >
            <table dir="rtl">
              <tbody>
                <tr>
                  <th
                    style={{
                      border: "1px #000 solid",
                      padding: 10,
                      textAlign: "center",
                      background: BG_COLOR,
                    }}
                  >
                    شرکت
                  </th>
                  <th
                    style={{
                      border: "1px #000 solid",
                      padding: 10,
                      textAlign: "center",
                      background: BG_COLOR,
                    }}
                  >
                    چغندرکار
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      border: "1px #000 solid",
                      padding: 10,
                      textAlign: "center",
                    }}
                  >
                    توسعه کشاورزی قند خاورمیانه
                  </th>
                  <th
                    style={{
                      border: "1px #000 solid",
                      padding: 10,
                      textAlign: "center",
                    }}
                  >
                    جناب آقای{" "}
                    <Value>
                      {data?.profile?.first_name +
                        " " +
                        data?.profile?.last_name}
                    </Value>
                  </th>
                </tr>
              </tbody>
            </table>
          </div> */}
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده چهار - تعهدات و الزامات قراردادی" />
          <Description>
            1-4. عدم کشت ، کاهش کشت کمتر از سطح کشت ، تحویل کمتر از مقدار
            چغندرقند موضوع ماده یک و فروش آزاد محصول توسط چغندرکار که به تشخیص
            مسئول منطقه عمدی باشد، تخلف از تعهدات قرارداد حاضر محسوب شده و در
            این صورت شرکت مجاز است که ضمن بهره برداری از تضمین سپرده شده، متناسب
            با کسر تحویل و یا عدم تحویل چغندر، به روش های ذیل جهت جبران خسارت
            اقدام نموده و مبلغ خسارات وارده را از هر طریق ممکن، از جمله تهاتر با
            مطالبات چغندرکار وصول نماید : الف- در صورتی که با توجه به ماده یک
            قرارداد حاضر، چغندر تحویلی شده کمتر از 50 تن در هکتار باشد، شرکت به
            میزان هر یک تن کسری چغندرقند، مبلغی معادل مبلغ خرید یک تن چغندرقند
            برابر نرخ اعلام شده از سوی شورای عالی اقتصاد در سال زراعی
            (1404-1403) را به حساب بدهی چغندرکار، منظور می نماید. ب-در صورتی که
            چغندرکار محصول خود را به صورت آزاد، فروش نماید و از تحویل آن به شرکت
            ممانعت نماید. شرکت وجه التزامی را مطابق روش زیر از چغندرکار وصول می
            نماید : این وجه التزام برابر است با معادل متوسط تولید محصول چغندرقند
            در سطح یک هکتار( 50 تن در هکتار) به نرخ مصوب شورای عالی اقتصاد در
            سال زراعی (1404-1403) به ازای کل سطح قرارداد منعقد شده با چغندرکار.
            <br /> 2-4. چغندرهای تحویلی به کارخانه باید سالم، بدون برگ و طوقه،
            خاک و سنگ و مواد زائد باشد. محموله های دارای چغندرهای گندیده، سیاه،
            گرمازده و دارای سنگ به هیچ وجه تحویل گرفته نمی شود و عودت خواهد شد.
            ملاک بررسی کیفیت چغندر های تحویل شده، اعلام نظر نماینده شرکت می
            باشد.
            <br /> تبصره 6 : بر اساس نظر کنترل کیفی شرکت از جذب محموله های چغندر
            ذیل ممانعت به عمل می آید : الف) چغندرقند با عیار کمتر از 10 ، ب)
            چغندرقند با افت بالای 35 درصد ، پ) محموله چغندرقند با گندیدگی بالای
            35 درصد
            <br /> 3-4. چغندرکار موظف است قبل از بارگیری در مبداء، چغندرهای
            گندیده را از چغندر های سالم جدا نماید تا خسارتی متوجه شرکت نگردد.
            حسن اجرای این تعهد به عهده چغندرکار می باشد. طی این فرآیند ضروری است
            که ناظر و مسئول منطقه در محل حضور داشته باشد. گزارش مسئول منطقه ملاک
            تخلف از ماده و مجوز بهره برداری از تضامین بند 3 ماده 2 می باشد.
            <br /> 4-4. چغندرکار اعلام می نماید که از تمامی نکات و دستورات فنی
            برای کاشت، داشت و برداشت چغندرقند اطلاع کافی داشته و نهایت دقت را در
            اجرای آنها به کار خواهد گرفت ؛ نظارت شرکت یا توصیه نمایندگان صرفا
            مشاوره ای بوده و موجب مسئولیت شرکت یا سلب سئولیت از چغندرکار نمی
            گردد.
            <br /> 5-4. کلیه هزینه ها مربوط به کشت چغندر ( مستقیم یا غیرمستقیم )
            بر عهده چغندرکار است و چنانچه شرکت جهت مساعدت نسبت به پرداخت این
            هزینه ها (از جمله هزینه حمل، نهاده‌ها و مکانیزاسیون و ... )اقدام
            نماید، حق مراجعه جهت مطالبه مبالغ پرداختی یا تهاتر با مطالبات
            چغندرکار برای شرکت محفوظ است.این مبالغ مطابق با صورت هزینه های
            اعلامی شرکت، از چغندرکار دریافت میگردد. چغندرکارحق هرگونه ادعا و
            اعتراض و طرح دعاوی نزد مراجع قضایی و ... را راجع به تمام مفاد ،حالات
            و تبعات این ماده ( فی المثـل مبالغ اعلامی و....) را از خود سلـب و
            ساقط نمود.
            <br /> تبصره 7: از آنجا که شرکت تعهدی نسبت به تهیه نهاده یا بذر
            ندارد، چنانچه از بابت مساعدت با چغندرکار نسبت به تهیه بذر اقدام
            نماید، کلیه مسئولیت های ناشی از مرغوبیت و کیفیت بذر بر عهده چغندرکار
            بوده و چغندرکار مختار است که ظرف ۲۴ ساعت پس از ارسال بذر از سوی
            شرکت، نسبت به استرداد بذر ارسالی اقدام نموده و با بذر خود کشت نماید.
            انقضای مهلت ۲۴ ساعته و سکوت چغندرکار، ضمن ایجاد تعهد به پرداخت قیمت
            روز بذر، در حکم تحویل بذر مرغوب و اسقاط حق هرگونه ادعا و اعتراض از
            سوی چغندرکار از این بابت می باشد.
            <br /> 6-4. در خصوص پرداخت کارکرد برداشت عامل مکانیزاسیون ، در صورت
            تایید چغندرکار هزینه کارکرد بارکن بر اساس وزن ناخالص ، محاسبه و به
            عامل مکانیزاسیون پرداخت می گردد.
            <br /> 7-4. تعیین عیار و افت توسط دستگاه عیار سنج و افت سنج شرکت
            بوده و برای طرفین معتبر می باشد. از این بابت، چغندرکار ضمن اطلاع از
            مفاد این ماده، حق هر گونه ادعا و اعتراض را از این بابت از خود سلب و
            ساقط نمود.
            <br /> تبصره 8 : فقط در صورتیکه عیار محموله ارسالی از سوی چغندرکار
            پایین تر از رقم اعلامی دستورالعمل عیارسنجی سازمان تحقیقات وزارت جهاد
            کشاورزی (عیار ده) باشد ، چغندرکار میتواند حداکثر تا 72 ساعت ، اعتراض
            خود را نسبت به عیار اعلامی از سوی شرکت فقط از طریق نماینده انجمن
            صنفی کشاورزان مستقر در کارخانه پیگیری نماید و پس از گذشت 72 ساعت از
            ثبت عیار حق هرگونه ادعا و اعتراض در مراجع ذی صلاح را از خود سلب و
            ساقط می نماید.
            <br /> 8-4. چغندرکار رضایت کامل خود را اعلام می نماید که بابت حضور
            نماینده انجمن صنفی کشاورزان مستقر در کارخانه جهت نظارت بر عیار
            محموله های ارسالی چغندرکار مبلغ 000/150 ریال بابت هر هکتار ( مطابق
            مساحت اعلامی در ماده یک این قرارداد ) از مانده بستانکاری ایشان نزد
            شرکت ، کسر گردد و چغندرکار حق هر گونه ادعا و اعتراض را از این بابت
            از خود سلب و ساقط می نماید.
            <br /> 9-4. شرکت هیچگونه مسئولیت و تعهدی نسبت به تهیه کامیون جهت حمل
            محصول چغندرکار از مزرعه تا کارخانه را ندارد، و چغندرکار ضمن اطلاع از
            مفاد این ماده حق هر گونه ادعا و اعتراض را از این بابت از خود سلب و
            ساقط می نماید.
          </Description>

          <Description>
            10-4. محصول چغندر برابر مقررات بایستی توسط چغندرکار بیمه گردد و برگ
            بیمه نامه را جهت ضمیمه نمودن به قرارداد تحویل شرکت دهد. در غیر
            اینصورت، مسئولیت هرگونه خسارت وارده با چغندرکار بوده و عدم تحویل
            چغندر قند به علل قوه قاهره یا مواردی که می توانست در شمول بیمه نامه
            قرارگیرد نیز در حکم عدم تحویل بوده و مطابق بند3 ماده 2 این قرارداد،
            رفتار می شود.
            <br /> 11-4. مسئولیت جبران خسارات ناشی از عدم رعایت موارد فنی توصیه
            شده از طرف مسئول منطقه و هرگونه تصمیمات درون گروهی از قبیل خرد کردن
            قطعات کشت و ... که باعث کندی کار ماشین آلات گردد ، مستقیماً به عهـده
            چغندرکار می باشد و در حکـم تخلف از قرارداد بوده و شرکت می تواند،
            مطابق مفاد بند 3 ماده 2 قرارداد اقدام نماید.
            <br /> 12-4. شرکت هیچگونه مسئولیتی جهت تامین آب برای کشت نداشته و
            چغندرکار نیز ضمن بررسی توانمندی خود ( از جمله تامین آب از چاه و
            غیره)،متعهد به تحویل بموقع تناژ معین شده در قرارداد حاضر می‌باشد.
            لذا چنانچه به هر دلیل این تحویل محقق نگردد شرکت اختیار دارد ضمن بهره
            ‌برداری از تضمین سپرده شده، وجه التزامی معادل دو برابر مبلغ کل
            قرارداد را از چغندرکار دریافت نماید. چنانچه خسارت وارده به شرکت از
            سوی چغندرکار مازاد بر مبالغ فوق باشد حق مراجعه ، جهت دریافت مازاد
            برای شرکت محفوظ می باشد.
            <br /> 13-4. در تمام طول مدت قرارداد رعایت نکات و مسائل و قواعد
            حفاظتی و ایمنی در خصوص فعالیت های چغندرکار و نیز نیروهای اجرائی که
            درتمام مدت اجرای قرارداد به کار میگیرد، به عهده چغندرکار می باشد و
            شرکت هیچگونه مسئولیتی در این خصوص ندارد. همچنین هیچگونه رابطه
            استخدامی بین شرکت و چغندرکار یا اشخاص دیگر مربوط به چغندرکار وجود
            نخواهد داشت. چغندرکار ضمن اطلاع از این ماده، مسئولیت هرگونه ادعا از
            این بابت را پذیرفته و مکلف به جبران خسارات وارده خواهد بود.
          </Description>
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده پنج - اقامتگاه قانونی و ابلاغ" />
          <Description>
            1-5. هرگونه ارسال مرقومه ، پیام (از طریق سامانه پیامکی) و ... به
            آدرس و شماره تماس مندرج در این قرارداد، به منزله دریافت آنها از سوی
            چغندرکار تلقی می گردد. لذا چغندرکار مکلف است در صورت تغییر آدرس ،
            شماره تمـاس خود و هر موردی(که موجب عدم ابلاغ گردد)مراتب را ظرف
            حداکثر48 ساعت به اطلاع شرکت برساند، درغیر این صورت هرگونه ابلاغ
            مطابق اطلاعات سابق معتبر است.
          </Description>
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده شش - حل اختلاف" />
          <Description>
            1-6. در صورت بروز هر گونه اختلاف بین طرفین اعم از اینکه مربوط به
            اجرای تعهدات موضوع قرارداد یا مربوط به تفسیر و تعبیر هر یک از مواد و
            پیوست ­های آن و یا تخلفی در خصوص تعهدات این قرارداد باشد و همچنین
            سایر اختلافات احتمالی از قبیل فسخ یا بطلان و خسارات و غیره، به جناب
            آقای سید مجتبی موسویان به شماره ملی 4282150491 به عنوان مقام ناصب
            داور مورد قبول طرفین مراجعه خواهد شد، داور معین شده از سوی ایشان
            نسبت به رسیدگی اقدام و رای داور برای طرفین قطعی و لازم الاجراست.
            <br /> 2-6. شرط داوری حاضر مستقل از این قرارداد بوده و حتی در فرض
            بطلان، فسخ، یا اختتام آن نیز معتبر است.
            <br /> 3-6. داور طبق مقررات دارای مسئولیت­های حقوقی و قانونی است
            لیکن داور بنا بر ادله ارائه شده از سوی طرفین تصمیم­گیری کرده و
            مسئولیت شرعی در این خصوص ندارد.
            <br /> تبصره 9 : طرفین ضمن امضای قرارداد حاضر کلیه اختیارات لازم جهت
            انجام امور مربوط به داوری را از بـدو الی الختم، به داور، تفویض
            نمودند.
            <br /> تبصره 10 : طرفین اقرار داشتند که در سامانه ابلاغ الکترونیکی
            قوه قضاییه (ثنـا) ثبت نام نموده اند و کلیه ابلاغ های داور از این
            طریق به ایشان وصول می گردد. مسئولیت عدم ثبت نام در سامانه مذکور بر
            عهده طرفین می باشد.
            <br /> 4-6. در صورت اختلاف فی مابین چغندرکار و عامل مکانیزاسیون از
            بابت میزان کارکرد مراحل کاشت ، داشت و برداشت ، چغندرکار اختیار کامل
            را به مسئول منطقه ( مطابق ماده3 بند 2 تبصره3 قرارداد ) می دهد که
            بعنوان داور در این خصوص تصمیم گیری نماید . رای داور برای طرفین قطعی
            و لازم الاجرا بوده و چغندرکار ضمن اطلاع از مفاد این ماده، حق هر گونه
            ادعا و اعتراض را از این بابت در مراجع قضایی و غیر قضایی را از خود
            سلب و ساقط نمود.
          </Description>
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده هفت - فسخ" />
          <Description>
            1-7. شرکت حق دارد در صورت وقوع موارد ذیل نسبت به فسخ قرارداد حاضر
            اقدام نماید :
          </Description>

          <Description>
            الف- در صورتی که تاخیر چغندرکار در انجام تعهدات قراردادی وی به حدی
            باشد که به تشخیص کارشناس شرکت امکان بهره برداری از محصول موضوع
            قرارداد حاضر عملاً منتفی باشد.
            <br /> ب- در صورتی که احراز گردد، چغندرکار نسبت به انجام تعهدات
            قراردادی خود به هر نحو مرتکب تقلب گردیده است.
            <br /> ج- در صورتی که احراز گردد، چغندرکار قرارداد حاضر را به شخص
            ثالث انتقال داده است. <br />
            د- در صورت بروز شرایط فورس ماژور برای شرکت.
            <br /> ه- در صورت تخلف چغندرکار از هریک از تعهدات قرارداد حاضر.
            <br />
            تبصره 11 : در صورت صلاح دید شرکت، امکان فسخ قرارداد صرفاً نسبت به
            بخشی از معامله وجود خواهد داشت.
            <br />
            2-7. در صورتی که علی رغم فسخ این قرارداد، خساراتی از بابت عملکرد
            چغندرکار به شرکت وارد آمده باشد. شرکت می تواند مبالغ مربوطه از بابت
            این خسارات را به هر نحو که بتواند از جمله بهره برداری از تضمین سپرده
            شده یا تهاتر و ... وصول نماید و فسـخ قرارداد خللی به حق شرکت از این
            بابت وارد نمی آورد.
            <br /> 3-7. در تمامی موارد، فسـخ قرارداد حاضر بدون نیاز به مراجعه به
            محاکم دادگستری و صرفاً با ارسال یک فقره اظهارنامه به نشانی چغندرکار
            محقق می گردد.
            <br /> 7-4. در هیچ کدام از موارد، وجه التزام بدل از اصل تعهد نیست و
            مسئولیت چغندرکار را نسبت به اصل تعهد منتفی نمی سازد.
          </Description>
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده هشت - فورس ماژور" />
          <Description>
            1-8. فورس ماژور عبارت است از حوادث غیر مترقبه و پیش بینی نشده خارج
            از اراده طرفین که شامل و محدود به موارد زیر می باشد :<br />{" "}
            جنگ،زلزله،آتش سوزی ناخواسته،بیماری های فراگیری که منجر به تعطیل رسمی
            از سوی حاکمیت شود و ورشکستگی شخص حقوقی. در صورت بروز فورس ماژور
            چنانچه فورس ماژور تا دو ماه ادامه داشته باشد قرارداد طرفین طی این
            مدت به تعویق می افتد و پس از آن بدون آنکه نیـاز به پرداخت خسارت نسبت
            به مدت به تعویق افتاده تعهدات طرفین ادامه می یابد . در صورت تداوم
            فورس ماژور بیش از یک سال شرکت حق فسق قرارداد را داشته و مسئولیتی به
            جبران خسارت چغندرکار ندارد.
            <br /> تبصره 12: تحریم اقتصادی،نوسانات قیمت ارز یا محصولات یا هزینه
            کارگر و غیره ، خشکسالی ، بیماری های فراگیری که مانع از انجام کار به
            طور عرفی نمی شود و دشواری در انجام کار از مصادیق فورس ماژور نمی
            باشد.
          </Description>
        </ContentWrapper>
        <ContentWrapper>
          <Title label="ماده نه - خاتمه" />
          <Description>
            1-9. این قرارداد در 9 (نه) ماده و 12 ( دوازده ) تبصره و در دو نسخه
            یکسان و متحدالمتن که در حکم واحد اند، بدون خدشه و قلم خوردگی رویت
            شده و به امضای طرفین رسیده است و صحت مطالب مندرج در آن مورد تایید می
            باشند و در محل شرکت توسعه کشاورزی قند خاورمیانه تنظیم و مبادله
            گردید. چغندرکار حق هرگونه ادعا و اعتراض در مراجع ذی صلاح قضایی و
            غیرقضایی را از این بابت از خود سلب و ساقط نمود. بالاقرار هر یک از
            طرفین نسخه اصلی خود را دریافت نمود.
          </Description>
        </ContentWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "50px 0",
          }}
        >
          <table dir="rtl">
            <tbody>
              <tr>
                <th
                  style={{
                    border: "1px #000 solid",
                    padding: 10,
                    textAlign: "center",
                    background: BG_COLOR,
                  }}
                >
                  شرکت
                </th>
                <th
                  style={{
                    border: "1px #000 solid",
                    padding: 10,
                    textAlign: "center",
                    background: BG_COLOR,
                  }}
                >
                  چغندرکار
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px #000 solid",
                    padding: 10,
                    textAlign: "center",
                  }}
                >
                  توسعه کشاورزی قند خاورمیانه
                </th>
                <th
                  style={{
                    border: "1px #000 solid",
                    padding: 40,
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  {contractDetail?.signature_file && (
                    <img
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        translate: "-50% -50%",
                        zIndex: 999,
                        pointerEvents: "none",
                        userSelect: "none",
                      }}
                      src={contractDetail?.signature_file}
                      alt="signeture"
                      width="140"
                    />
                  )}
                  <Value>
                    {data?.profile?.first_name + " " + data?.profile?.last_name}
                  </Value>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ContractDetailTemplate;
