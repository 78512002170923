/* eslint-disable react/prop-types */
import MapGL, {
  Marker,
  NavigationControl,
  Popup,
  GeolocateControl,
} from "react-map-gl";
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import {
  Check,
  Delete,
  DocumentScannerSharp,
  InfoOutlined,
} from "@mui/icons-material";
import {
  useDeleteAdminContractMutation,
  useGetAdminCentroidsQuery,
  useGetAdminContractByIdQuery,
  useGetAdminGeneralContractStateListQuery,
  useGetInputTypesBriefQuery,
  useGetStatesQuery,
  useLazyGetCooperativeBriefQuery,
} from "@/services/contracts";
import modalService from "@/components/common/Modal/modalService";
import MgmtContractPrices from "@/components/modals/MgmtContractPrices";
import Polygon from "@/components/Polygon";
import SetStateModal from "@/components/modals/SetStateModal";
import DeleteContracts from "@/components/modals/DeleteContracts";
import { useSelector } from "react-redux";
import { Roles, TYPE_TRANSLATOR } from "@/constants/global";
import { useGetProductsTitlesQuery } from "@/services/products";

const Item = ({ title, value, my, valueColor = "#000" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        my,
      }}
    >
      {title && <Typography color="GrayText">{title}</Typography>}
      <Typography textAlign="left" color={valueColor}>
        {value}
      </Typography>
    </Box>
  );
};

const PopupContent = ({ states, contractId, setSelectedContract }) => {
  const user = useSelector((state) => state.authentication.user);
  const isMainAdmin = user?.user_info?.type?.name === Roles.ADMIN;

  const [rowStates, setRowStates] = useState(null);
  const [deleteContract, { isLoading }] = useDeleteAdminContractMutation();
  const { data: popup, isLoading: isLoadingDetail } =
    useGetAdminContractByIdQuery(contractId, {
      skip: !contractId,
    });

  if (isLoadingDetail) {
    return <Typography>در حال بارگزاری...</Typography>;
  }

  return (
    <Popup
      style={{ width: 300 }}
      closeButton={false}
      onClose={() => setSelectedContract(null)}
      latitude={popup?.farm?.center?.coordinates?.[1] ?? 0}
      longitude={popup?.farm?.center?.coordinates?.[0] ?? 0}
    >
      <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
        <Typography mb={1.5} fontWeight="bold" textAlign="center">
          {popup?.profile?.first_name} {popup?.profile?.last_name}
        </Typography>
        <Item title="شناسه قرارداد:" value={popup?.contract_number} />
        <Item title="مساحت زمین:" value={`${popup?.farm?.area} هکتار`} my={1} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color="GrayText">مباشر:</Typography>
          <Typography textAlign="center">
            {popup?.cooperative_company?.cooperative_company_title}
          </Typography>
        </Box>
        {popup?.state?.title === "waiting" && isMainAdmin ? (
          <Box
            sx={{
              display: "flex",
              // flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              value={rowStates}
              onChange={(e) => setRowStates(e.target.value)}
              select
              label="تعیین وضعیت"
              size="small"
              sx={{ width: 150, mt: 2 }}
            >
              {states.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              disabled={!rowStates}
              title="تایید وضعیت"
              onClick={() =>
                modalService.open(SetStateModal, {
                  state: rowStates,
                  contractId: popup?.id,
                  onSuccess: () => {
                    setSelectedContract(null);
                  },
                })
              }
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
            >
              <Check sx={{ color: "green" }} />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography color="GrayText">وضعیت:</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {popup?.state?.title === "denied" && (
                <Tooltip title={popup.denial_reason} arrow>
                  <InfoOutlined
                    sx={{ mr: 1, width: 16, height: 16, color: "red" }}
                  />
                </Tooltip>
              )}
              <Typography
                color={popup?.state?.title === "active" ? "green" : "red"}
              >
                {popup?.state?.label}
              </Typography>
            </Box>
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <IconButton
            variant="outlined"
            onClick={() => {
              modalService.open(MgmtContractPrices, {
                contractId: popup?.id,
                cooperativeTitle:
                  popup?.cooperative_company?.cooperative_company_title,
              });
            }}
          >
            <DocumentScannerSharp />
          </IconButton>
          {isMainAdmin && (
            <IconButton
              sx={{ alignSelf: "center" }}
              disabled={isLoading}
              onClick={() =>
                modalService.open(DeleteContracts, {
                  handleSubmit: (onClose) =>
                    deleteContract(popup?.id)
                      .unwrap()
                      .then(() => {
                        setSelectedContract(null);
                        onClose();
                      }),
                })
              }
            >
              <Delete fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Popup>
  );
};

const AdminCoveredFields = () => {
  const [selectedContract, setSelectedContract] = useState(null);
  const user = useSelector((state) => state.authentication.user);
  const isStateAdmin = user?.user_info?.type?.name === Roles.STATE_ADMIN;
  const isMainAdmin = user?.user_info?.type?.name === Roles.ADMIN;

  const [requestState, setRequestState] = useState({
    input_title: "",
    state: isStateAdmin ? user?.state : "",
    cooperative_company: "",
    input_type: "",
  });

  const {
    data: centroids,
    isLoading,
    isFetching,
  } = useGetAdminCentroidsQuery(requestState);
  const { data: states } = useGetAdminGeneralContractStateListQuery(
    {},
    { skip: !isMainAdmin }
  );

  const [polygon, setPolygon] = useState(null);
  const mapRef = useRef();

  const [getCompanyProvider, { data: companies }] =
    useLazyGetCooperativeBriefQuery({ state: 0 });
  const { data: inputTitles } = useGetProductsTitlesQuery({
    input_type: requestState.input_type,
  });
  const { data: provinces } = useGetStatesQuery({}, { skip: isStateAdmin });
  const { data: inputTypesList } = useGetInputTypesBriefQuery();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      {(isLoading || isFetching) && (
        <Box
          sx={{
            position: "absolute",
            background: "#0000008c",
            inset: 0,
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography sx={{ fontWeight: "bold", color: "#fff", mt: 4 }}>
            در حال بارگزاری...
          </Typography>
        </Box>
      )}
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 280,
          px: 1,
        }}
      >
        <Box>
          <TextField
            fullWidth
            sx={{ mt: 1 }}
            InputProps={{
              endAdornment: <Search />,
            }}
            label="جستجو"
            placeholder="شناسه قرارداد/نام نام خانوادگی"
            value={currentSearch}
            onChange={(e) => {
              setContracts([]);
              setPage(1);
              setCurrentSearch(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflowY: "auto",
            py: 1,
            px: 0.5,
            mt: 1,
            direction: "rtl",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <Typography sx={{ mt: 1 }}>در حال بارگزاری...</Typography>
            </Box>
          ) : isSuccess ? (
            contracts?.map((item, index) => (
              <Card
                lastElementRef={
                  index === contracts?.length - 1 ? lastElementRef : null
                }
                selected={item?.id === selectedContract}
                key={item?.id}
                item={item}
                onClick={() => {
                  mapRef?.current?.flyTo({
                    center: item?.farm_info?.center?.coordinates,
                    zoom: 16,
                  });
                  setPolygon(item?.farm_info?.polygon?.coordinates);
                  setSelectedContract(item?.id);
                }}
              />
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>خطایی رخ داده است!</Typography>
            </Box>
          )}
        </Box>
      </Box> */}
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "absolute",
            zIndex: 999,
            background: "#ffffff",
            p: 4,
            left: 10,
            top: 10,
            borderRadius: 10,
            rowGap: 2,
          }}
        >
          {/* <TextField
            value={requestState.input_title}
            onChange={(e) =>
              setRequestState((prev) => {
                const copiedData = { ...prev };
                copiedData.input_title = e.target.value;
                handleDebounceRequest(copiedData);
                copiedData.page = 1;
                return copiedData;
              })
            }
            label="جستجو"
            size="small"
            placeholder="نام محصول"
          /> */}

          {!isStateAdmin && (
            <TextField
              onChange={(e) =>
                setRequestState((prev) => {
                  const newState = {
                    ...prev,
                    page: 1,
                    state: e.target.value,
                  };
                  newState.cooperative_company = "";
                  if (e.target.value) {
                    getCompanyProvider({ state: e.target.value });
                  }

                  return newState;
                })
              }
              SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
              value={requestState.state}
              sx={{ width: 200 }}
              select
              size="small"
              label="استان"
            >
              <MenuItem value={""}>بدون فیلتر</MenuItem>
              {provinces?.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            disabled={provinces && !requestState.state}
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  cooperative_company: e.target.value,
                };

                return newState;
              })
            }
            SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
            value={requestState.cooperative_company}
            sx={{ width: 200 }}
            select
            size="small"
            label="مباشر"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {companies?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  input_type: e.target.value,
                  input_title: "",
                };
                return newState;
              })
            }
            value={requestState.input_type}
            sx={{ width: 200 }}
            select
            size="small"
            label="نوع نهاده"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {inputTypesList?.map((item, index) => (
              <MenuItem value={item?.id} key={index}>
                {TYPE_TRANSLATOR?.[item?.title]?.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={!requestState.input_type}
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  input_title: e.target.value,
                };

                return newState;
              })
            }
            SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
            value={requestState.input_title}
            sx={{ width: 200 }}
            select
            size="small"
            label="عنوان نهاده"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {inputTitles?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <MapGL
          ref={mapRef}
          initialViewState={{
            longitude: 51.33725700771478,
            latitude: 35.70107661348783,
            zoom: 5,
          }}
          mapboxAccessToken={import.meta.env.VITE_MAP_TOKEN}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 4,
            boxShadow: "0px 5px 10px #00000050",
            position: "relative",
          }}
          mapStyle="mapbox://styles/amir790/ckxmvcic2o0jz15nsp93vt8ix"
        >
          <NavigationControl position="bottom-right" />
          <GeolocateControl
            position="bottom-right"
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation
            showUserHeading
          />
          {centroids?.map((point) => (
            <Marker
              key={`${point?.contract_state?.title}-${point?.id}`}
              color={
                point?.contract_state?.title === "active"
                  ? "green"
                  : point?.contract_state?.title === "waiting"
                  ? "yellow"
                  : "red"
              }
              style={{ cursor: "pointer" }}
              anchor="top"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                mapRef.current?.flyTo({
                  center: [point?.centroid?.lng, point?.centroid?.lat],
                  zoom: 16,
                });
                setPolygon(point?.polygon?.coordinates);
                setSelectedContract(point?.contract_id);
              }}
              latitude={point?.centroid?.lat}
              longitude={point?.centroid?.lng}
            />
          ))}
          {!!(polygon && selectedContract) && (
            <Polygon
              sourceId={`polygon-source`}
              layerId={`polygon-layer`}
              coordinates={polygon}
            />
          )}
          {selectedContract && (
            <PopupContent
              states={states}
              contractId={selectedContract}
              setSelectedContract={setSelectedContract}
            />
          )}
        </MapGL>
      </Box>
    </Box>
  );
};

export default AdminCoveredFields;
