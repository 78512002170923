/* eslint-disable react/prop-types */
import { bankVerificationImg } from "@/assets";
import GuideCard from "@/components/contract-registration-modules/GuideCard";
import {
  useGetBankValidationQuery,
  usePostBankValidationMutation,
  usePostContractStateChangeMutation,
} from "@/services/contracts";
import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";

const Item = ({ title, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        mb: "20px",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "flex-start", sm: "center" },
      }}
    >
      <Box sx={{ width: "250px", mb: 2 }}>
        <Typography fontSize="18px" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          background: "#fff",
          padding: "10px 18px",
          borderRadius: 4,
          marginLeft: { xs: 0, sm: 4 },
          maxWidth: { xs: 250, md: 600 },
          overflowX: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const BankValidation = () => {
  const matches = useMediaQuery("(max-width:1650px)");
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const { data } = useGetBankValidationQuery(contractId, { skip: !contractId });
  const [postBankValidation, { isLoading }] = usePostBankValidationMutation();

  const [postContractStateChange] = usePostContractStateChangeMutation();

  return (
    <Box
      sx={{
        minHeight: 500,
        padding: "30px 36px",
        diplay: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: matches ? "column" : "row" }}>
        <Box sx={{ display: "flex", justifyContent: matches ? "center" : "" }}>
          <GuideCard
            src={bankVerificationImg}
            title="نحوه اعتبار سنجی من چگونه است؟"
          >
            <Typography textAlign="justify">
              لطفا منتظر تایید اعتبار سنجی خود از بانک کشاورزی باشید.
            </Typography>
          </GuideCard>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", xl: "center" },
            // alignItems: "center",
            pl: matches ? 0 : 8,
            mt: matches ? 8 : 0,
            flexDirection: "column",
          }}
        >
          <Item title="مساحت زمین شما">
            <Typography>{data?.farm?.area} هکتار</Typography>
          </Item>
          <Item title=" اعتبار دریافتی بابت نهاده:">
            <IconButton
              sx={{ mb: 2 }}
              onClick={() => {
                postContractStateChange({
                  contractId,
                  stage: "acquire_input",
                });
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
            <Box component="table">
              <Box component="thead">
                <Box component="tr">
                  <Box
                    component="th"
                    sx={{
                      background: "#cfcfcf",
                      borderTopLeftRadius: 10,
                      p: 1,
                    }}
                  >
                    عنوان
                  </Box>
                  <Box component="th" sx={{ background: "#cfcfcf", p: 1 }}>
                    مقدار
                  </Box>
                  <Box component="th" sx={{ background: "#cfcfcf", p: 1 }}>
                    هزینه
                  </Box>
                  <Box
                    component="th"
                    sx={{
                      background: "#cfcfcf",
                      borderTopRightRadius: 10,
                      p: 1,
                    }}
                  >
                    شرکت ارائه دهنده
                  </Box>
                </Box>
              </Box>
              <Box component="tbody">
                {data?.provider?.selected_inputs?.map((item) => (
                  <Box component="tr" key={item?.id}>
                    <Box component="td" sx={{ p: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography textAlign="center">
                          {item?.selected_input?.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component="td" sx={{ p: 1 }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", px: 4 }}
                      >
                        <Typography
                          component="span"
                          sx={{ color: "#01ADB7", mr: 2 }}
                        >
                          {item?.needed_bags}
                        </Typography>
                        <Typography sx={{ textAlign: "center" }}>
                          {item?.selected_input?.input_type?.title === "seed"
                            ? "کیلوگرم"
                            : "بسته"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component="td" sx={{ p: 1 }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ textAlign: "center" }}>
                          {item?.cost > 0
                            ? `${Intl.NumberFormat("en").format(
                                item?.cost
                              )} ریال`
                            : "قیمت بعدا محاسبه می شود"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box component="td" sx={{ p: 1 }}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography sx={{ textAlign: "center" }}>
                          {item?.selected_input?.provider?.title ?? "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />
            <Typography mb={1}>
              مبلغ :{" "}
              {Intl.NumberFormat("en").format(data?.provider?.payed_cost)} ریال
            </Typography>
          </Item>

          {/* <Item title="شرکت ارائه دهنده بیمه نامه:">
            <Box sx={{ maxWidth: "300px" }}>
              <IconButton
                sx={{ mb: 2 }}
                onClick={() => {
                  postContractStateChange({
                    contractId,
                    stage: "insurance_validation",
                  });
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
              {data?.insurance?.payed_cost ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 1,
                    mt: 2,
                  }}
                >
                  <img
                    width={100}
                    src={`${import.meta.env.VITE_PRODUCTION_BASE_URL}${
                      data?.insurance?.tariff?.insurance_company?.logo
                    }`}
                    alt="insurance"
                  />
                  <Typography fontWeight="bold" mt={2}>
                    {data?.insurance?.tariff?.insurance_company?.title}
                  </Typography>
                </Box>
              ) : (
                <Typography>بیمه سفارش نداده اید!</Typography>
              )}
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography mb={1}>
              مبلغ :{" "}
              {Intl.NumberFormat("en").format(data?.insurance?.payed_cost)} ریال
            </Typography>{" "}
          </Item> */}

          <Item title="خدمات انتقال دانش:">
            <Box sx={{ width: { xs: "fit-content", sm: "300px" } }}>
              <Box>
                <Typography textAlign="left">
                  - مشاوره‌های فنی و توصیه‌های به زراعی به صورت مجازی، تلفنی و
                  حضوری
                  <br />
                  - پیش آگاهی آب و کشاورزی
                  <br />
                  - پایش ماهواره‌ای آفات و بیماری‌ها جهت افزایش عملکرد <br />
                  - توصیه فنی در حوزه افزایش بهره‌وری در آبیاری
                  <br />
                  - گزارشات فنی جهت مبارزه با سرمازدگی و خشکسالی در بستر
                  الکترونیک مناسب اجرای کشاورزی قراردادی
                  <br />
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography mb={1}>
              مبلغ :{" "}
              {Intl.NumberFormat("en").format(
                data?.knowledge_agreement?.payed_cost
              )}{" "}
              ریال
            </Typography>
          </Item>

          <Divider sx={{ my: 4 }} />

          <Item title="مجموع هزینه های قرارداد:">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                px: 4,
                maxWidth: "300px",
              }}
            >
              <Typography
                fontSize={18}
                fontWeight="bold"
                component="span"
                sx={{ color: "#01ADB7", mr: 2 }}
              >
                {Intl.NumberFormat("en").format(data?.contract?.total_cost)}
              </Typography>
              <Typography>ریال</Typography>
            </Box>
          </Item>

          <Divider sx={{ my: 4 }} />

          <Button
            disabled={isLoading}
            variant="contained"
            onClick={() => {
              postBankValidation(contractId)
                .unwrap()
                .then(() => {});
            }}
            sx={{ alignSelf: "flex-end" }}
          >
            {isLoading ? "در حال تایید..." : "تایید اطلاعات و اعتبارسنجی"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BankValidation;
