/* eslint-disable react/prop-types */
import CurrentWeatherInfo from "@/components/farm-management-modules/CurrentWeatherInfo";
import {
  FarmManagementStates,
  setFarmManageMentState,
} from "@/redux/slices/farmManagementSlice";
import {
  useGetFarmImageProcessQuery,
  usePostFarmIndexImageMutation,
  // useGetPolygonsQuery,
  // useLazyGetFarmsQuery,
} from "@/services/farms";
import { useGetWeatherInformationQuery } from "@/services/meteorology";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Layer, Map, Marker, Popup, Source } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import ElementsOntheMap from "../../../components/farm-management-modules/ElementsOntheMap";
import {
  useDeleteAdminContractMutation,
  useGetAdminContractByIdQuery,
  useGetAdminGeneralContractStateListQuery,
  useLazyGetContractSatplatQuery,
} from "@/services/contracts";
import {
  Check,
  Delete,
  DocumentScanner,
  DocumentScannerSharp,
  InfoOutlined,
  Search,
} from "@mui/icons-material";
import { useNavigate, useOutletContext } from "react-router-dom";
import ChartOnMap from "@/components/ChartOnMap";
import modalService from "@/components/common/Modal/modalService";
import DeleteContracts from "@/components/modals/DeleteContracts";
import MgmtContractPrices from "@/components/modals/MgmtContractPrices";
import SetStateModal from "@/components/modals/SetStateModal";
import { Roles } from "@/constants/global";

const Item = ({ title, value, my, valueColor = "#000" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        my,
      }}
    >
      {title && <Typography color="GrayText">{title}</Typography>}
      <Typography textAlign="left" color={valueColor}>
        {value}
      </Typography>
    </Box>
  );
};

const Card = ({
  item,
  onClick,
  selected,
  lastElementRef,
  updateContractSatplatId,
}) => {
  const navigate = useNavigate();
  const [getContractSatplat] = useLazyGetContractSatplatQuery();

  return (
    <Box
      ref={lastElementRef}
      onClick={onClick}
      sx={{
        border: "1px solid",
        position: "relative",
        borderColor: selected ? "green" : "gray",
        background: selected ? "#a4a4a454" : "",
        p: 1,
        borderRadius: 2,
        cursor: "pointer",
        mb: 1,
        direction: "ltr",
        "&:last-child": {
          mb: 0,
        },
      }}
    >
      {!item?.has_satplat_farm_id && (
        <Box
          sx={{
            position: "absolute",
            borderRadius: 2,
            inset: 0,
            background: "#551900ce",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              getContractSatplat(item?.id)
                .unwrap()
                .then(() => {
                  updateContractSatplatId(item);
                });
            }}
          >
            فعالسازی
          </Button>
        </Box>
      )}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography sx={{ textAlign: "center" }}>
          {item?.contract_number}
        </Typography>
      </Box>
      <Divider variant="middle" sx={{ mb: 1, mt: 1 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* <Item title="شناسه قرارداد:" value={item?.contract_number} /> */}
        <Item title="مساحت زمین:" value={`${item?.farm_info?.area} هکتار`} />
        <Item title="محصول:" value={item?.farm_info?.product} />
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color="GrayText">مباشر:</Typography>
          <Typography textAlign="center">
            {item?.cooperative_company?.cooperative_company_title}
          </Typography>
        </Box> */}
      </Box>
      <Divider variant="middle" sx={{ mb: 1, mt: 1 }} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          title="گزارشات"
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              `/technology-knowledge-transfer/reports/${item?.farm_info?.id}`
            );
          }}
        >
          <DocumentScanner fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

const TooltipContainer = styled(Box)`
  width: 120px;
  pointer-events: none;
  background-color: white;
  color: black;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -60px;
  direction: ltr;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`;

const PopupContent = ({ states, contractId, setSelectedContract }) => {
  const user = useSelector((state) => state.authentication.user);
  const isMainAdmin = user?.user_info?.type?.name === Roles.ADMIN;

  const [rowStates, setRowStates] = useState(null);
  const [deleteContract, { isLoading }] = useDeleteAdminContractMutation();
  const { data: popup, isLoading: isLoadingDetail } =
    useGetAdminContractByIdQuery(contractId, {
      skip: !contractId,
    });

  if (isLoadingDetail) {
    return <Typography>در حال بارگزاری...</Typography>;
  }

  return (
    <Popup
      style={{ width: 300 }}
      closeButton={false}
      onClose={() => setSelectedContract(null)}
      latitude={popup?.farm?.center?.coordinates?.[1] ?? 0}
      longitude={popup?.farm?.center?.coordinates?.[0] ?? 0}
    >
      <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
        <Typography mb={1.5} fontWeight="bold" textAlign="center">
          {popup?.profile?.first_name} {popup?.profile?.last_name}
        </Typography>
        <Item title="شناسه قرارداد:" value={popup?.contract_number} />
        <Item title="مساحت زمین:" value={`${popup?.farm?.area} هکتار`} my={1} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography color="GrayText">مباشر:</Typography>
          <Typography textAlign="center">
            {popup?.cooperative_company?.cooperative_company_title}
          </Typography>
        </Box>
        {popup?.state?.title === "waiting" && isMainAdmin ? (
          <Box
            sx={{
              display: "flex",
              // flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              value={rowStates}
              onChange={(e) => setRowStates(e.target.value)}
              select
              label="تعیین وضعیت"
              size="small"
              sx={{ width: 150, mt: 2 }}
            >
              {states.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
            <IconButton
              disabled={!rowStates}
              title="تایید وضعیت"
              onClick={() =>
                modalService.open(SetStateModal, {
                  state: rowStates,
                  contractId: popup?.id,
                  onSuccess: () => {
                    setSelectedContract(null);
                  },
                })
              }
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
            >
              <Check sx={{ color: "green" }} />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography color="GrayText">وضعیت:</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {popup?.state?.title === "denied" && (
                <Tooltip title={popup.denial_reason} arrow>
                  <InfoOutlined
                    sx={{ mr: 1, width: 16, height: 16, color: "red" }}
                  />
                </Tooltip>
              )}
              <Typography
                color={popup?.state?.title === "active" ? "green" : "red"}
              >
                {popup?.state?.label}
              </Typography>
            </Box>
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <IconButton
            variant="outlined"
            onClick={() => {
              modalService.open(MgmtContractPrices, {
                contractId: popup?.id,
                cooperativeTitle:
                  popup?.cooperative_company?.cooperative_company_title,
              });
            }}
          >
            <DocumentScannerSharp />
          </IconButton>
          {isMainAdmin && (
            <IconButton
              sx={{ alignSelf: "center" }}
              disabled={isLoading}
              onClick={() =>
                modalService.open(DeleteContracts, {
                  handleSubmit: (onClose) =>
                    deleteContract(popup?.id)
                      .unwrap()
                      .then(() => {
                        setSelectedContract(null);
                        onClose();
                      }),
                })
              }
            >
              <Delete fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Popup>
  );
};

const MapBox = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const minRange = {
  NDVI: -1,
  MSAVI: -1,
  NDRE: -1,
  NDWI: 0,
  CHL: 0,
  LAI: 0,
};

const SatelliteMonitoring = () => {
  const dispatch = useDispatch();
  const mapRef = useRef(null);

  const {
    currentSearch,
    setContracts,
    setPage,
    setCurrentSearch,
    contracts,
    lastElementRef,
    selectedFarm,
    setSelectedFarm,
  } = useOutletContext() ?? {};

  const selectedIndex = useSelector(
    (state) => state.farmManagement[FarmManagementStates.selectedIndex]
  );
  const selectedImageId = useSelector(
    (state) => state.farmManagement[FarmManagementStates.selectedImageId]
  );

  const [viewState, setViewState] = useState({
    longitude: 51.33725700771478,
    latitude: 35.70107661348783,
    zoom: 5,
  });
  const {
    data: weatherData,
    isError: isErrorWeatherData,
    isSuccess: isSuccessWeatherData,
    isLoading: isLoadingWeatherData,
  } = useGetWeatherInformationQuery(
    { farmId: selectedFarm?.farm_info?.id },
    { skip: !selectedFarm }
  );
  const { data: dates, isSuccess: isSuccessDates } =
    useGetFarmImageProcessQuery(selectedFarm?.farm_info?.id, {
      skip: !selectedFarm,
    });
  const [postFarmIndexImage, { data: indexImage }] =
    usePostFarmIndexImageMutation();

  const user = useSelector((state) => state.authentication.user);
  const isMainAdmin = user?.user_info?.type?.name === Roles.ADMIN;
  const [mouseCordinates, setMouseCordinates] = useState([51.389, 35.6892]);
  const [mousePixel, setMousePixel] = useState([0, 0]);
  const [ranges, setRanges] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);

  const flyTo = useCallback(() => {
    if (indexImage) {
      mapRef.current?.fitBounds(
        [
          [indexImage?.extent?.xmin, indexImage?.extent?.ymin],
          [indexImage?.extent?.xmax, indexImage?.extent?.ymax],
        ],
        { padding: 100, duration: 1000 }
      );
    }
  }, [indexImage]);

  useEffect(() => {
    if (selectedImageId) {
      postFarmIndexImage({
        imageId: selectedImageId,
        index: selectedIndex,
        farmId: selectedFarm?.farm_info?.id,
      });
    }
  }, [
    postFarmIndexImage,
    selectedFarm?.farm_info?.id,
    selectedImageId,
    selectedIndex,
  ]);

  useEffect(() => {
    if (isSuccessDates) {
      dispatch(
        setFarmManageMentState({
          [FarmManagementStates.selectedImageId]: dates[0]?.id,
        })
      );
    }
  }, [isSuccessDates, dates]);

  useEffect(() => {
    flyTo();
  }, [indexImage]);

  const tooltipLogic = useCallback(
    (mouseCordinates) => {
      if (indexImage) {
        if (
          mouseCordinates[0] > indexImage?.extent?.xmin &&
          mouseCordinates[0] < indexImage?.extent?.xmax
        ) {
          if (
            mouseCordinates[1] > indexImage?.extent?.ymin &&
            mouseCordinates[1] < indexImage?.extent?.ymax
          ) {
            for (const x in indexImage.json) {
              if (
                Math.abs(mouseCordinates[0] - x) <=
                indexImage.pixel_size / 2
              ) {
                for (const y in indexImage.json[x]) {
                  if (
                    Math.abs(mouseCordinates[1] - y) <=
                    indexImage.pixel_size / 2
                  ) {
                    return parseFloat(indexImage.json[x][y]).toFixed(4);
                  }
                }
              }
            }
          }
        }
      }
      // console.log('end');
    },
    [indexImage]
  );

  const tooltipUI = useMemo(() => {
    if (tooltipLogic) {
      const IndexValue = tooltipLogic(mouseCordinates);
      // console.log(IndexValue);

      if (IndexValue) {
        return (
          <TooltipContainer
            style={{
              position: "absolute",
              top: mousePixel[1] - 50 + "px",
              left: mousePixel[0] - 50 + "px",
              zIndex: 999,
              // visibility: IndexValue ? 'visible' : 'hidden',
            }}
          >
            {selectedIndex}: {IndexValue}
          </TooltipContainer>
        );
      }
    }
  }, [tooltipLogic, mousePixel, mouseCordinates, selectedIndex]);

  useEffect(() => {
    if (indexImage) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const temp = [...indexImage?.index_info?.ranges];
      temp.unshift(minRange[selectedIndex]);
      setRanges(temp);
    }
  }, [indexImage, selectedIndex]);

  const { data: states } = useGetAdminGeneralContractStateListQuery(
    {},
    { skip: !isMainAdmin }
  );

  return (
    <MapBox>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            display: { xs: "none", md: "initial" },
            width: "260px",
            borderRight: `1px solid gray`,
          }}
        >
          <Box sx={{ height: "80px", px: 2, pt: 2 }}>
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: <Search />,
              }}
              label="جستجو"
              placeholder="شناسه قرارداد/نام نام خانوادگی"
              value={currentSearch}
              onChange={(e) => {
                setContracts([]);
                setPage(1);
                setCurrentSearch(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ height: "calc(100% - 100px)", overflowY: "auto", px: 2 }}>
            {contracts?.map((item, index) => (
              <Card
                lastElementRef={
                  index === contracts?.length - 1 ? lastElementRef : null
                }
                updateContractSatplatId={(data) => {
                  const cpoiedContracts = [...contracts];
                  const foundeIndex = cpoiedContracts?.findIndex(
                    (ele) => ele?.id === data?.id
                  );
                  if (foundeIndex >= 0) {
                    cpoiedContracts[foundeIndex] = data;
                    setContracts(cpoiedContracts);
                  }
                }}
                selected={item?.farm_info?.id === selectedFarm?.farm_info?.id}
                key={item?.id}
                item={item}
                onClick={() => {
                  // mapRef?.current?.flyTo({
                  //   center: item?.farm_info?.center?.coordinates,
                  //   zoom: 16,
                  // });
                  // setPolygon(item?.farm?.polygon?.coordinates);
                  setSelectedFarm(item);
                }}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "calc(100% - 260px)" },
            height: "100%",
          }}
        >
          <Map
            ref={mapRef}
            onLoad={(map) =>
              map.target.fitBounds(
                map.target.getSource("polygons-source").tileBounds.bounds
              )
            }
            onMove={(evt) => setViewState(evt.viewState)}
            onMouseMove={(event) => {
              setMousePixel([event.point.x, event.point.y]);
              setMouseCordinates([event.lngLat.lng, event.lngLat.lat]);
            }}
            mapboxAccessToken={import.meta.env.VITE_MAP_TOKEN}
            style={{
              width: "100%",
              height: selectedFarm ? "calc(100% - 400px)" : "100%",
              position: "relative",
            }}
            mapStyle="mapbox://styles/amir790/ckxmvcic2o0jz15nsp93vt8ix"
            {...viewState}
          >
            {tooltipUI}
            {indexImage && (
              <Source
                id="map-source"
                type="image"
                url={`https://service.satplat.com${indexImage.index_info.png}`}
                coordinates={[
                  [indexImage.index_info.xmin, indexImage.index_info.ymax],
                  [indexImage.index_info.xmax, indexImage.index_info.ymax],
                  [indexImage.index_info.xmax, indexImage.index_info.ymin],
                  [indexImage.index_info.xmin, indexImage.index_info.ymin],
                ]}
              >
                <Layer
                  id="overlay"
                  source="map-source"
                  type="raster"
                  paint={{ "raster-opacity": 0.85 }}
                />
              </Source>
            )}
            {contracts?.map(
              (farm, index) =>
                farm?.farm_info?.center?.coordinates && (
                  <Marker
                    key={index}
                    latitude={farm?.farm_info?.center?.coordinates[1]}
                    longitude={farm?.farm_info?.center?.coordinates[0]}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setSelectedFarm(farm);
                      e.originalEvent.stopPropagation();
                      setSelectedContract(farm?.id);
                    }}
                  />
                )
            )}
            {selectedContract && (
              <PopupContent
                states={states}
                contractId={selectedContract}
                setSelectedContract={setSelectedContract}
              />
            )}
            {dates && (
              <ElementsOntheMap
                indexImage={indexImage}
                ranges={ranges}
                dates={dates}
              />
            )}
          </Map>
          <Box
            sx={{
              height: isSuccessWeatherData ? 100 : 0,
              borderTop: isSuccessWeatherData
                ? (theme) => `1px solid ${theme.palette.grey["100"]}`
                : "none",
            }}
          >
            {selectedFarm && (
              <>
                <CurrentWeatherInfo
                  data={weatherData?.currentConditions}
                  isLoadingWeatherData={isLoadingWeatherData}
                  isErrorWeatherData={isErrorWeatherData}
                  isSuccessWeatherData={isSuccessWeatherData}
                />
                <ChartOnMap selectedFarm={selectedFarm} />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </MapBox>
  );
};

export default SatelliteMonitoring;
