import { Box, useMediaQuery } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { Document, Page } from "react-pdf";
import { useMemo, useState } from "react";

const AcquireInputDetails = (props) => {
  const [numPages, setNumPages] = useState(1);
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const memoizedFile = useMemo(
    () => ({
      url: props?.pdf ?? "",
    }),
    [props]
  );

  return (
    <ModalComponent onClose={props?.onClose}>
      <Box
        sx={{
          p: 4,
          width: { sm: "1000px" },
          height: { xs: "500px", sm: "600px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowX: "hidden",
        }}
      >
        <Document
          file={{ ...memoizedFile }}
          onLoadError={(error) => console.log(error)}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={"در حال بارگزاری PDF ..."}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              loading="بارگزاری صفحات ..."
              width={matchesSm ? 800 : 300}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </Box>
    </ModalComponent>
  );
};

export default AcquireInputDetails;
