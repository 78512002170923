/* eslint-disable react/prop-types */
import { fieldDrawGif } from "@/assets";
import DragDropFile from "@/components/common/DragDropFile";
import AddPolygonTab from "@/components/contract-registration-modules/AddPolygonTab";
import Map from "@/components/contract-registration-modules/Map";
import { Delete } from "@mui/icons-material";
import { TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import calcArea from "@turf/area";
import {
  useDeclareFarmMutation,
  useGetProductListQuery,
  useValidatePolygonMutation,
} from "@/services/contracts";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Layer, Source } from "react-map-gl";
import bbox from "@turf/bbox";
import SelectProduct from "@/components/contract-registration-modules/SelectProduct";
import modalService from "@/components/common/Modal/modalService";
import SubmitAddFarm from "@/components/modals/SubmitAddFarm";
import { acquireInputsPath } from "@/constants/pagePath";
import useUserGeoLocation from "@/hooks/useUserGeoLocation";
import mapboxgl from "mapbox-gl";

const DeclareForm = ({ handleSubmit = () => {} }) => {
  const { data, isSuccess, isLoading } = useGetProductListQuery();
  const [state, setState] = useState({
    farmName: "",
    product: "",
  });

  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (isSuccess) {
      handleChange("product", data?.[0]);
    }
  }, [data, isSuccess]);

  return (
    <>
      <SelectProduct
        productLsit={data || []}
        loading={isLoading}
        value={state.product}
        handleChange={(e) => {
          handleChange("product", e.target.value);
        }}
      />
      {/* <TextField
        autoFocus
        label="نام زمین"
        placeholder="یک نام برای زمین خود تعیین کنید"
        size="small"
        sx={{ width: 250, my: 2 }}
        value={state.farmName}
        onChange={(e) => {
          handleChange("farmName", e.target.value);
        }}
      /> */}
      <Button
        sx={{ mt: 2 }}
        disabled={!state.product}
        onClick={() => handleSubmit(state)}
        variant="contained"
      >
        تایید
      </Button>
    </>
  );
};

const Draw = ({ polygon, handleSubmit = () => {} }) => {
  const hasPolygon = polygon.length > 0;

  return (
    <TabPanel value="1">
      <Typography
        fontWeight="bold"
        sx={{
          fontSize: "14px",
          textAlign: "center",
          width: 300,
          mb: 3,
        }}
      >
        {hasPolygon
          ? "در صورت اطمینان از شکل زمین خود و پس از تعیین نام، دکمه تایید را بفشارید. در غیر اینصورت میتوانید آن را ویرایش کنید."
          : "برای ثبت زمین به روش ترسیم پلیگون میتوانید از آموزش تصویری رو به رو بهره بگیرید."}
      </Typography>{" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {hasPolygon ? (
          <DeclareForm handleSubmit={handleSubmit} />
        ) : (
          <img
            style={{
              width: "300px",
              height: "300px",
              boxShadow: "0px 5px 10px #00000050",
              borderRadius: 4,
            }}
            src={fieldDrawGif}
          />
        )}
      </Box>
    </TabPanel>
  );
};

const Upload = ({
  file,
  setFile,
  handleSubmit = () => {},
  setFilePolygon,
  map,
  setArea,
}) => {
  const [validate] = useValidatePolygonMutation();

  return (
    <TabPanel value="2">
      {file ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Chip
            sx={{
              height: "auto",
              mb: 2,
              "& .MuiChip-deleteIcon": {
                ml: 1,
              },
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                padding: 0,
                mr: 2,
              },
            }}
            dir="ltr"
            deleteIcon={
              <IconButton>
                <Delete color="error" />
              </IconButton>
            }
            variant="outlined"
            label={file?.[0]?.name}
            onDelete={() => {
              map.current.getSource("polygon").map.removeSource("polygon");
              setArea(0);
              setFile();
              setFilePolygon();
            }}
          />
          <DeclareForm handleSubmit={handleSubmit} />
        </Box>
      ) : (
        <DragDropFile
          onChange={(e) => {
            const id = toast.loading("در حال بارگذاری فایل ...");
            //do something else

            const formData = new FormData();
            formData.append("file", e[0]);

            validate(formData)
              .unwrap()
              .then((data) => {
                if (data.polygon) {
                  toast.update(id, {
                    render: "فایل با موفقیت ترسیم شد!",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                  });
                  setFilePolygon(data.polygon);
                  setFile(e);
                } else {
                  toast.update(id, {
                    render: "فایل معتبر نیست!",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                  });
                }
              })
              .catch(() =>
                toast.update(id, {
                  render: "با مشکل مواجه شدید",
                  type: "error",
                  isLoading: false,
                  autoClose: 3000,
                })
              );
          }}
        />
      )}
    </TabPanel>
  );
};

const AddNewFarm = () => {
  const [file, setFile] = useState();
  const [value, setValue] = useState("1");
  const [polygon, setPolygon] = useState([]);
  const [filePolygon, setFilePolygon] = useState();
  const [area, setArea] = useState(0);
  const navigate = useNavigate();
  const [declareFarm, { isLoading }] = useDeclareFarmMutation();

  const contract = useSelector((state) => state.contractRegistraion);

  useEffect(() => {
    if (filePolygon) {
      const polygonBbox = bbox(filePolygon);
      map.current.fitBounds(polygonBbox, { padding: 50 });
      setArea(calcArea(filePolygon) / 10000);
    }
  }, [filePolygon]);

  const handlePolygonChange = useCallback((e) => {
    const newPolygon = e?.features?.[0];
    setPolygon(newPolygon?.geometry?.coordinates?.[0]);
    newPolygon && setArea(calcArea(newPolygon) / 10000);
  }, []);

  const map = useRef();
  const { getLocation, location } = useUserGeoLocation();

  // useEffect(() => {
  //   if (location) {
  //     map.current?.flyTo({
  //       center: [location?.longitude, location?.latitude],
  //       zoom: 10,
  //     });
  //   }
  // }, [location]);

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Paper
        sx={{
          width: { xs: "80%", md: "90%" },
          maxWidth: "1100px",
          padding: { xs: "15px 30px", md: "25px 42px" },
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          background: "#F4F6F8",
          height: "fit-content",
          position: "relative",
        }}
      >
        {/* <Box
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            startIcon={<ArrowForward />}
            onClick={() => dispatch(updateContractState({ pahne: "" }))}
          >
            <Typography>بازگشت به انتخاب زمین</Typography>
          </Button>
        </Box> */}
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            ثبت زمین کشاورزی
          </Typography>
          <Typography sx={{ fontSize: "14px", mt: "14px" }}>
            کاربر گرامی لطفا زمین مورد نظر خود را به یکی از روش ها ثبت کنید.
          </Typography>
          {/* <Typography sx={{ fontSize: "14px", mt: "14px", fontWeight: "bold" }}>
            آدرس زمین کشاورزی: ایلام، آبدانان، چشمه شیرین رشنو، بالا محله
          </Typography> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: { xs: "column-reverse", md: "row" },
            pt: 2,
            height: { xs: 1000, md: 500 },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "calc(100% - 400px)" },
              height: "100%",
              pr: 4,
            }}
          >
            <Map
              onLoad={(e) => {
                e?.target?.addControl(
                  new mapboxgl.GeolocateControl({
                    positionOptions: {
                      enableHighAccuracy: true,
                    },
                    // When active the map will receive updates to the device's location as it changes.
                    trackUserLocation: true,
                    // Draw an arrow next to the location dot to indicate which direction the device is heading.
                    showUserHeading: true,
                  })
                );
              }}
              ref={map}
              onUpdate={(e) => {
                handlePolygonChange(e);
                setValue("1");
              }}
              onCreate={(e) => {
                handlePolygonChange(e);
                setValue("1");
              }}
              onDelete={() => {
                setPolygon([]);
                setArea(0);
              }}
            >
              {filePolygon && (
                <Source
                  id="polygon"
                  {...{
                    type: "geojson",
                    data: {
                      type: "Feature",
                      geometry: filePolygon,
                    },
                  }}
                >
                  <Layer
                    {...{
                      id: "polygon",
                      type: "fill",
                      source: "polygon", // reference the data source
                      layout: {},
                      paint: {
                        "fill-color": "#0080ff", // blue color fill
                        "fill-opacity": 0.5,
                      },
                    }}
                  />
                </Source>
              )}
              <Box
                sx={{
                  px: 2,
                  py: 1,
                  background: "#fff",
                  width: "fit-content",
                  mt: 2,
                  borderRadius: 2,
                  position: "absolute",
                  top: -5,
                  right: 5,
                  boxShadow: "0px 5px 10px #00000050",
                }}
              >
                {/* <Typography sx={{ fontSize: "14px" }}>
                  مساحت کشت قراردادی:{" "}
                  <Typography component="span" color="green" fontWeight="bold">
                    {contract?.pahne?.total_area} هکتار
                  </Typography>{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px", my: 1 }}>
                  مساحت باقیمانده:{" "}
                  <Typography
                    component="span"
                    color={
                      (contract?.pahne?.left_area - area).toFixed(2) >
                        contract?.pahne?.total_area ||
                      (contract?.pahne?.left_area - area).toFixed(2) < 0
                        ? "error"
                        : "green"
                    }
                    fontWeight="bold"
                  >
                    <Typography component="span" dir="ltr" fontWeight="bold">
                      {(contract?.pahne?.left_area - area).toFixed(2)}
                    </Typography>{" "}
                    هکتار
                  </Typography>{" "}
                </Typography> */}
                <Typography sx={{ fontSize: "14px", mb: 1 }}>
                  مساحت زمین:{" "}
                  <Typography component="span" color="green" fontWeight="bold">
                    {area.toFixed(2)} هکتار
                  </Typography>{" "}
                </Typography>
              </Box>
            </Map>
          </Box>

          <AddPolygonTab value={value} setValue={setValue}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Draw
                polygon={polygon}
                handleSubmit={(state) => {
                  modalService.open(SubmitAddFarm, {
                    addingLoading: isLoading,
                    handleSubmit: (onClose) => {
                      declareFarm({
                        contractId: contract.contractId,
                        data: {
                          product: state.product.id,
                          polygon: {
                            type: "polygon",
                            coordinates: [polygon],
                          },
                        },
                      })
                        .unwrap()
                        .then(() => {
                          toast.success("زمین با موفقیت ثبت شد");
                          onClose();
                        });
                    },
                  });
                }}
              />
              <Upload
                map={map}
                setArea={setArea}
                file={file}
                setFile={setFile}
                setFilePolygon={setFilePolygon}
                handleSubmit={(state) => {
                  modalService.open(SubmitAddFarm, {
                    addingLoading: isLoading,
                    handleSubmit: (onClose) => {
                      declareFarm({
                        contractId: contract.contractId,
                        data: {
                          product: state.product.id,
                          polygon: {
                            type: "polygon",
                            coordinates: [filePolygon],
                          },
                        },
                      })
                        .unwrap()
                        .then(() => {
                          toast.success("زمین با موفقیت ثبت شد");
                          onClose();
                          navigate(acquireInputsPath);
                        });
                    },
                  });
                }}
              />
            </Box>
          </AddPolygonTab>
        </Box>
      </Paper>
    </Box>
  );
};

const FarmDeclarion = () => {
  return <AddNewFarm />;
};

export default FarmDeclarion;
