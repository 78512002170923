/* eslint-disable react/prop-types */
import styled from "@emotion/styled";
import { UploadFile } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const DragDropFile = ({ onChange }) => {
  const inputRef = useRef();
  const hiddenInputRef = useRef();

  const onUpload = (files) => {
    onChange(files);
  };

  useEffect(() => {
    inputRef?.current?.addEventListener("dragover", handleDragOver);
    inputRef?.current?.addEventListener("drop", handleDrop);

    return () => {
      inputRef?.current?.removeEventListener("dragover", handleDragOver);
      inputRef?.current?.removeEventListener("drop", handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      onUpload(files);
    }
  };

  return (
    <Box
      ref={inputRef}
      onClick={() => hiddenInputRef && hiddenInputRef.current.click()}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "250px",
        width: "250px",
        border: "1px dashed #000",
        borderRadius: 4,
        cursor: "pointer",
      }}
    >
      <Button
        onClick={(e) => e.stopPropagation()}
        component='label'
        startIcon={<UploadFile />}
        sx={{ mb: 2 }}
        disableTouchRipple
        disableFocusRipple
        disableElevation
      >
        بارگزاری فایل
        <VisuallyHiddenInput
          ref={hiddenInputRef}
          type='file'
          onChange={(e) => onChange(e.target.files)}
        />
      </Button>
      <Typography fontSize='14px' fontWeight='bold'>
        فایل خود را اینجا بکشید
      </Typography>
    </Box>
  );
};

export default DragDropFile;
