/* eslint-disable react/prop-types */
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Assignment,
  DescriptionOutlined,
  DocumentScanner,
  DownloadRounded,
  InfoOutlined,
  ShoppingBag,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import Error from "../common/Error";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateContractState } from "@/redux/slices/contractRegistraionSlice";
import modalService from "../common/Modal/modalService";
import ContractPrices from "../modals/ContractPrices";
import FarmerSaleProduct from "../modals/FarmerSaleProduct";

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  font-size: 16px;
`;

const TableLoading = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        sx={{
          position: "relative",
          height: 500,
        }}
      >
        <CircularProgress sx={{ position: "absolute", left: "50%" }} />
      </TableCell>
    </TableRow>
  );
};

const EmptyTable = () => {
  return (
    <TableRow>
      <TableCell
        colSpan={12}
        sx={{
          position: "relative",
          height: 500,
        }}
      >
        <Box
          sx={{
            height: "200px",
            width: "200px",
            position: "absolute",
            left: "calc(50% - 100px)",
            top: "calc(50% - 100px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DescriptionOutlined sx={{ width: "100px", height: "100px" }} />
          <Typography fontWeight="bold" sx={{ mt: 2 }}>
            هیچ قراردادی یافت نشد!
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const Data = ({ rows, rowsPerPage, page }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return rows.map((row, index) => (
    <TableRow
      key={row?.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-of-type(odd)": {
          background: "#F4F6F8",
        },
      }}
    >
      <TableCell
        sx={{ background: "#00581558" }}
        align="center"
        component="th"
        scope="row"
      >
        {page * rowsPerPage + index + 1}
      </TableCell>
      <TableCell align="center">{row?.farm_info?.title ?? "-"}</TableCell>
      <TableCell align="center">{row?.contract_number ?? "-"}</TableCell>
      <TableCell align="center">
        {row?.start_date
          ? Intl.DateTimeFormat("fa-IR-u-nu-latn", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            }).format(new Date(row?.start_date))
          : "-"}
      </TableCell>
      <TableCell align="center">
        {row?.end_date
          ? Intl.DateTimeFormat("fa-IR-u-nu-latn", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            }).format(new Date(row?.end_date))
          : "-"}
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row?.state.title === "denied" && (
            <Tooltip title={row?.denial_reason} arrow>
              <InfoOutlined sx={{ mr: 1, width: 16, height: 16 }} />
            </Tooltip>
          )}
          <Typography component="p" variant="button">
            {row?.state?.label ?? "-"}
          </Typography>
        </Box>
        {/* {row?.state.title === "denied" && (
          <Typography component='p' variant='caption'>
            در مرحله{" "}
            <Typography component='span' variant='caption'>
              {row?.stage.label}
            </Typography>
          </Typography>
        )} */}
        {row?.state.title === "not_completed" && (
          <Button
            startIcon={<Assignment />}
            sx={{ mt: 1 }}
            size="small"
            variant="outlined"
            onClick={() => {
              dispatch(updateContractState({ contractId: row?.id }));
              navigate("/farmer/contract-registration");
            }}
          >
            تکمیل فرآیند
          </Button>
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          disabled={!row?.contract_number}
          onClick={() => {
            navigate(`${row?.id}`);
          }}
        >
          <DownloadRounded />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <IconButton
          disabled={row?.stage?.id < 6}
          onClick={() =>
            modalService.open(ContractPrices, { contractId: row?.id })
          }
        >
          <DocumentScanner />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <IconButton
          disabled={!row?.purchase}
          onClick={() =>
            modalService.open(FarmerSaleProduct, {
              data: row,
              enableAction: row?.purchase?.state === "waiting",
            })
          }
        >
          <ShoppingBag />
        </IconButton>
      </TableCell>
    </TableRow>
  ));
};

const Table = ({
  data = [],
  isLoading,
  isSuccess,
  isFetching,
  rowsPerPage,
  page,
}) => {
  const Body = () => {
    if (isLoading || isFetching) {
      return <TableLoading />;
    } else if (isSuccess) {
      if (data.length > 0) {
        return <Data rows={data} rowsPerPage={rowsPerPage} page={page} />;
      } else {
        return <EmptyTable />;
      }
    } else {
      return <Error />;
    }
  };

  return (
    <TableContainer component={Box}>
      <MuiTable sx={{ minWidth: 650 }} size="medium" aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: "#00581558" }}>
            <TableCell
              align="center"
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              ردیف
            </TableCell>
            <StyledTableCell align="center">نام زمین</StyledTableCell>
            <StyledTableCell align="center">شناسه قرارداد</StyledTableCell>
            <StyledTableCell align="center">تاریخ شروع قرارداد</StyledTableCell>
            <StyledTableCell align="center">
              تاریخ پایان قرارداد
            </StyledTableCell>
            <StyledTableCell align="center">وضعیت</StyledTableCell>
            <StyledTableCell align="center">دریافت قرارداد</StyledTableCell>
            <StyledTableCell align="center">هزینه ها</StyledTableCell>
            <StyledTableCell align="center">فروش محصول</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Body />
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
