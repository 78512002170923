/* eslint-disable react/prop-types */
import { Modal, Paper } from "@mui/material";

const ModalComponent = (props) => {
  return (
    <Modal
      open
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      {...props}
    >
      <Paper
        sx={{
          borderRadius: "10px",
          overflow: "auto",
          outline: 0,
          ...props?.paperProps?.sx,
        }}
        {...props.paperProps}
      >
        {props.children}
      </Paper>
    </Modal>
  );
};

export default ModalComponent;
