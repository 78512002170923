import {
  knowledgeTransferImg,
  lineChart,
  management,
  report,
  satellite,
  statistics,
} from "@/assets";
import GuideCard from "@/components/contract-registration-modules/GuideCard";
import {
  useGetKnowledgeValidationQuery,
  usePostKnowledgeValidationMutation,
} from "@/services/contracts";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ITEMS = [
  {
    title: "مشاوره‌های فنی و توصیه‌های به زراعی به صورت مجازی، تلفنی و حضوری",
    src: management,
  },
  { title: "پیش آگاهی آب و کشاورزی", src: satellite },
  {
    title: "پایش ماهواره‌ای آفات و بیماری‌ها جهت افزایش عملکرد",
    src: lineChart,
  },
  {
    title: "توصیه فنی در حوزه افزایش بهره‌وری در آبیاری",
    src: statistics,
  },
  {
    title:
      "گزارشات فنی جهت مبارزه با سرمازدگی و خشکسالی در بستر الکترونیک مناسب اجرای کشاورزی قراردادی",
    src: report,
  },
  // {
  //   title: "تخمین عملکرد دوره کشت محصول",
  //   src: commodity,
  // },
  // {
  //   title: "بررسی سری زمانی مزرعه",
  //   src: mean,
  // },

  // {
  //   title: "پیش بینی هواشناسی روزانه و هفتگی",
  //   src: weatherForecast,
  // },
];

const KnowledgeTransfer = () => {
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const { data } = useGetKnowledgeValidationQuery(contractId, {
    skip: !contractId,
  });
  const [postKnowledgeValidation, { isLoading }] =
    usePostKnowledgeValidationMutation();

  return (
    <Box
      sx={{
        minHeight: 500,
        padding: "30px 36px",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
      }}
    >
      <Box>
        <GuideCard
          src={knowledgeTransferImg}
          title="خدماتی که در بخش انتقال دانش به من داده میشود چیست؟"
        >
          <Typography textAlign="justify">
            خدماتی کاملاً خودکار که با استفاده از هوش مصنوعی، برای پردازش تصاویر
            ماهواره‌ای و هوایی برای جنبه‌های مختلف منابع‌طبیعی عرضه می‌کنیم. این
            خدمات، شامل توصیه‌های مختلفی از جمله موارد زیر است:{" "}
          </Typography>
          <Typography textAlign="justify">
            1- کاشت، داشت، برداشت و تناسب زراعی برای کشاورزان
          </Typography>
          <Typography textAlign="justify">
            2- میزان بهره‌برداری از مراتع
          </Typography>
          <Typography textAlign="justify">3- مسائل حقوقی و کشف تقلب</Typography>
          <Typography textAlign="justify">
            4- ارائه تحلیل‌های اقتصادی و ریسک
          </Typography>
          <Typography textAlign="justify">
            5- پایش و ارزیابی خسارت بلایای طبیعی و بسیاری موارد دیگر
          </Typography>
        </GuideCard>
      </Box>
      <Box
        sx={{
          mt: { xs: 6, md: 0 },
          ml: { xs: 0, md: "44px" },
          width: { xs: "fit-content", md: "600px" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography fontWeight="bold" fontSize="20px">
          انتقال دانش
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "45px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography fontWeight="bold">
            هزینه دریافتی بابت انتقال دانش که در موارد زیر گفته شده :
          </Typography>
          <Typography
            sx={{
              mt: { xs: 2, md: 0 },
              background: "#fff",
              p: 1,
              height: "fit-content",
              width: "fit-content",
            }}
          >
            <Typography
              component="span"
              fontWeight="bold"
              fontSize="16px"
              sx={{ color: "#01ADB7", mr: 1 }}
            >
              {Intl.NumberFormat("en").format(data?.payable_cost)}
            </Typography>
            ریال
          </Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          {ITEMS.map((item, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <img style={{ marginLeft: 18 }} src={item.src} alt="" />
              <Typography fontWeight="bold">{item.title}</Typography>
            </Box>
          ))}
        </Box>
        <Button
          disabled={isLoading}
          onClick={() => {
            postKnowledgeValidation(contractId).unwrap();
          }}
          sx={{ width: "160px", alignSelf: { xs: "center", md: "flex-end" } }}
          variant="contained"
        >
          {isLoading ? "درحال تایید..." : "تایید دریافت خدمات"}
        </Button>
      </Box>
    </Box>
  );
};

export default KnowledgeTransfer;
