/* eslint-disable react/prop-types */
import { Box, CircularProgress, useTheme } from "@mui/material";

const Loading = ({ color }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        style={{ color: color ?? theme.palette.primary.main }}
      />
    </Box>
  );
};

export default Loading;
