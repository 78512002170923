import Card from "@/components/recieve-input-modules/Card";
import CartDrawer from "@/components/recieve-input-modules/CartDrawer";
import { TYPE_TRANSLATOR } from "@/constants/global";
import {
  useGetCompanyProviderBriefQuery,
  useGetCompanyProviderInputsQuery,
  useGetInputTypesBriefQuery,
  useGetInputValidationQuery,
  useGetProductListQuery,
  usePostInputValidationMutation,
} from "@/services/contracts";
import { ShoppingCart } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";

import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

const AcquireInputVerification = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterStates, setFilterStates] = useState({
    input_type: "",
    provider: "",
    product: "",
  });
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const [page, setPage] = useState(1);
  const { data: inputTypesList } = useGetInputTypesBriefQuery();
  const [PostInputValidation, { isLoading: postInputValidationIsLoading }] =
    usePostInputValidationMutation();
  const { data: companyProviderInputs } = useGetCompanyProviderInputsQuery({
    page,
    product__id: filterStates.product?.id,
    input_type: filterStates.input_type?.id,
    provider: filterStates.provider?.id,
    page_size: 12,
  });
  const { data: companyProviderBrief } = useGetCompanyProviderBriefQuery({
    input_type_title: filterStates.input_type?.title,
  });
  const { data: inputValidationData } = useGetInputValidationQuery(contractId, {
    skip: !contractId,
  });

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const getMaxObtain = useCallback(
    (type) => {
      const foundItem = inputValidationData?.max_obtains?.find(
        (ele) => ele?.title === type
      );
      return foundItem ? foundItem.obtainable : 0;
    },
    [inputValidationData]
  );

  const { data } = useGetProductListQuery();

  return (
    <Box
      sx={{
        minHeight: 500,
        padding: "30px 36px",
        diplay: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <TextField
            value={filterStates.product}
            onChange={(e) => {
              setPage(1);
              setFilterStates((prev) => ({
                ...prev,
                product: e.target.value,
              }));
            }}
            sx={{ width: 150 }}
            size="small"
            label="محصول"
            select
          >
            <MenuItem value="">بدون فیلتر</MenuItem>
            {data?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item?.title}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            value={filterStates.input_type}
            onChange={(e) => {
              setPage(1);
              setFilterStates((prev) => ({
                ...prev,
                provider: "",
                input_type: e.target.value,
              }));
            }}
            sx={{ width: 150, mx: { xs: 0, sm: 2 }, my: { xs: 2, sm: 0 } }}
            size="small"
            label="نوع نهاده"
            select
          >
            <MenuItem value="">بدون فیلتر</MenuItem>
            {inputTypesList?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {TYPE_TRANSLATOR?.[item?.title]?.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            value={filterStates.provider}
            onChange={(e) => {
              setPage(1);
              setFilterStates((state) => ({
                ...state,
                provider: e.target.value,
              }));
            }}
            sx={{ width: 180 }}
            size="small"
            label="شرکت ارائه دهنده"
            select
          >
            <MenuItem value="">بدون فیلتر</MenuItem>
            {companyProviderBrief?.map((item, index) => (
              <MenuItem key={index} value={item}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ width: 25, height: 25 }}
                    src={item?.logo}
                    alt=""
                  />
                  <Typography sx={{ ml: 1 }}>{item?.title}</Typography>
                </Box>
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <IconButton onClick={handleOpenDrawer}>
          <Badge
            invisible={inputValidationData?.input_selections?.length <= 0}
            badgeContent={inputValidationData?.input_selections?.length}
            color="primary"
          >
            <ShoppingCart />
          </Badge>
        </IconButton>
      </Box>

      <CartDrawer
        inputValidationData={inputValidationData}
        open={openDrawer}
        onClose={handleCloseDrawer}
      />

      {companyProviderInputs?.results?.length > 0 ? (
        <Grid container spacing={2} py={5}>
          {companyProviderInputs?.results?.map((item) => (
            <Grid
              key={item?.id}
              item
              container
              justifyContent="center"
              xl={3}
              md={4}
              sm={6}
              xs={12}
            >
              <Card item={item} max={getMaxObtain(item?.input_type?.title)} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            height: 400,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontWeight="bold" sx={{ textAlign: "center" }}>
            موردی یافت نشد
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {companyProviderInputs?.total_items > 0 && (
          <Pagination
            sx={{ alignSelf: "center" }}
            color="primary"
            page={page}
            count={companyProviderInputs?.total_pages ?? 0}
            onChange={(_e, pg) => setPage(pg)}
          />
        )}
        <Button
          sx={{
            width: 200,
            mt: 6,
            alignSelf: { xs: "center", sm: "flex-end" },
          }}
          variant="contained"
          disabled={postInputValidationIsLoading}
          onClick={() => {
            PostInputValidation(contractId).unwrap();
          }}
        >
          {postInputValidationIsLoading ? "درحال تایید..." : "تایید و ادامه"}
        </Button>
      </Box>
    </Box>
  );
};

export default AcquireInputVerification;
