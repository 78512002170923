/* eslint-disable react/prop-types */
import { DrawRounded, UploadFile } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";

const AddPolygonTab = ({ children, value, setValue }) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        typography: "body1",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: { xs: "100%", md: "350px" },
        height: "500px",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab
              iconPosition='start'
              icon={<DrawRounded />}
              label='ترسیم پولیگان'
              value='1'
            />
            <Tab
              iconPosition='start'
              icon={<UploadFile />}
              label='آپلود فایل زمین'
              value='2'
            />
          </TabList>
        </Box>
        {children}
      </TabContext>
    </Box>
  );
};

export default AddPolygonTab;
