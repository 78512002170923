import { useState, useEffect, memo } from "react";
import modalService from "./modalService";

const ModalRoot = () => {
  const [modal, setModal] = useState({});

  /*
   * useEffect will run when the component renders, which might be more times than you think.
   * 2nd arg = If present, effect will only activate if the values in the list change.
   */
  useEffect(() => {
    modalService.on("open", ({ component, props }) => {
      setModal({
        component,
        props,
        onClose: () => {
          setModal({});
        },
      });
    });
  }, []);

  const ModalBody = modal.component ? modal.component : null;

  return (
    ModalBody && <ModalBody {...modal.props} open onClose={modal.onClose} />
  );
};

export default memo(ModalRoot);
