import { setLogoutState, setRefreshToken } from "@/redux/slices/authSlice";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

const isProduction = location.hostname === "curcp.com";
// const isStage = location.hostname === "stage.curcp.com";

export const baseUrl = import.meta.env[
  isProduction ? "VITE_PRODUCTION_BASE_URL" : "VITE_STAGE_BASE_URL"
];

// create a new mutex
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // add your token here
    const {
      authentication: { user },
    } = getState();
    if (user?.access_token) {
      headers.set("Authorization", `Bearer ${user?.access_token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(setRefreshToken(""));
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: "/api/accounts/api/v1/refresh-token/",
            method: "post",
            body: {
              refresh_token: api.getState().authentication?.user?.refresh_token,
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(setRefreshToken(refreshResult.data.access_token));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(setLogoutState());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const mainSplitApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "contract",
    "contractDelete",
    "input_selection",
    "contract-state",
    "Product",
    "ProductSales",
  ], // add your tag type here
  endpoints: () => ({}),
});
