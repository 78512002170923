import ContractDetailTemplate from "@/components/contract-registration-modules/ContractDetailTemplate";
import { useGetFinaliztionQuery } from "@/services/contracts";
import { Print } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";

const ContractDetail = () => {
  const params = useParams();

  // console.log(params);

  const { data } = useGetFinaliztionQuery(params?.id, {
    skip: !params?.id,
  });

  const print = () => {
    let printContents, popupWin;
    printContents = document.getElementById("print-section").innerHTML;
    popupWin = window.open("", "_blank");

    popupWin.document.open();
    popupWin.document.write(`
      <html dir="rtl">
        <head>
          <title>قرارداد شماره ${data?.contract_number}</title>
          <style>
            body{
              max-width:800px
            }
            @page {
              size: A4 portrait;
              margin:1.5cm;
            }
            #print-button{
              display:none;
            }
            @media print {
              #contract-title {
                break-inside: avoid;
              }
            }
          </style>
        </head>
    <body onload="window.print();window.close()">
      ${printContents}
    </body>
      </html>`);
    popupWin.document.close();
  };

  return (
    <Box
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 28,
        }}
      >
        <Button
          variant="contained"
          id="print-button"
          onClick={print}
          endIcon={<Print sx={{ mr: 1 }} />}
        >
          پرینت
        </Button>
      </div>
      <ContractDetailTemplate contractDetail={data} />
    </Box>
  );
};

export default ContractDetail;
