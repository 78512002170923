/* eslint-disable react/prop-types */
import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuiListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  AssignmentSharp,
  Circle,
  DashboardCustomize,
  DocumentScanner,
  ExitToApp,
  Layers,
  PersonOutline,
  Psychology,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { setLogoutState } from "@/redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Roles } from "@/constants/global";

const ListItem = ({
  icon,
  title,
  handleClick = () => {},
  expandIcon,
  sx,
  selected,
  disabled,
}) => {
  return (
    <MuiListItem disablePadding>
      <ListItemButton
        disabled={disabled}
        selected={selected}
        sx={sx}
        onClick={handleClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {expandIcon}
      </ListItemButton>
    </MuiListItem>
  );
};

const CollpaseList = ({ title, icon, selected, disabled, children }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        disabled={disabled}
        selected={selected}
        title={title}
        handleClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
        icon={icon}
        expandIcon={open ? <ExpandLess /> : <ExpandMore />}
      />
      <Collapse collapsedSize="40px" in={open} timeout="auto" unmountOnExit>
        <List sx={{ mt: 0.5 }} component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

const AdminSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.authentication.user);
  const isMainAdmin = user?.user_info?.type?.name === Roles.ADMIN;

  return (
    <>
      <ListItem
        title={"مدیریت قراردادها"}
        icon={<DashboardCustomize />}
        selected={location.pathname === "/admin/contracts-management"}
        handleClick={() => navigate("/admin/contracts-management")}
      />
      <ListItem
        title={"مدیریت نهاده ها"}
        icon={<DashboardCustomize />}
        selected={location.pathname === "/admin/acquires-management"}
        handleClick={() => navigate("/admin/acquires-management")}
      />
      <ListItem
        title={"مزارع تحت پوشش"}
        icon={<DashboardCustomize />}
        selected={location.pathname === "/admin"}
        handleClick={() => navigate("/admin")}
      />
      {isMainAdmin && (
        <ListItem
          title={"نرخ خرید محصولات"}
          icon={<DashboardCustomize />}
          selected={location.pathname === "/admin/products-prices"}
          handleClick={() => navigate("/admin/products-prices")}
        />
      )}
    </>
  );
};

const CompanyDirectSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <ListItem
        title={"مدیریت قراردادها"}
        icon={<DocumentScanner />}
        selected={location.pathname === "/company-direct/contracts-management"}
        handleClick={() => navigate("/company-direct/contracts-management")}
      />
      {/* <ListItem
        title={"مدیریت نهاده ها"}
        icon={<DashboardCustomize />}
        selected={location.pathname === "/company-direct/acquires-management"}
        handleClick={() => navigate("/company-direct/acquires-management")}
      /> */}
      {/*<ListItem
        title={"مزارع تحت پوشش"}
        icon={<Layers />}
        selected={location.pathname === "/company-direct"}
        handleClick={() => navigate("/company-direct")}
      />*/}
      {/* <ListItem
        title={"نرخ خرید محصولات"}
        icon={<DashboardCustomize />}
        selected={location.pathname === "/company-direct/product-prices"}
        handleClick={() => navigate("/company-direct/product-prices")}
      /> */}
      {/* <ListItem
        title={"انتقال دانش و فناوری"}
        icon={<DashboardCustomize />}
        // selected={location.pathname === adminCoveredFieldPath}
        // handleClick={() => navigate(adminCoveredFieldPath)}
      /> */}
    </>
  );
};

const FarmerSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <ListItem
        title={"داشبورد"}
        icon={<DashboardCustomize />}
        selected={location.pathname === "/farmer"}
        handleClick={() => navigate("/farmer")}
      />
      <CollpaseList
        selected={
          location.pathname.includes("/farmer/contract-registration") ||
          location.pathname.includes("/farmer/contracts")
        }
        title={"کشت قراردادی"}
        icon={<AssignmentSharp />}
      >
        <ListItem
          sx={{ pl: 4 }}
          title={"ثبت قرارداد"}
          icon={<Circle fontSize="12px" />}
          selected={location.pathname.includes("/farmer/contract-registration")}
          handleClick={() => {
            navigate(`/farmer/contract-registration`);
          }}
        />
        <ListItem
          sx={{ pl: 4 }}
          title={"قراردادها"}
          icon={<Circle fontSize="12px" />}
          selected={location.pathname.includes("contracts")}
          handleClick={() => navigate("/farmer/contracts")}
        />
      </CollpaseList>
      {/* <ListItem disabled title={"مراکز تحویل نهاده"} icon={<Store />} />
      <ListItem disabled title={"پیام ها"} icon={<Message />} /> */}
    </>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authentication.user);
  const isCompanyDirect = user?.user_info?.type?.name === Roles.COMPANY_DIRECT;
  const isMainAdmin = user?.user_info?.type?.name === Roles.ADMIN;
  const isAdmin =
    user?.user_info?.type?.name === Roles.ADMIN ||
    user?.user_info?.type?.name === Roles.STATE_ADMIN ||
    user?.user_info?.type?.name === Roles.SUPERVISOR_ADMIN;
  const isFarmer = user?.user_info?.type?.name === Roles.FARMER;

  return (
    <List
      sx={{
        width: "100%",
        height: "calc(100% - 16px)",
        display: "flex",
        flexDirection: "column",
      }}
      component="div"
      aria-labelledby="nested-list-subheader"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: "0 1 150px",
        }}
      >
        <ListItem
          // handleClick={() => navigate(dashboardPath)}
          icon={<PersonOutline sx={{ color: "#fff" }} />}
          title={user?.user_info?.full_name ?? user?.phone_number}
          sx={{
            backgroundColor: "#005815",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#01711b",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
        }}
      >
        {isAdmin && <AdminSideBar />}
        {isCompanyDirect && <CompanyDirectSideBar />}
        {isFarmer && <FarmerSideBar />}
        {/* {(isFarmer || isMainAdmin || isCompanyDirect) && (
          <CollpaseList
            selected={
              location.pathname.includes(
                "/technology-knowledge-transfer/satellite-monitoring"
              ) ||
              location.pathname.includes(
                "/technology-knowledge-transfer/meteorology"
              ) ||
              location.pathname.includes(
                "/technology-knowledge-transfer/reports"
              )
            }
            title="انتقال دانش و فناوری نوین"
            icon={<Psychology />}
          >
            <ListItem
              sx={{ pl: 4 }}
              title="پایش ماهواره ای"
              icon={<Circle fontSize="12px" />}
              selected={
                location.pathname.includes(
                  "/technology-knowledge-transfer/satellite-monitoring"
                ) ||
                location.pathname.includes(
                  "/technology-knowledge-transfer/reports"
                )
              }
              handleClick={() =>
                navigate("/technology-knowledge-transfer/satellite-monitoring")
              }
            />
            <ListItem
              sx={{ pl: 4 }}
              title="پشتیبانی فنی"
              icon={<Circle fontSize="12px" />}
              // selected={location.pathname.includes(contractsPath)}
              // handleClick={() => navigate(contractsPath)}
            />
            <ListItem
              sx={{ pl: 4 }}
              title="مدیریت مزرعه"
              icon={<Circle fontSize="12px" />}
              // selected={location.pathname.includes(contractsPath)}
              // handleClick={() => navigate(contractsPath)}
            />
            <ListItem
              sx={{ pl: 4 }}
              title="پیش آگاهی آب و کشاورزی"
              icon={<Circle fontSize="12px" />}
              selected={location.pathname.includes(
                "/technology-knowledge-transfer/meteorology"
              )}
              handleClick={() =>
                navigate(
                  `/technology-knowledge-transfer/meteorology/prediction`
                )
              }
            />
          </CollpaseList>
        )} */}
      </Box>
      <Box sx={{ display: "flex", flex: "0 1 40px" }}>
        <ListItem
          title={"خروج از حساب"}
          icon={<ExitToApp />}
          handleClick={() => dispatch(setLogoutState())}
        />
      </Box>
    </List>
  );
};

export default Sidebar;
