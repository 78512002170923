import { step1, step2, step3, step4, step5, step6 } from "@/assets";
import { updateContractState } from "@/redux/slices/contractRegistraionSlice";
import {
  useCreateContractMutation,
  useGetProductListQuery,
} from "@/services/contracts";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import _ from "lodash";
import HandleServerData from "@/components/common/HandleServerData";
import { Autoplay, Pagination } from "swiper";
import { AddBox } from "@mui/icons-material";
import { productImage } from "@/constants/productImages";

const STEPS = [step1, step2, step3, step4, step5, step6];

const CreateContract = () => {
  const dispatch = useDispatch();
  const [createContract, { isLoading: createContractLoading }] =
    useCreateContractMutation();
  const { data, isSuccess, isLoading, isError } = useGetProductListQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        data: _.uniqBy(
          data?.map((item) => ({
            ...item,
            title: _.trim(item.title),
          })),
          "title"
        ),
      }),
    }
  );
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        mt: { xs: 1, md: 4 },
      }}
    >
      <Box>
        <Box sx={{ px: { xs: 4, md: 1 } }}>
          <Typography
            sx={{ mb: 1, textAlign: { xs: "center", md: "left" } }}
            fontWeight="bold"
          >
            کاربر محترم محصولاتی که شما می‌توانید برای آنها ثبت قرارداد انجام
            دهید در پایین نمایش داده شده است.
          </Typography>
          <Typography
            sx={{ textAlign: { xs: "center", md: "left" } }}
            variant="subtitle2"
          >
            اگر محصول موردنظر شما جز موارد زیر است، بر روی
            <Typography component="span" color="green" fontWeight="bolder">
              {" "}
              شروع فرآیند انعقاد قرارداد{" "}
            </Typography>
            کلیک نمایید.
          </Typography>{" "}
        </Box>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Swiper
            dir="rtl"
            style={{ height: 300, width: matches ? 800 : 300 }}
            slidesPerView={matches ? 3 : 1}
            centeredSlides
            spaceBetween={2}
            loop
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            pagination
            modules={[Autoplay, Pagination]}
          >
            <HandleServerData
              isLoading={isLoading}
              isError={isError}
              isSuccess={isSuccess}
            >
              {data?.map((item, index) => (
                <SwiperSlide
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  key={index}
                >
                  <Paper
                    sx={{
                      width: "250px",
                      height: "200px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      mx: 2,
                      "& img": {
                        transition: "all 0.25s ease",
                        mt: 2,
                      },
                      "&:hover img": {
                        transform: "scale(1.2)",
                      },
                    }}
                  >
                    <img
                      width={45}
                      src={productImage?.[item.title_en]}
                      alt={item.title}
                    />
                    <Typography mt={2} fontWeight="bold">
                      {item.title}
                    </Typography>
                  </Paper>
                </SwiperSlide>
              ))}
            </HandleServerData>
          </Swiper>
        </Box>
      </Box>

      <Button
        disabled={createContractLoading}
        sx={{ height: 45, width: 240, fontSize: "16px" }}
        variant="contained"
        endIcon={
          createContractLoading ? (
            <CircularProgress />
          ) : (
            <AddBox sx={{ width: "25px", height: "25px" }} />
          )
        }
        onClick={() => {
          createContract()
            .unwrap()
            .then((data) => {
              toast.success("قرارداد با موفقیت ایجاد شد!");
              dispatch(
                updateContractState({
                  balance: data?.total_cost,
                  stage: data?.stage,
                  contractId: data?.id,
                })
              );
            });
        }}
      >
        {isLoading ? "درحال ایجاد قرارداد ..." : "شروع فرآیند انعقاد قرارداد"}
      </Button>
      <Divider
        textAlign="center"
        variant="middle"
        flexItem
        sx={{
          width: "80%",
          alignSelf: "center",
          my: 4,
          height: "8px",
          background: "#188417",
          borderRadius: 4,
        }}
      />
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {STEPS.map((url, index) => (
          <Box key={index} sx={{ mx: 4 }}>
            <img src={url} alt="" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CreateContract;
