import styled from "@emotion/styled";
import { Call, Person } from "@mui/icons-material";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";

import FormikInput from "@/components/common/FormikInput/FormikInput";
import formikInputTypes from "@/constants/formikInputTypes";
import { useRegisterMutation } from "@/services/authentication";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Container = styled(Box)`
  padding: 5% 10%;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(FormikInput)`
  margin-bottom: 10%;
`;

const phoneRegExp = /^09\d{9}$/;
// eslint-disable-next-line no-control-regex
const englishNumber = /^[\x00-\x7F]*$/;

// eslint-disable-next-line react/prop-types
const RegisterForm = ({ goNextSection = () => {} }) => {
  const [register] = useRegisterMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Container>
      <Formik
        initialValues={{ phoneNumber: "", nationalId: "" }}
        validationSchema={yup.object({
          phoneNumber: yup
            .string()
            .matches(englishNumber, "لطفا کیبورد خود را انگلیسی کنید")
            .matches(phoneRegExp, "شماره تماس معتبر نیست")
            .required("شماره تماس خود را وارد کنید"),
          nationalId: yup
            .string()
            .matches(englishNumber, "لطفا کیبورد خود را انگلیسی کنید")
            .required("کد ملی خود را وارد کنید"),
        })}
        onSubmit={(values) => {
          if (!executeRecaptcha) {
            toast.error("کپچا هنوز دسترس نیست!");
            return;
          }
          executeRecaptcha("validate_captcha").then((gReCaptchaToken) => {
            register({ ...values, captcha: gReCaptchaToken })
              .unwrap()
              .then(() => {
                goNextSection();
                toast.success("کد با موفقیت ارسال شد!");
                sessionStorage.setItem("phone", values.phoneNumber);
                sessionStorage.setItem("nationalId", values.nationalId);
              });
          });
        }}
      >
        <StyledForm>
          <Typography
            fontWeight='bold'
            sx={{
              mt: 2,
              mb: 4,
              color: "red",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            کدملی و شماره تماس باید متعلق به یک فرد باشد.
          </Typography>
          <StyledInput
            size='small'
            sx={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Person />
                </InputAdornment>
              ),
            }}
            fullWidth
            inputType={formikInputTypes.TEXT_FIELD}
            label='کد ملی'
            name='nationalId'
          />
          <StyledInput
            sx={{ width: "100%" }}
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Call />
                </InputAdornment>
              ),
            }}
            fullWidth
            inputType={formikInputTypes.TEXT_FIELD}
            label='شماره تماس'
            name='phoneNumber'
          />
          <Button
            variant='contained'
            type='submit'
            sx={{ marginTop: 4, borderRadius: 2 }}
          >
            ثبت نام
          </Button>
        </StyledForm>
      </Formik>
    </Container>
  );
};

export default RegisterForm;
