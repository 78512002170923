import { Add, HorizontalRule } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";

const Counter = ({ title, counter, setCounter, max }) => {
  return (
    <Box>
      {/* {title && (
        <Typography fontSize={14} mb={1} textAlign="center">
          {title}
        </Typography>
      )} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #00000050",
          borderRadius: 1.5,
        }}
      >
        <IconButton
          disabled={counter >= max}
          onClick={() => {
            const newCounter = counter;
            if (max && newCounter >= max) {
              setCounter(newCounter);
            } else {
              setCounter(newCounter + 1);
            }
          }}
        >
          <Add />
        </IconButton>
        <TextField
          inputProps={{ style: { textAlign: "center", fontSize: 18 } }}
          size="small"
          value={counter}
          onChange={(e) => setCounter(e.target.value)}
          type="number"
        />
        <IconButton
          disabled={counter <= 1}
          onClick={() => {
            const newCounter = counter;
            if (newCounter > 1) {
              setCounter(newCounter - 1);
            } else {
              setCounter(newCounter);
            }
          }}
        >
          <HorizontalRule />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Counter;
