import styled from "@emotion/styled";
import { Call } from "@mui/icons-material";
import { Box, Button, InputAdornment } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";

import FormikInput from "@/components/common/FormikInput/FormikInput";
import formikInputTypes from "@/constants/formikInputTypes";
import { useLoginMutation } from "@/services/authentication";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";

const Container = styled(Box)`
  padding: 5% 10%;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(FormikInput)`
  /* margin-bottom: 10%; */
`;

const phoneRegExp = /^09\d{9}$/;
// eslint-disable-next-line no-control-regex
const englishNumber = /^[\x00-\x7F]*$/;

// eslint-disable-next-line react/prop-types
const LoginForm = ({ goNextSection = () => {} }) => {
  const [login] = useLoginMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Container>
      <Formik
        initialValues={{ phoneNumber: "" }}
        validationSchema={yup.object({
          phoneNumber: yup
            .string()
            .matches(englishNumber, "لطفا کیبورد خود را انگلیسی کنید")
            .matches(phoneRegExp, "شماره تماس معتبر نیست")
            .required("شماره تماس خود را وارد کنید"),
        })}
        onSubmit={(values) => {
          if (!executeRecaptcha) {
            toast.error("کپچا هنوز دسترس نیست!");
            return;
          }
          executeRecaptcha("validate_captcha").then((gReCaptchaToken) => {
            login({ phoneNumber: values.phoneNumber, captcha: gReCaptchaToken })
              .unwrap()
              .then(() => {
                goNextSection();
                sessionStorage.setItem("phone", values.phoneNumber);
              });
          });
        }}
      >
        <StyledForm>
          <StyledInput
            sx={{ width: "100%" }}
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Call />
                </InputAdornment>
              ),
            }}
            fullWidth
            inputType={formikInputTypes.TEXT_FIELD}
            label='شماره تماس'
            name='phoneNumber'
          />
          <Button
            // disabled={!captchaKey}
            variant='contained'
            type='submit'
            sx={{ marginTop: 4, borderRadius: 2 }}
          >
            ورود
          </Button>
        </StyledForm>
      </Formik>
      {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <GoogleReCaptcha
          onVerify={(token) => setCaptchaKey(token)}
          refreshReCaptcha={refreshReCaptcha}
        />
      </Box> */}
    </Container>
  );
};

export default LoginForm;
