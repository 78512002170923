/* eslint-disable react/prop-types */
import { Box, Button, Typography } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";

const SubmitAddFarm = (props) => {
  return (
    <ModalComponent open={props.open}>
      <Box
        sx={{
          p: 4,
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box textAlign="center">
          <Typography>آیا از درستی پلیگون زمین خود اطمینان دارید؟</Typography>
          <Typography sx={{ my: 0.8 }} color="red" fontWeight="bold">
            (تایید به منزله ثبت شدن زمین است و امکان بازگشت وجود ندارد.)
          </Typography>
          <Typography> در غیر این صورت آن را ویرایش کنید.</Typography>
        </Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <Button
            disabled={props?.addingLoading}
            sx={{ mr: 1 }}
            onClick={() => props?.handleSubmit(props?.onClose)}
            variant="contained"
          >
            {props?.addingLoading ? "در حال ثبت زمین..." : "ثبت زمین"}
          </Button>
          <Button sx={{ ml: 1 }} onClick={props?.onClose} variant="outlined">
            ویرایش
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
};

export default SubmitAddFarm;
