/* eslint-disable react/prop-types */
import MapGL, {
  Marker,
  NavigationControl,
  Popup,
  GeolocateControl,
} from "react-map-gl";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import {
  useGetAdminCentroidsQuery,
  useGetAdminContractByIdQuery,
  useGetAdminGeneralContractStateListQuery,
  useGetInputTypesBriefQuery,
} from "@/services/contracts";
import modalService from "@/components/common/Modal/modalService";
import MgmtContractPrices from "@/components/modals/MgmtContractPrices";
import Polygon from "@/components/Polygon";
import SetStateModal from "@/components/modals/SetStateModal";
import { useGetProductsTitlesQuery } from "@/services/products";
import { TYPE_TRANSLATOR } from "@/constants/global";

const Item = ({ title, value, my, valueColor = "#000" }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my }}>
      {title && <Typography color="GrayText">{title}</Typography>}
      <Typography color={valueColor}>{value}</Typography>
    </Box>
  );
};

const PopupContent = ({ states, contractId, setSelectedContract }) => {
  const [rowStates, setRowStates] = useState(null);

  const { data: popup, isLoading: isLoadingDetail } =
    useGetAdminContractByIdQuery(contractId, {
      skip: !contractId,
    });

  if (isLoadingDetail) {
    return <Typography>در حال بارگزاری...</Typography>;
  }
  return (
    <Popup
      style={{ width: 300 }}
      closeButton={false}
      onClose={() => setSelectedContract(null)}
      latitude={popup?.farm?.center?.coordinates?.[1] ?? 0}
      longitude={popup?.farm?.center?.coordinates?.[0] ?? 0}
    >
      <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
        <Typography mb={1.5} fontWeight="bold" textAlign="center">
          {popup?.profile?.first_name} {popup?.profile?.last_name}
        </Typography>
        <Item title="شناسه قرارداد:" value={popup?.contract_number} />
        <Item title="مساحت زمین:" value={`${popup?.farm?.area} هکتار`} my={1} />

        {popup?.state?.title === "waiting" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              value={rowStates}
              onChange={(e) => setRowStates(e.target.value)}
              select
              label="تعیین وضعیت"
              size="small"
              sx={{ width: 150, mt: 1 }}
            >
              {states.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
            <Button
              disabled={!rowStates}
              onClick={() =>
                modalService.open(SetStateModal, {
                  state: rowStates,
                  contractId: popup?.id,
                  onSuccess: () => {
                    setSelectedContract(null);
                  },
                })
              }
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
            >
              تایید
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography color="GrayText">وضعیت:</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {popup.state.title === "denied" && (
                <Tooltip title={popup.denial_reason} arrow>
                  <InfoOutlined
                    sx={{ mr: 1, width: 16, height: 16, color: "red" }}
                  />
                </Tooltip>
              )}
              <Typography
                color={popup?.state?.title === "active" ? "green" : "red"}
              >
                {popup?.state?.label}
              </Typography>
            </Box>
          </Box>
        )}
        <Button
          sx={{ my: 2 }}
          variant="outlined"
          onClick={() => {
            modalService.open(MgmtContractPrices, { contractId: popup?.id });
          }}
        >
          جزئیات قرارداد
        </Button>
      </Box>
    </Popup>
  );
};

const CompanyDirectCoveredFields = () => {
  const [selectedContract, setSelectedContract] = useState(null);
  const [requestState, setRequestState] = useState({
    input_title: "",
    state: "",
    cooperative_company: "",
    input_type: "",
  });

  const {
    data: centroids,
    isLoading,
    isFetching,
  } = useGetAdminCentroidsQuery(requestState);
  const { data: states } = useGetAdminGeneralContractStateListQuery();

  const [polygon, setPolygon] = useState(null);
  const mapRef = useRef();

  const { data: inputTitles } = useGetProductsTitlesQuery({
    input_type: requestState.input_type,
  });
  const { data: inputTypesList } = useGetInputTypesBriefQuery();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      {(isLoading || isFetching) && (
        <Box
          sx={{
            position: "absolute",
            background: "#0000008c",
            inset: 0,
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography sx={{ fontWeight: "bold", color: "#fff", mt: 4 }}>
            در حال بارگزاری...
          </Typography>
        </Box>
      )}

      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "absolute",
            zIndex: 999,
            background: "#ffffff",
            p: 4,
            left: 10,
            top: 10,
            borderRadius: 10,
            rowGap: 2,
          }}
        >
          {/* <TextField
            value={requestState.input_title}
            onChange={(e) =>
              setRequestState((prev) => {
                const copiedData = { ...prev };
                copiedData.input_title = e.target.value;
                handleDebounceRequest(copiedData);
                copiedData.page = 1;
                return copiedData;
              })
            }
            label="جستجو"
            size="small"
            placeholder="نام محصول"
          /> */}

          {/* <TextField
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  state: e.target.value,
                };
                newState.cooperative_company = "";
                if (e.target.value) {
                  getCompanyProvider({ state: e.target.value });
                }

                return newState;
              })
            }
            SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
            value={requestState.state}
            sx={{ width: 200 }}
            select
            size="small"
            label="استان"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {provinces?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </TextField> */}

          {/* <TextField
            disabled={provinces && !requestState.state}
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  cooperative_company: e.target.value,
                };

                return newState;
              })
            }
            SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
            value={requestState.cooperative_company}
            sx={{ width: 200 }}
            select
            size="small"
            label="مباشر"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {companies?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </TextField> */}
          <TextField
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  input_type: e.target.value,
                  input_title: "",
                };
                return newState;
              })
            }
            value={requestState.input_type}
            sx={{ width: 200 }}
            select
            size="small"
            label="نوع نهاده"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {inputTypesList?.map((item, index) => (
              <MenuItem value={item?.id} key={index}>
                {TYPE_TRANSLATOR?.[item?.title]?.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={!requestState.input_type}
            onChange={(e) =>
              setRequestState((prev) => {
                const newState = {
                  ...prev,
                  page: 1,
                  input_title: e.target.value,
                };

                return newState;
              })
            }
            SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
            value={requestState.input_title}
            sx={{ width: 200 }}
            select
            size="small"
            label="عنوان نهاده"
          >
            <MenuItem value={""}>بدون فیلتر</MenuItem>
            {inputTitles?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <MapGL
          ref={mapRef}
          initialViewState={{
            longitude: 51.33725700771478,
            latitude: 35.70107661348783,
            zoom: 5,
          }}
          mapboxAccessToken={import.meta.env.VITE_MAP_TOKEN}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 4,
            boxShadow: "0px 5px 10px #00000050",
            position: "relative",
          }}
          mapStyle="mapbox://styles/amir790/ckxmvcic2o0jz15nsp93vt8ix"
        >
          <NavigationControl position="bottom-right" />
          <GeolocateControl
            position="bottom-right"
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation
            showUserHeading
          />
          {centroids?.map((point) => (
            <Marker
              key={`${point?.contract_state?.title}-${point?.id}`}
              color={
                point?.contract_state?.title === "active"
                  ? "green"
                  : point?.contract_state?.title === "waiting"
                  ? "yellow"
                  : "red"
              }
              style={{ cursor: "pointer" }}
              anchor="top"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                mapRef.current?.flyTo({
                  center: [point?.centroid?.lng, point?.centroid?.lat],
                  zoom: 16,
                });
                setPolygon(point?.polygon?.coordinates);
                setSelectedContract(point?.contract_id);
              }}
              latitude={point?.centroid?.lat}
              longitude={point?.centroid?.lng}
            />
          ))}
          {polygon && (
            <Polygon
              sourceId={`polygon-source`}
              layerId={`polygon-layer`}
              coordinates={polygon}
            />
          )}
          {selectedContract && (
            <PopupContent
              states={states}
              contractId={selectedContract}
              setSelectedContract={setSelectedContract}
            />
          )}
        </MapGL>
      </Box>
    </Box>
  );
};

export default CompanyDirectCoveredFields;
