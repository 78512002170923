import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  palette: {
    primary: {
      main: "#005815",
    },
  },
  direction: "rtl",
  typography: {
    fontFamily: "IranSans", // Add your Font here
  },
  components: {
    MuiPaper: {
      defaultProps: {
        style: {
          border: "1px solid #ddd",
          borderRadius: 10,
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

// eslint-disable-next-line react/prop-types
const MuiGlobalThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiGlobalThemeProvider;
