import { MenuItem, TextField } from "@mui/material";
import React from "react";

// eslint-disable-next-line react/prop-types
const IndexesSelect = ({ value, onChange, ...props }) => {
  const INDEXES = [
    { title_fa: "سبزینگی و پوشش گیاهی", value: "NDVI", lock: false },
    { title_fa: "محتوای آبی گیاه", value: "NDWI", lock: false },
    { title_fa: "محتوای نیتروژن گیاه", value: "NDRE", lock: false },
    { title_fa: "سطح برگ", value: "LAI", lock: false },
    { title_fa: "محتوای کلروفیل گیاه", value: "CHL", lock: false },
  ];

  return (
    <TextField
      {...props}
      InputProps={{ sx: { background: "#fff" } }}
      select
      value={value}
      onChange={onChange}
    >
      {INDEXES.map((option, index) => (
        <MenuItem disabled={option.lock} key={index} value={option.value}>
          {option.title_fa}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default IndexesSelect;
