import { Box, Typography } from "@mui/material";

// eslint-disable-next-line react/prop-types
const TitleSection = ({ title }) => {
  return (
    <Box sx={{ width: "fit-content" }}>
      <Typography
        fontWeight="bold"
        variant="subtitle1"
        style={{
          display: "inline-flex",
          flexDirection: "column",
        }}
      >
        {title}
      </Typography>
      <hr style={{ height: "2px", background: "#000" }} />
    </Box>
  );
};

export default TitleSection;
