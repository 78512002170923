import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: 0,
  contractId: "",
  stage: "",
  pahne: "",
};

const contractRegistraionSlice = createSlice({
  name: "contractRegistraionSlice",
  initialState,
  reducers: {
    updateContractState: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetContractState: () => initialState,
  },
});

const { reducer } = contractRegistraionSlice;

export const { resetContractState, updateContractState } =
  contractRegistraionSlice.actions;
export default { reducer };
