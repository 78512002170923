/* eslint-disable react/prop-types */
import { Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";
import convert2Jalali from "@/utils/convert2Jalali";

const ReportInfo = ({ data }) => {
  const infoReport = [
    [
      {
        title: "تهیه کننده گزارش:",
        value: `${data?.writer?.first_name} ${data?.writer?.last_name}`,
      },
      {
        title: "کارفرما",
        value: `${data?.farm?.customer?.first_name} ${data?.farm?.customer?.last_name}`,
      },
      {
        title: "تاریخ گزارش",
        value: convert2Jalali(data?.date),
      },
    ],
    [
      {
        title: "نام زمین",
        value: data?.farm?.name,
      },
      {
        title: "محصول",
        value: data?.farm_info?.product_type,
      },
      {
        title: "تاریخ کاشت",
        value: convert2Jalali(data?.farm_info?.cultivation),
      },
    ],
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container justifyContent="center">
        <Typography
          variant="h5"
          fontWeight="bolder"
          style={{
            textAlign: "center",
            marginTop: 50,
            direction: "rtl",
          }}
        >
          شماره گزارش:
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bolder"
          style={{
            textAlign: "center",
            marginTop: 50,
            direction: "rtl",
            ml: 4,
          }}
        >
          {data?.report_number}
        </Typography>
      </Grid>

      <table
        style={{
          borderCollapse: "separate",
          width: "80%",
          margin: "50px auto ",
          borderSpacing: 1,
        }}
      >
        <tbody>
          {infoReport.map((item, indexOne) => (
            <tr key={indexOne}>
              {item.map((detail, indexTwo) => (
                <Fragment key={indexTwo}>
                  <td
                    style={{
                      border: "1px solid #000",
                      background: "#ddd",
                      textAlign: "center",
                      width: 40,
                      height: 60,
                      fontWeight: "bolder",
                    }}
                  >
                    {detail.title}
                  </td>
                  <td
                    style={{
                      border: "1px solid #000",
                      textAlign: "center",
                      width: 80,
                      fontWeight: "bold",
                    }}
                  >
                    {detail.value}
                  </td>
                </Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default ReportInfo;
