import { insurancePolicyImg, updateSysImg } from "@/assets";
import GuideCard from "@/components/contract-registration-modules/GuideCard";

import {
  useGetInsuranceValidationQuery,
  usePostInsuranceValidationMutation,
} from "@/services/contracts";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

// const ITEMS = [
//   { title: "سیل", src: flood },
//   { title: "سرما و یخبندان بهاره", src: flood1 },
//   { title: "زلزله", src: earthquake },
//   { title: "سرما و یخبندان زمستانه", src: winterTree },
//   { title: "باد زدگی", src: wind },
//   { title: "خشکسالی", src: drought },
//   { title: "آتش سوزی", src: burn },
//   { title: "باران‌های سیل آسا", src: flood },
//   { title: "بادگرم", src: hotWind },
//   { title: "حمله پرندگان", src: birds },
//   { title: "طوفان", src: sandstorm },
//   { title: "گرما", src: heat },
//   { title: "تگرگ", src: hail },
// ];

// eslint-disable-next-line react/prop-types
const InsuranceCard = ({ item }) => {
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const [postInsuranceValidation, { isLoading }] =
    usePostInsuranceValidationMutation();

  return (
    <Box
      sx={{
        background: "#fff",
        mb: 2,
        "&:last-child": { mb: 0 },
        direction: "ltr",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            width={60}
            height={60}
            src={`${import.meta.env.VITE_PRODUCTION_BASE_URL}${
              item?.insurance_company?.logo
            }`}
            alt="insurance"
          />
          <Typography fontWeight="bold" sx={{ mt: 2 }}>
            {item?.insurance_company?.title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography>محصول: {item?.product?.title}</Typography>
          <Box sx={{ display: "flex" }}>
            <Typography>مبلغ</Typography>
            <Typography sx={{ mx: 1, color: "#01ADB7", fontWeight: "bold" }}>
              {Intl.NumberFormat("en").format(item?.unit_price)}
            </Typography>
            <Typography>ریال</Typography>
          </Box>
          <Button
            disabled={isLoading}
            onClick={() =>
              postInsuranceValidation({
                contractId,
                data: {
                  is_accepted: true,
                  selected_insurance_tariff: item?.id,
                },
              }).unwrap()
            }
            variant="contained"
            sx={{ fontWeight: "bold" }}
          >
            {isLoading ? "در حال ثبت سفارش..." : "سفارش"}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "10px",
          alignItems: "center",
          borderTop: "1px #0000007b solid",
        }}
      >
        <Typography fontWeight="bold" sx={{ mr: 2 }}>
          خدمات
        </Typography>
        <Box
          sx={{
            display: "flex",
            background: "#E0DDDA",
            padding: 1,
            borderRadius: 5,
          }}
        >
          {["سیل", "زلزله", "آتشسوزی", "خشکسالی"].map((item, index) => (
            <Typography sx={{ mx: 1, fontSize: 14 }} key={index}>
              {item}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

// ----- If you need added to component ----
// {insuranceValidateLoading ? (
//       <Box
//         sx={{
//           display: "flex",
//           flex: 1,
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <CircularProgress />
//         <Typography>درحال بارگزاری...</Typography>
//       </Box>
//     ) : (
//       <Box
//         sx={{
//           mt: { xs: 4, xl: 0 },
//           ml: { xs: 0, xl: "44px" },
//           width: { xs: "100%", md: "80%", xl: "400px" },
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//         }}
//       >
//         {data?.insurance_tariff_list?.length > 0 ? (
//           <Box>
//             {/* <TextField
//           value={code}
//           onChange={(e) => setCode(e.target.value)}
//           placeholder="تخفیف"
//           sx={{ background: "#fff", mb: 2 }}
//           inputProps={{ style: { fontSize: 18 } }}
//           InputProps={{
//             endAdornment: (
//               <Button
//                 disabled={!code}
//                 variant="contained"
//                 sx={{ fontWeight: "bold" }}
//               >
//                 اعمال
//               </Button>
//             ),
//           }}
//         /> */}
//             <Box sx={{ maxHeight: 800, overflowY: "auto", direction: "rtl" }}>
//               {data?.insurance_tariff_list.map((item, index) => (
//                 <InsuranceCard key={index} item={item} />
//               ))}
//             </Box>
//             <Box mt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
//               <Button
//                 disabled={isLoading}
//                 onClick={() =>
//                   postInsuranceValidation({
//                     contractId,
//                     data: { is_accepted: false },
//                   })
//                     .unwrap()
//                     .then(() => {})
//                 }
//                 sx={{ fontWeight: "bold" }}
//                 variant="outlined"
//               >
//                 {isLoading ? "منتظر بمانید..." : "به بیمه نامه نیاز ندارم"}
//               </Button>
//             </Box>
//           </Box>
//         ) : (
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <Typography fontWeight="bold">
//               هیچ تعرفه ای برای زمین شما وجود ندارد!
//             </Typography>
//             <Button
//               disabled={isLoading}
//               onClick={() =>
//                 postInsuranceValidation({
//                   contractId,
//                   data: { is_accepted: false },
//                 })
//                   .unwrap()
//                   .then(() => {})
//               }
//               sx={{ mt: 4 }}
//               variant="outlined"
//             >
//               {isLoading ? "درحال گذر..." : "عبور از این مرحله"}
//             </Button>
//           </Box>
//         )}
//       </Box>
//     )}
const InsurancePolicyIssuance = () => {
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const [code, setCode] = useState("");
  const { data, isLoading: insuranceValidateLoading } =
    useGetInsuranceValidationQuery(contractId);
  const [postInsuranceValidation, { isLoading }] =
    usePostInsuranceValidationMutation();

  return (
    <Box
      sx={{
        padding: "30px 36px",
        display: "flex",
        flexDirection: { xs: "column", xl: "row" },
        justifyContent: { xs: "", xl: "space-around" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", xl: "flex-start" },
        }}
      >
        <GuideCard
          src={insurancePolicyImg}
          title="روند صدور بیمه نامه برای اراضی کشاورزان به چه صورت است؟"
        >
          <Typography textAlign="justify">
            شما می‌توانید بیمه نامه‌ای را برای زمین کشاورزی خود صادر کنید که به
            جایمبلغ و بهای مشخص، بر اساس ارزش واقعی زمین، میزان پوشش تعیین
            می‌شود. در این روش، اگر ارزش زمین شما بیشتر شد، میزان پوشش بیمه نیز
            بیشتر می‌شود و در صورت کاهش ارزش زمین، میزان پوشش بیمه نیز کاهش
            می‌یابد. این روش به شما کمک می‌کند تا برای زمین کشاورزی خود، مقدار
            مناسبی را برای بیمه گذاری انتخاب کنید. هزینه صدور بیمه نامه با توجه
            به میزان اراضی شما و پوشش های دریافتی از مبلغ کیف پول شما کسر خواهد
            شد .
          </Typography>
        </GuideCard>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            mt: { xs: 4, xl: 0 },
            mx: 2,
            p: { xs: 2, xl: 6 },
            borderRadius: 4,
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: 250, md: 350 },
              height: { xs: 250, md: 350 },
              background: `url(${updateSysImg}) center center no-repeat`,
              backgroundSize: "contain",
            }}
          />
          <Typography fontWeight="bold" textAlign="center">
            این بخش از سایت در حال توسعه است.
          </Typography>
          <Typography mt={2} fontWeight="bold" textAlign="center">
            پس از راه اندازی خدمات این قسمت، به شما همراهان عزیز اطلاع رسانی
            خواهد شد.
          </Typography>
          <Button
            disabled={isLoading}
            onClick={() =>
              postInsuranceValidation({
                contractId,
                data: { is_accepted: false },
              })
                .unwrap()
                .then(() => {})
            }
            sx={{ mt: 4 }}
            variant="outlined"
          >
            {isLoading ? "درحال گذر..." : "عبور از این مرحله"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InsurancePolicyIssuance;
