import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ModalRoot from "@/components/common/Modal/ModalRoot";
import ProtectedRoute from "@/components/ProtectedRoute";
import DashboardLayout from "@/Layout/DashboardLayout";
import ContractRegistrationLayout from "@/Layout/ContractRegistrationLayout";
import { Roles } from "@/constants/global";

import {
  NotFound,
  DashboardPage,
  AuthPage,
  CreateContract,
  PVInfoVerification,
  AcquireInputVerification,
  AddWallet,
  AdminAcquireManagement,
  AdminContracts,
  AdminCoveredFields,
  Analyze,
  BankValidation,
  CompanyDirectAcquireManagement,
  CompanyDirectContracts,
  CompanyDirectCoveredFields,
  ContractDetail,
  Contracts,
  FarmDeclarion,
  InsurancePolicyIssuance,
  KnowledgeTransfer,
  Meteorology,
  Prediction,
  Reports,
  SatelliteMonitoring,
  SigningAgreement,
  Verifications,
  Unauthorized,
} from "@/pages";

import "react-toastify/dist/ReactToastify.css";
import TechnologyKnowledgeTransfer from "./pages/TechnologyKnowledgeTransfer";
import ProductsPrices from "./pages/Admin/ProductsPrices";
import CompanyDirectProductsPrices from "./pages/CompanyDirect/CompanyDirectProductsPrices";

function App() {
  return (
    <>
      <ModalRoot />
      <ToastContainer
        draggable={false}
        closeButton={false}
        theme="colored"
        autoClose={2000}
        pauseOnFocusLoss={false}
        position={"top-right"}
        rtl
        limit={5}
        bodyStyle={{
          userSelect: "none",
        }}
      />
      <Routes>
        <Route path="/" element={<DashboardLayout />}>
          <Route
            path="technology-knowledge-transfer"
            element={<TechnologyKnowledgeTransfer />}
          >
            <Route
              path="meteorology"
              element={
                <ProtectedRoute
                  requiredRole={[
                    Roles.ADMIN,
                    Roles.FARMER,
                    Roles.COMPANY_DIRECT,
                  ]}
                >
                  <Meteorology />
                </ProtectedRoute>
              }
            >
              <Route path={`prediction`} element={<Prediction />} />
              <Route path={`analyze`} element={<Analyze />} />
            </Route>
            <Route
              path="satellite-monitoring"
              element={
                <ProtectedRoute
                  requiredRole={[
                    Roles.ADMIN,
                    Roles.FARMER,
                    Roles.COMPANY_DIRECT,
                  ]}
                >
                  <SatelliteMonitoring />
                </ProtectedRoute>
              }
            />
            <Route
              path="reports/:id"
              element={
                <ProtectedRoute requiredRole={[Roles.ADMIN, Roles.FARMER]}>
                  <Reports />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* farmer's routes */}
          <Route path="farmer">
            <Route
              index
              element={
                <ProtectedRoute requiredRole={[Roles.FARMER]}>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="contracts"
              element={
                <ProtectedRoute requiredRole={[Roles.FARMER]}>
                  <Contracts />
                </ProtectedRoute>
              }
            />
            <Route
              path="contracts/:id"
              element={
                <ProtectedRoute requiredRole={[Roles.FARMER]}>
                  <ContractDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="contract-registration"
              element={
                <ProtectedRoute requiredRole={[Roles.FARMER]}>
                  <ContractRegistrationLayout />
                </ProtectedRoute>
              }
            >
              <Route index element={<CreateContract />} />
              <Route
                path="pv-info-Verification"
                element={<PVInfoVerification />}
              />
              <Route path="add-new-farm" element={<FarmDeclarion />} />
              <Route path="signing-agreement" element={<SigningAgreement />} />
              <Route path="verifications" element={<Verifications />}>
                <Route
                  path="receive-inputs"
                  element={<AcquireInputVerification />}
                />
                <Route
                  path="insurance-policy-issuance"
                  element={<InsurancePolicyIssuance />}
                />
                <Route
                  path="knowledge-transfer"
                  element={<KnowledgeTransfer />}
                />
                <Route path="bank-validation" element={<BankValidation />} />
              </Route>
            </Route>
          </Route>

          {/* admin's routes */}
          <Route path="admin">
            <Route
              index
              element={
                <ProtectedRoute
                  requiredRole={[
                    Roles.ADMIN,
                    Roles.STATE_ADMIN,
                    Roles.SUPERVISOR_ADMIN,
                  ]}
                >
                  <AdminCoveredFields />
                </ProtectedRoute>
              }
            />
            <Route
              path="contracts-management"
              element={
                <ProtectedRoute
                  requiredRole={[
                    Roles.ADMIN,
                    Roles.STATE_ADMIN,
                    Roles.SUPERVISOR_ADMIN,
                  ]}
                >
                  <AdminContracts />
                </ProtectedRoute>
              }
            />
            <Route
              path="acquires-management"
              element={
                <ProtectedRoute
                  requiredRole={[
                    Roles.ADMIN,
                    Roles.STATE_ADMIN,
                    Roles.SUPERVISOR_ADMIN,
                  ]}
                >
                  <AdminAcquireManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="products-prices"
              element={
                <ProtectedRoute requiredRole={[Roles.ADMIN]}>
                  <ProductsPrices />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* company direct's routes */}
          <Route path="company-direct">
            <Route
              index
              element={
                <ProtectedRoute requiredRole={[Roles.COMPANY_DIRECT]}>
                  <CompanyDirectCoveredFields />
                </ProtectedRoute>
              }
            />
            <Route
              path="contracts-management"
              element={
                <ProtectedRoute requiredRole={[Roles.COMPANY_DIRECT]}>
                  <CompanyDirectContracts />
                </ProtectedRoute>
              }
            />
            <Route
              path="acquires-management"
              element={
                <ProtectedRoute requiredRole={[Roles.COMPANY_DIRECT]}>
                  <CompanyDirectAcquireManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="product-prices"
              element={
                <ProtectedRoute requiredRole={[Roles.COMPANY_DIRECT]}>
                  <CompanyDirectProductsPrices />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>

        <Route path="add-wallet" element={<AddWallet />} />
        <Route path="auth" element={<AuthPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
