/* eslint-disable react/prop-types */
import { Edit, Preview } from "@mui/icons-material";
import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import { useRef, useState } from "react";

const ImageText = ({ item, isRtl, imageEditable, textEditable }) => {
  const STATIC_REPORT_TEXT = {
    NDVI: "شاخص تراکم و سلامت گیاهی (NDVI) نشان دهنده وضعیت تراکم پوشش گیاهی و سلامت (سبز بودن) گیاه است. شاخص NDVI هر چه بیشتر باشد، تراکم گیاهی یا سبزینگی محصول بیشتر است.",
    LAI: "",
    NDWI: "شاخص محتوای آبی گیاه (NDWI) نشان دهنده رطوبت یا میزان آماس گیاه است. هرچه میزان شاخص NDWI بیشتر باشد، گیاه آبدارتر خواهد بود.",
    CHL: "شاخص کلروفیل تولید شده گیاه (CHL) میزان ذخیره کلروفیل محصول را نشان میدهد. هرچه میزان شاخص CHL بیشتر باشد، میزان کلروفیل گیاه بیشتر است.",
    NDRE: "شاخص میزان ازت گیاه (NDRE) نشان دهنده سطح نیتروژن جذب شده توسط گیاه است.",
    MSAVI: "",
    RGB: "",
  };
  // console.log(item?.title, STATIC_REPORT_TEXT[item?.title]);
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState(
    item.description.replace(STATIC_REPORT_TEXT[item?.title], "")
  );
  const editorRef = useRef();

  return (
    <div>
      {!!((imageEditable || textEditable) && item?.title !== "RGB") && (
        <div className={isRtl ? "text-right" : "text-right"}>
          <Button
            variant="outlined"
            style={{ background: "transparent" }}
            sx={{ my: 4 }}
            onClick={() => setEditMode((prev) => !prev)}
          >
            {editMode ? (
              <>
                نمایش
                <Preview />
              </>
            ) : (
              <>
                ویرایش
                <Edit />
              </>
            )}
          </Button>
        </div>
      )}
      {item.img && (
        <div style={{}} className="table">
          {imageEditable && editMode ? (
            <div
              style={{
                height: 800,
                width: 800,
                position: "relative",
              }}
            >
              {/* <CustomImageEditor
                innerRef={editorRef}
                imageSrc={item.img}
                onReset={() => {
                  editorRef.current?.loadImage({
                    url: item.orgImg,
                  });
                }}
              /> */}
            </div>
          ) : (
            <img
              onLoad={() => {}}
              loading="lazy"
              src={item.img}
              alt={item.title}
              style={{
                border: "solid 1px rgba(0,0,0,0.4)",
                borderRadius: 10,
                margin: "20px 0",
                maxWidth: 800,
                width: "100%",
              }}
            />
          )}
        </div>
      )}
      {textEditable && editMode ? (
        <div className="text-center">
          <TextareaAutosize
            dir={isRtl ? "rtl" : "ltr"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline="true"
            minRows={7}
            name="message"
            style={{
              padding: 10,
              border: "2px #000 solid",
              borderRadius: 10,
              width: "100%",
              marginTop: 20,
            }}
            placeholder="توضیحات خود را بنویسید"
          />
          <Button
            className="my-4"
            onClick={() =>
              item.updateDetail(
                setEditMode,
                description,
                editorRef?.current?.getImageUrl()
              )
            }
          >
            تایید
          </Button>
        </div>
      ) : (
        <Box sx={{ mt: "10px", mb: "50px" }}>
          <Typography
            style={{
              textAlign: "justify",
              direction: isRtl ? "rtl" : "ltr",
            }}
          >
            {item.description}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default ImageText;
