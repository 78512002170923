import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import rtkQueryErrorLogger from "@/utils/rtkQueryErrorLogger";
import { meteorologyApi } from "@/services/meteorology";
import { farmsApi } from "@/services/farms";
import { authenticationApi } from "@/services/authentication";
import { contractsApi } from "@/services/contracts";
import { productsApi } from "@/services/products";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({})
      .concat(rtkQueryErrorLogger)
      .concat(authenticationApi.middleware)
      .concat(meteorologyApi.middleware)
      .concat(contractsApi.middleware)
      .concat(farmsApi.middleware)
      .concat(productsApi.middleware),
});

export default store;
