import { Box, Divider, IconButton, Typography } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { Close } from "@mui/icons-material";
import { useGetBankValidationQuery } from "@/services/contracts";

const Item = ({ title, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        mb: "20px",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "flex-start", sm: "center" },
      }}
    >
      <Box sx={{ width: "250px", mb: 2 }}>
        <Typography fontSize="18px" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          background: "#fff",
          padding: "10px 18px",
          borderRadius: 4,
          marginLeft: { xs: 0, sm: 4 },
          maxWidth: { xs: 250, md: 600 },
          overflowX: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const ContractPrices = (props) => {
  const { data } = useGetBankValidationQuery(props?.contractId, {
    skip: !props?.contractId,
  });

  return (
    <ModalComponent
      onClose={props.onClose}
      open={props.open}
      paperProps={{
        sx: {
          p: 6,
          height: 500,
          overflowY: "auto",
          position: "relative",
        },
      }}
    >
      <IconButton
        sx={{ position: "absolute", top: "5px", left: "5px" }}
        onClick={props.onClose}
      >
        <Close />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Item title="مساحت زمین شما">
          <Typography>{data?.farm?.area} هکتار</Typography>
        </Item>
        <Item title="اعتبار دریافتی بابت نهاده: ">
          <Box component="table">
            <Box component="thead">
              <Box component="tr">
                <Box
                  component="th"
                  sx={{
                    background: "#cfcfcf",
                    borderTopLeftRadius: 10,
                    p: 1,
                  }}
                >
                  عنوان
                </Box>
                <Box component="th" sx={{ background: "#cfcfcf", p: 1 }}>
                  مقدار
                </Box>
                <Box component="th" sx={{ background: "#cfcfcf", p: 1 }}>
                  هزینه
                </Box>
                <Box
                  component="th"
                  sx={{
                    background: "#cfcfcf",
                    borderTopRightRadius: 10,
                    p: 1,
                  }}
                >
                  شرکت ارائه دهنده
                </Box>
              </Box>
            </Box>
            <Box component="tbody">
              {data?.provider?.selected_inputs?.map((item, index) => (
                <Box component="tr" key={item?.id}>
                  <Box component="td" sx={{ p: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography textAlign="center">
                        {item?.selected_input?.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="td" sx={{ p: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", px: 4 }}>
                      <Typography
                        component="span"
                        sx={{ color: "#01ADB7", mr: 2 }}
                      >
                        {item?.needed_bags}
                      </Typography>
                      <Typography>
                        {item?.selected_input?.input_type?.title === "seed"
                          ? "کیلوگرم"
                          : "بسته"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="td" sx={{ p: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography sx={{ textAlign: "center" }}>
                        {item?.cost > 0
                          ? `${Intl.NumberFormat("en").format(item?.cost)} ریال`
                          : "قیمت بعدا محاسبه می شود"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="td" sx={{ p: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography sx={{ textAlign: "center" }}>
                        {item?.selected_input?.provider?.title ?? "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />
          <Typography mb={1}>
            مبلغ : {Intl.NumberFormat("en").format(data?.provider?.payed_cost)}{" "}
            ریال
          </Typography>
        </Item>
        {/* <Item title="شرکت ارائه دهنده بیمه نامه:">
          <Box sx={{ maxWidth: "300px" }}>
            {data?.insurance?.payed_cost ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 1,
                  mt: 2,
                }}
              >
                <img
                  width={100}
                  src={`${import.meta.env.VITE_PRODUCTION_BASE_URL}${
                    data?.insurance?.tariff?.insurance_company?.logo
                  }`}
                  alt="insurance"
                />
                <Typography fontWeight="bold" mt={2}>
                  {data?.insurance?.tariff?.insurance_company?.title}
                </Typography>
              </Box>
            ) : (
              <Typography>بیمه سفارش نداده اید!</Typography>
            )}
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography mb={1}>
            مبلغ : {Intl.NumberFormat("en").format(data?.insurance?.payed_cost)}{" "}
            ریال
          </Typography>{" "}
        </Item> */}

        <Item title="خدمات انتقال دانش:">
          <Box sx={{ width: { xs: "fit-content", sm: "300px" } }}>
            <Box>
              <Typography textAlign="left">
                - مشاوره‌های فنی و توصیه‌های به زراعی به صورت مجازی، تلفنی و
                حضوری
                <br />
                - پیش آگاهی آب و کشاورزی
                <br />
                - پایش ماهواره‌ای آفات و بیماری‌ها جهت افزایش عملکرد <br />
                - توصیه فنی در حوزه افزایش بهره‌وری در آبیاری
                <br />
                - گزارشات فنی جهت مبارزه با سرمازدگی و خشکسالی در بستر الکترونیک
                مناسب اجرای کشاورزی قراردادی
                <br />
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography mb={1}>
            مبلغ :{" "}
            {Intl.NumberFormat("en").format(
              data?.knowledge_agreement?.payed_cost
            )}{" "}
            ریال
          </Typography>
        </Item>

        <Divider sx={{ my: 4 }} />

        <Item title="مجموع هزینه های قرارداد:">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              px: 4,
              maxWidth: "300px",
            }}
          >
            <Typography
              fontSize={18}
              fontWeight="bold"
              component="span"
              sx={{ color: "#01ADB7", mr: 2 }}
            >
              {Intl.NumberFormat("en").format(data?.contract?.total_cost)}
            </Typography>
            <Typography>ریال</Typography>
          </Box>
        </Item>
      </Box>
    </ModalComponent>
  );
};

export default ContractPrices;
