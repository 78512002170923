/* eslint-disable react/prop-types */
import { useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, Paper } from "@mui/material";

import LoginForm from "@/components/forms/LoginForm";
import EnterCode from "@/components/common/EnterCode";

import farmImage from "@/assets/images/loginImage.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginState } from "@/redux/slices/authSlice";
import { dashboardPath } from "@/constants/pagePath";
import RegisterForm from "@/components/forms/RegisterForm";
import {
  useLoginMutation,
  useLoginOtpVerifyMutation,
  useRegisterMutation,
  useRegisterOtpVerifyMutation,
} from "@/services/authentication";
import { toast } from "react-toastify";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { RolePath } from "@/constants/global";

const Tab = ({ selected, setSelected }) => {
  return (
    <Box
      sx={{
        height: "60px",
        minWidth: 100,

        display: "flex",
        padding: "15px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          background: "#005815",
          width: "200px",
          height: "85%",
          display: "flex",
          alignItems: "center",
          borderRadius: "12px",
          p: 0.3,
        }}
      >
        <Button
          fullWidth
          sx={{
            fontSize: "18px",
            "&:hover": {
              ...(selected === "login"
                ? { background: "#fff", color: "#005815" }
                : { color: "#fff" }),
            },
            borderRadius: "12px 0 0 12px",
            height: "100%",
            ...(selected === "login"
              ? { background: "#fff", color: "#005815" }
              : { color: "#fff" }),
          }}
          onClick={() => setSelected("login")}
        >
          ورود
        </Button>
        <Button
          fullWidth
          sx={{
            fontSize: "18px",
            "&:hover": {
              ...(selected === "register"
                ? { background: "#fff", color: "#005815" }
                : { color: "#fff" }),
            },
            borderRadius: "0 12px 12px 0",
            height: "100%",
            ...(selected === "register"
              ? { background: "#fff", color: "#005815" }
              : { color: "#fff" }),
          }}
          onClick={() => setSelected("register")}
        >
          ثبت نام
        </Button>
      </Box>
    </Box>
  );
};

const AuthContainer = styled(Box)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const FormSection = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  direction: rtl;
`;

const ImageSection = styled(Box)`
  width: 100%;
  height: 100%;
  display: none;
`;

const FormWrapper = styled(Paper)`
  border: 1px ${({ theme }) => theme.palette.grey["100"]} solid;
  width: 35%;
  padding: 2%;
  height: fit-content;
  margin: 50px 0;
  direction: ltr;
`;

const FormHOC = ({ Component, handleSubmit, handleReset }) => {
  const [codeLevel, setCodeLevel] = useState(false);
  const [code, setCode] = useState("");

  return codeLevel ? (
    <EnterCode
      code={code}
      setCode={setCode}
      handleSubmit={() => handleSubmit(code)}
      handleReset={handleReset}
    />
  ) : (
    <Component goNextSection={() => setCodeLevel(true)} />
  );
};

const AuthPage = () => {
  const [renderSection, setRenderSection] = useState("login");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registerOtpVerify] = useRegisterOtpVerifyMutation();
  const [loginOtpVerify] = useLoginOtpVerifyMutation();
  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const renderState = () => {
    if (renderSection === "login") {
      return (
        <FormHOC
          Component={LoginForm}
          handleReset={(enableTimer) => {
            if (!executeRecaptcha) {
              toast.error("کپچا هنوز دسترس نیست!");
              return;
            }
            executeRecaptcha("validate_captcha").then((gReCaptchaToken) => {
              login({
                phoneNumber: sessionStorage.getItem("phone"),
                captcha: gReCaptchaToken,
              })
                .unwrap()
                .then(() => {
                  enableTimer();
                  toast.success("کد مجدد ارسال شد!");
                });
            });
          }}
          handleSubmit={(code) => {
            if (code.length > 0) {
              loginOtpVerify(code)
                .unwrap()
                .then((data) => {
                  toast.success("ورود با موفقیت انجام شد!");
                  dispatch(setLoginState(data));
                  navigate(`${RolePath[data?.user_info?.type?.name] ?? "/"}`, {
                    replace: true,
                  });
                });
            }
          }}
        />
      );
    } else {
      return (
        <FormHOC
          handleReset={(enableTimer) => {
            if (!executeRecaptcha) {
              toast.error("کپچا هنوز دسترس نیست!");
              return;
            }
            executeRecaptcha("validate_captcha").then((gReCaptchaToken) => {
              register({
                phoneNumber: sessionStorage.getItem("phone"),
                nationalId: sessionStorage.getItem("nationalId"),
                captcha: gReCaptchaToken,
              })
                .unwrap()
                .then(() => {
                  enableTimer();
                  toast.success("کد مجدد ارسال شد!");
                });
            });
          }}
          Component={RegisterForm}
          handleSubmit={(code) => {
            if (code.length > 0) {
              registerOtpVerify(code)
                .unwrap()
                .then((data) => {
                  toast.success("ثبت نام با موفقیت انجام شد!");
                  dispatch(setLoginState(data));
                  navigate(dashboardPath, { replace: true });
                });
            }
          }}
        />
      );
    }
  };

  return (
    <AuthContainer
      sx={{
        flexDirection: {
          md: "row",
        },
      }}
    >
      <FormSection
        sx={{
          alignItems: "center",
        }}
      >
        <FormWrapper
          sx={{
            width: {
              xs: "95%",
              md: "50%",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src="/SATPLAT.F.E.png" width="30%" alt="logo" />
          </Box>

          {renderSection && (
            <Tab selected={renderSection} setSelected={setRenderSection} />
          )}

          <Box>{renderState()}</Box>
        </FormWrapper>
      </FormSection>
      <ImageSection
        sx={{
          background: `url(${farmImage}) center center no-repeat`,
          backgroundSize: "cover",
          display: {
            md: "flex",
          },
        }}
      />
    </AuthContainer>
  );
};

const WrappedByCaptchaProvider = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
      language="fa"
      scriptProps={{
        appendTo: "body", // optional, default to "head", can be "head" or "body",
      }}
    >
      <AuthPage />
    </GoogleReCaptchaProvider>
  );
};

export default WrappedByCaptchaProvider;
