import { memo, useEffect, useState } from "react";

let myInterval;

const Timer = ({ h = 0, m = 0, s = 0, render, onFinished = () => {} }) => {
  const [time, setTime] = useState({
    h,
    m,
    s,
  });

  const putZero = (num) => {
    if (num < 10) {
      return `0${num}`;
    }
    return num;
  };

  const start = () => {
    clearInterval(myInterval);
    myInterval = setInterval(() => {
      setTime((prev) => {
        if (prev.s > 0) {
          return { ...prev, s: prev.s - 1 };
        } else if (prev.m > 0) {
          return { ...prev, m: prev.m - 1, s: 59 };
        } else if (prev.h > 0) {
          return { ...prev, h: prev.h - 1, m: 59, s: 59 };
        }
        // clear interval
        clearInterval(myInterval);

        return prev;
      });
    }, 1000);
  };

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    if (time.h === 0 && time.m === 0 && time.s === 0) {
      onFinished();
    }
  }, [time, onFinished]);

  return (
    <>
      {render({
        h: putZero(time.h),
        m: putZero(time.m),
        s: putZero(time.s),
        finished: !!time.h === 0 && !!time.m === 0 && !!time.s === 0,
      })}
    </>
  );
};

export default memo(Timer);
