import { TextField, useMediaQuery } from "@mui/material";
import { forwardRef } from "react";
import OtpInput from "react-otp-input";

// eslint-disable-next-line react/prop-types
const OTP = ({ inputStyle, ...props }, ref) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <OtpInput
      containerStyle={{ paddingTop: 4, direction: "ltr" }}
      ref={ref}
      shouldAutoFocus
      inputStyle={{
        width: matches ? 35 : 50,
        height: 30,
        margin: "10px 10px",
        fontSize: 22,
        border: "none",
        borderBottom: "1px solid #BABABA",
        color: "#000",
        outline: "none",
        ...inputStyle,
      }}
      errorStyle={{
        borderBottom: "1px solid red",
      }}
      focusStyle={{
        borderBottom: "1px solid #000",
      }}
      renderInput={(props) => {
        // eslint-disable-next-line react/prop-types
        props.style = { ...props?.style, borderBottom: "none" };
        return <TextField inputProps={props} />;
      }}
      {...props}
    />
  );
};

export default forwardRef(OTP);
