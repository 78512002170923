import { AppBar, IconButton, Toolbar } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useDispatch } from "react-redux";
import { openDrawer } from "@/redux/slices/globalSlice";

const Header = () => {
  const dispatch = useDispatch();

  return (
    <AppBar
      position="static"
      sx={{
        display: { xs: "flex", lg: "none" },
        justifyContent: "center",
        height: "80px",
        background: "#005815",
        border: "none !important",
        borderRadius: "0px !important",
      }}
    >
      <Toolbar>
        <IconButton
          onClick={() => dispatch(openDrawer())}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuOutlinedIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
