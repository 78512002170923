import { useState } from "react";
import useDebounce from "./useDebounce";

const useSearchByDelay = (delay = 1000) => {
  const [currentSearch, setCurrentSearch] = useState("");
  const debounceSearch = useDebounce(currentSearch, delay);

  return { debounceSearch, currentSearch, setCurrentSearch };
};

export default useSearchByDelay;
