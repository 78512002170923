/* eslint-disable react/prop-types */
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DAY_OF_THE_WEEK = {
  ش: "شنبه",
  "1ش": "یکشنبه",
  "2ش": "دوشنبه",
  "3ش": "سه‌شنبه",
  "4ش": "چهارشنبه",
  "5ش": "پنجشنبه",
  ج: "جمعه",
};

const CDatePicker = ({ ...props }) => {
  return (
    <DatePicker
      {...props}
      slotProps={{
        ...props?.slotProps,
        layout: {
          ...props.slotProps?.layout,
          sx: {
            direction: "ltr",
            width: 350,
            ".MuiPickersDay-root": {
              borderRadius: 1.5,
              borderWidth: 0,
              border: "0px solid",
              width: 50,
              fontWeight: "900",
            },
            ".MuiDayCalendar-weekDayLabel": {
              width: 50,
              color: (theme) => theme.palette.primary.main,
              borderRadius: 0,
              borderWidth: 0,
              border: "0px solid",
              fontWeight: "bold",
            },
          },
        },
      }}
      dayOfWeekFormatter={(day) => {
        return DAY_OF_THE_WEEK[day];
      }}
      localeText={{
        fieldDayPlaceholder: () => "روز",
        fieldMonthPlaceholder: () => "ماه",
        fieldYearPlaceholder: () => "سال",
      }}
      views={["year", "month", "day"]}
      closeOnSelect={false}
    />
  );
};

export default CDatePicker;
