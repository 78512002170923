import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { faIR } from "@mui/x-date-pickers/locales";

// eslint-disable-next-line react/prop-types
function MuiLocalizationProvider({ children }) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFnsJalali}
      localeText={
        faIR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      {children}
    </LocalizationProvider>
  );
}

export default MuiLocalizationProvider;
