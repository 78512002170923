/* eslint-disable react/prop-types */
import { Box, Typography } from "@mui/material";
import Loading from "../Loading";

const Error = () => {
  <Box
    sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography>خطا در سرویس</Typography>
  </Box>;
};

const HandleServerData = ({
  children,
  LoadingComponent = <Loading />,
  ErrorComponent = <Error />,
  isLoading,
  isSuccess,
  isError,
}) => {
  if (isLoading) {
    return LoadingComponent;
  }

  if (isSuccess) {
    return children;
  }

  if (isError) {
    return ErrorComponent;
  }

  return null;
};

export default HandleServerData;
