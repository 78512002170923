/* eslint-disable react/prop-types */
import { IndexesList } from "@/constants/global";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useGetFinalReportQuery,
  useUpdateReportDetailMutation,
} from "@/services/reports";

import Loading from "./Loading";
import ReportInfo from "./ReportInfo";
import ReportSection from "./ReportSection";
import { baseUrl } from "@/services";

const Preview = ({ farmId, id, editable }) => {
  const STATIC_REPORT_TEXT = {
    NDVI: "شاخص تراکم و سلامت گیاهی (NDVI) نشان دهنده وضعیت تراکم پوشش گیاهی و سلامت (سبز بودن) گیاه است. شاخص NDVI هر چه بیشتر باشد، تراکم گیاهی یا سبزینگی محصول بیشتر است.",
    LAI: "",
    NDWI: "شاخص محتوای آبی گیاه (NDWI) نشان دهنده رطوبت یا میزان آماس گیاه است. هرچه میزان شاخص NDWI بیشتر باشد، گیاه آبدارتر خواهد بود.",
    CHL: "شاخص کلروفیل تولید شده گیاه (CHL) میزان ذخیره کلروفیل محصول را نشان میدهد. هرچه میزان شاخص CHL بیشتر باشد، میزان کلروفیل گیاه بیشتر است.",
    NDRE: "شاخص میزان ازت گیاه (NDRE) نشان دهنده سطح نیتروژن جذب شده توسط گیاه است.",
    MSAVI: "",
    RGB: "",
  };
  const {
    data: reportData,
    isLoading,
    isSuccess,
  } = useGetFinalReportQuery(
    { reportId: id, farmId },
    {
      skip: !(id && farmId),
    }
  );
  const data = reportData ? reportData?.data : null;
  const [mergedIndexTimeseries, setMergedIndexTimeseries] = useState([]);
  const [updateDetail] = useUpdateReportDetailMutation();

  useEffect(() => {
    if (data) {
      const temp = [];
      IndexesList.forEach((indexName) => {
        const indexesItem = data?.consulting_detail_info?.indexes?.find(
          (ele) => ele.title === indexName
        );
        const timeSeriesItem = data?.consulting_detail_info?.time_series?.find(
          (ele) => ele.title === indexName
        );
        indexesItem &&
          temp.push({
            img: baseUrl + indexesItem.file,
            orgImg: baseUrl + indexesItem.original_file,
            description:
              STATIC_REPORT_TEXT[indexesItem.title] +
              " " +
              indexesItem.description,
            // enable edit image for indexes
            type: indexesItem.type,
            title: indexesItem.title,
            updateDetail: (setEditMode, description, editorImage) => {
              updateDetail({
                id: indexesItem.id,
                description: description,
                file: editorImage,
                original_file: indexesItem?.original_file,
                title: indexesItem.title,
                dates: null,
                created_at: indexesItem.created_at,
                type: "INDEX",
                consulting_report: id,
              })
                .unwrap()
                .then(() => {
                  toast.success("با موفقیت ثبت شد!");
                  setEditMode(false);
                });
            },
          });
        timeSeriesItem &&
          temp.push({
            img: baseUrl + timeSeriesItem.file,
            orgImg: baseUrl + indexesItem?.original_file,
            description: timeSeriesItem.description,
            type: timeSeriesItem.type,
            title: timeSeriesItem.title,
            updateDetail: (setEditMode, description) => {
              updateDetail({
                id: timeSeriesItem.id,
                description: description,
                file: timeSeriesItem.file,
                original_file: null,
                title: timeSeriesItem.title,
                dates: null,
                created_at: timeSeriesItem.created_at,
                type: "TIME_SERIES",
                consulting_report: id,
              })
                .unwrap()
                .then(() => {
                  toast.success("");
                  setEditMode(false);
                });
            },
          });
      });

      const RGBItem = data?.consulting_detail_info?.indexes?.find(
        (ele) => ele.title === "RGB"
      );
      RGBItem &&
        temp.unshift({
          img: baseUrl + RGBItem.file,
          description: RGBItem.description,
          type: RGBItem.type,
          title: RGBItem.title,
        });
      setMergedIndexTimeseries(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id, updateDetail]);

  return (
    <div style={{ width: "900px", minHeight: "400px" }}>
      {isLoading ? (
        <Loading />
      ) : (
        isSuccess && (
          <>
            <ReportInfo data={data?.consulting_report} />
            <ReportSection
              title={"خلاصه گزارش"}
              textEditable={editable}
              data={data?.consulting_detail_info?.summary?.map((item) => ({
                img: item.file ? baseUrl + item.file : "",
                description: item.description,
                type: item.type,
                title: item.title,
                updateDetail: (setEditMode, description) => {
                  updateDetail({
                    id: item.id,
                    description: description,
                    file: null,
                    original_file: null,
                    title: "",
                    dates: null,
                    created_at: item.created_at,
                    type: "SUMMARY",
                    consulting_report: id,
                  })
                    .unwrap()
                    .then(() => {
                      toast.success("");
                      setEditMode(false);
                    });
                },
              }))}
            />
            <ReportSection
              imageEditable={editable}
              textEditable={editable}
              title={"شاخص ها"}
              data={mergedIndexTimeseries}
            />
            <ReportSection
              title={"آب و هوا"}
              textEditable={editable}
              data={data?.consulting_detail_info?.weather?.map((item) => ({
                img: baseUrl + item.file,
                description: item.description,
                type: item.type,
                title: item.title,
                updateDetail: (setEditMode, description) => {
                  updateDetail({
                    id: item.id,
                    description: description,
                    file: item.file,
                    original_file: null,
                    title: "",
                    dates: null,
                    created_at: item.created_at,
                    type: "WEATHER",
                    consulting_report: id,
                  })
                    .unwrap()
                    .then(() => {
                      toast.success("");
                      setEditMode(false);
                    });
                },
              }))}
            />
            {/* {Boolean(
              editable || data?.consulting_report?.hashtag?.length > 0
            ) && (
              <Box sx={{ pt: 1, pb: 6, px: 6 }}>
                <Hashtags
                  reportId={id}
                  editable={editable}
                  enteredHashtags={data?.consulting_report?.hashtag}
                />
              </Box>
            )} */}
          </>
        )
      )}
    </div>
  );
};

export default Preview;
