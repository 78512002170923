/* eslint-disable react/prop-types */
import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import ModalComponent from "../common/Modal/ModalComponent";
import { usePostAdminContractSetStateMutation } from "@/services/contracts";
import { useState } from "react";
import { toast } from "react-toastify";

const BeingActive = ({ submit, cancel, isLoading }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 4,
      }}
    >
      <Typography>
        آیا از
        <Typography
          component="span"
          sx={{ color: "green", fontWeight: "bold" }}
        >
          {" "}
          فعال کردن{" "}
        </Typography>
        این قرارداد اطمینان دارید؟
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Button
          disabled={isLoading}
          onClick={submit}
          sx={{ mx: 1 }}
          variant="contained"
        >
          بله
        </Button>
        <Button
          disabled={isLoading}
          onClick={cancel}
          sx={{ mx: 1 }}
          variant="outlined"
        >
          خیر
        </Button>
      </Box>
    </Box>
  );
};

const BeingDeny = ({
  submit,
  cancel,
  denialReason,
  setDenialReason,
  isLoading,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 4,
      }}
    >
      <Typography>
        اگر از
        <Typography component="span" sx={{ color: "red", fontWeight: "bold" }}>
          {" "}
          رد کردن{" "}
        </Typography>{" "}
        این قرارداد اطمینان دارید، پس از نوشتن شرح، دکمه تایید را بزنید.
      </Typography>
      <TextareaAutosize
        value={denialReason}
        onChange={(e) => setDenialReason(e.target.value)}
        style={{
          width: "80%",
          height: 150,
          marginTop: 20,
          resize: "none",
          padding: 10,
          outline: "none",
        }}
        placeholder="دلیل خود را شرح دهید ..."
      />
      <Box sx={{ mt: 3 }}>
        <Button
          disabled={denialReason?.length <= 3 || isLoading}
          onClick={submit}
          sx={{ mx: 1 }}
          variant="contained"
        >
          تایید
        </Button>
        <Button
          disabled={isLoading}
          onClick={cancel}
          sx={{ mx: 1 }}
          variant="outlined"
        >
          بستن
        </Button>
      </Box>
    </Box>
  );
};

const SetStateModal = (props) => {
  const closeModal = () => {
    props?.onClose();
  };
  const [denialReason, setDenialReason] = useState("");
  const [postSetState, { isLoading }] = usePostAdminContractSetStateMutation();

  const handleRequest = () => {
    const data =
      props?.state?.title === "active"
        ? { state: props?.state?.id }
        : { state: props?.state?.id, denialReason };

    postSetState({
      contractId: props?.contractId,
      data,
    })
      .unwrap()
      .then((data) => {
        toast.success(data.detail);
        props?.onSuccess && props?.onSuccess();
        closeModal();
      });
  };

  return (
    <ModalComponent onClose={closeModal} open={props.open}>
      {props?.state?.title === "active" ? (
        <BeingActive
          isLoading={isLoading}
          cancel={closeModal}
          submit={handleRequest}
        />
      ) : (
        <BeingDeny
          isLoading={isLoading}
          denialReason={denialReason}
          setDenialReason={setDenialReason}
          cancel={closeModal}
          submit={handleRequest}
        />
      )}
    </ModalComponent>
  );
};

export default SetStateModal;
