/* eslint-disable react/prop-types */
import MapGL, { NavigationControl } from "react-map-gl";
import DrawControl from "./DrawControl";
import { forwardRef } from "react";

const Map = forwardRef(
  ({ onCreate, onUpdate, onDelete, onLoad, children }, ref) => {
    return (
      <MapGL
        ref={ref}
        onLoad={onLoad}
        initialViewState={{
          longitude: 51.33725700771478,
          latitude: 35.70107661348783,
          zoom: 6,
        }}
        mapboxAccessToken={import.meta.env.VITE_MAP_TOKEN}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 4,
          boxShadow: "0px 5px 10px #00000050",
          position: "relative",
        }}
        mapStyle="mapbox://styles/amir790/ckxmvcic2o0jz15nsp93vt8ix"
      >
        <NavigationControl />
        <DrawControl
          position="top-right"
          displayControlsDefault={false}
          controls={{
            polygon: true,
            trash: true,
          }}
          defaultMode="draw_polygon"
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
        {children}
      </MapGL>
    );
  }
);

Map.displayName = "Map";

export default Map;
