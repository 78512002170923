import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";

// eslint-disable-next-line react/prop-types
const MuiRtlConfig = ({ children }) => {
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
    prepend: true,
  });

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

export default MuiRtlConfig;
