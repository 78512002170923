import { RolePath } from "@/constants/global";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.authentication.user);
  const role = user?.user_info?.type?.name;

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4">صفحه ای یافت نشد</Typography>
      <Button
        onClick={() => navigate(RolePath?.[role])}
        sx={{ my: 4 }}
        variant="contained"
      >
        بازگشت به صفحه اصلی
      </Button>
    </Box>
  );
};

export default NotFound;
