import { combineReducers } from "redux";

import authSlice from "./slices/authSlice";
import contractRegistraionSlice from "./slices/contractRegistraionSlice";
import globalSlice from "./slices/globalSlice";
import { meteorologyApi } from "@/services/meteorology";
import { farmsApi } from "@/services/farms";
import farmManagementSlice from "./slices/farmManagementSlice";
import { authenticationApi } from "@/services/authentication";
import { productsApi } from "@/services/products";

const combinedReducers = combineReducers({
  authentication: authSlice.reducer,
  contractRegistraion: contractRegistraionSlice.reducer,
  global: globalSlice.reducer,
  farmManagement: farmManagementSlice.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [meteorologyApi.reducerPath]: meteorologyApi.reducer,
  [farmsApi.reducerPath]: farmsApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "authentication/setLogoutState") {
    state = undefined;
    localStorage.clear();
  }
  return combinedReducers(state, action);
};

export default rootReducer;
