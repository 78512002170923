/* eslint-disable react/prop-types */
import { useMediaQuery } from "@mui/material";
import { useCallback } from "react";
import { Pagination } from "swiper";
import { Swiper } from "swiper/react";
import DailyCard from "./DailyCard";
import { SwiperSlide } from "swiper/react";

// eslint-disable-next-line react/prop-types
const DailyInformation = ({ data }) => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const xsUp = useMediaQuery((theme) => theme.breakpoints.up("xs"));

  const renderNumberSlide = useCallback(() => {
    if (lgUp) {
      return 7;
    }
    if (mdUp) {
      return 4;
    }
    if (smUp) {
      return 3;
    }
    if (xsUp) {
      return 1;
    }
  }, [lgUp, mdUp, smUp, xsUp]);

  return (
    <Swiper
      dir="rtl"
      slidesPerView={renderNumberSlide()}
      slidesPerGroup={renderNumberSlide()}
      spaceBetween={5}
      grabCursor={true}
      pagination={{ clickable: true }}
      modules={[Pagination]}
    >
      {data?.map((item, index) => (
        <SwiperSlide key={index}>
          <DailyCard key={index} dailyInfo={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default DailyInformation;
