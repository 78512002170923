import {
  PVInfoImg,
  contractHistoryImg,
  howToSubmitImg,
  transformItemImg,
  walletImg,
} from "@/assets";
import PVInfoForm from "@/components/contract-registration-modules/PVInfoForm";
import { useGetProfileQuery } from "@/services/contracts";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ITEMS = [
  { img: howToSubmitImg, title: "چگونه قرارداد خود را ثبت کنم؟", url: "" },
  {
    img: contractHistoryImg,
    title: "قرارداد های ثبت شده را کجا میتوانم ببینم؟",
    url: "",
  },
  {
    img: walletImg,
    title: "چطور یک کیف پول برای خودم فعال کنم؟",
    url: "",
  },
  {
    img: transformItemImg,
    title: "انتقال دانش و فناورین نوین چه خدماتی ارائه می دهد؟",
    url: "",
  },
];

const initialData = {
  first_name: "",
  last_name: "",
  national_id: "",
  id: "",
  father_name: "",
  birthday: "",
  state: null,
  city: "",
  phone_number: "",
  bank_account_number: "",
  address: "",
  postal_code: "",
  cooperative_company: null,
  gender: null,
};

const PVInfoVerification = () => {
  const contractId = useSelector(
    (state) => state.contractRegistraion.contractId
  );
  const { data: serverData, isSuccess } = useGetProfileQuery(contractId, {
    skip: !contractId,
  });

  // console.log(isSuccess);
  // useEffect(() => {
  //   !!(isSuccess && !serverData?.is_fetched) &&
  //     modalService.open(CompleteProfileModal, { contractId });
  // }, [contractId, isSuccess, serverData?.is_fetched]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          width: "90%",
          maxWidth: "1100px",
          padding: { xs: "20px 10px", md: "25px 42px" },
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          background: "#F4F6F8",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            اطلاعات اختصاصی
          </Typography>
          <Typography sx={{ fontSize: "14px", mt: "14px" }}>
            لطفاً اطلاعات شخصی خود را در این بخش تکمیل کرده و در صورت صحیح بودن
            اطلاعات دکمه تایید و ادامه را بزنید.{" "}
          </Typography>
          <Box>
            <PVInfoForm
              data={isSuccess ? serverData : initialData}
              isSuccess={isSuccess}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img style={{ width: "300px", height: "300px" }} src={PVInfoImg} />
        </Box>
      </Paper>
      <Grid container width="90%" spacing={2} rowGap={2} sx={{ mt: 4 }}>
        {ITEMS.map((item, index) => (
          <Grid
            item
            container
            justifyContent="center"
            key={index}
            xs={12}
            sm={4}
            md={3}
          >
            <Paper
              sx={{
                width: 340,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                p: 4,
                cursor: "pointer",
                "& img": {
                  transition: "all 0.25s ease",
                },
                "&:hover img": {
                  transform: "scale(1.2)",
                },
              }}
            >
              <img src={item.img} alt="" />
              <Typography
                sx={{ mt: "14px" }}
                fontWeight="bold"
                fontSize="14px"
                textAlign="center"
              >
                {item.title}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PVInfoVerification;
