import { Box, Typography } from "@mui/material";
import noReport from "@/assets/images/error/noReport.png";

const NoReportsFound = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography fontWeight="bold">گزارشی یافت نشد!</Typography>
      </Box>
      <img src={noReport} style={{ width: "15vw", marginTop: 25 }} alt="" />
    </Box>
  );
};

export default NoReportsFound;
