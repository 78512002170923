import { isRejectedWithValue } from "@reduxjs/toolkit";
import { isArray } from "lodash";
import { toast } from "react-toastify";

const toastErrors = (errors) => {
  if (isArray(errors)) {
    errors.forEach((item) => {
      toast.error(item);
    });
  } else {
    toast.error(errors);
  }
};

// eslint-disable-next-line no-unused-vars
const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    const errorDetail = action.payload?.data?.error?.detail;
    const errorStatusCode = action.payload?.data?.error?.status_code;

    if (errorStatusCode === 500) {
      toast.error("خطای سرور رخ داده است");
    } else {
      toastErrors(errorDetail);
    }
  }

  return next(action);
};
export default rtkQueryErrorLogger;
