import { Box } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "@/components/Header";
import GlobalDrawer from "@/components/GlobalDrawer";
import { useSelector } from "react-redux";

const DashboardLayout = () => {
  const auth = useSelector((state) => state.authentication.auth);

  if (!auth) {
    return <Navigate to="/auth" replace />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}>
      <Header />
      <GlobalDrawer />
      <Box
        sx={{
          background: "#F4F6F8",
          height: "100vh",
          width: "280px",
          boxShadow: "4px 0px 10px 0px rgba(128, 128, 128, 0.7)",
          display: { xs: "none", lg: "block" },
        }}
      >
        <Sidebar />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", lg: "calc(100vw - 280px)" },
          overflow: "auto",
          height: { xs: "calc(100vh - 80px)", lg: "100vh" },
          direction: "rtl",
        }}
      >
        <Box component="span" sx={{ direction: "ltr" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
