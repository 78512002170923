import Table from "@/components/admin-product-prices";
import useSearchByDelay from "@/hooks/useSearchByDelay";
import { useGetProductFinalPricesListQuery } from "@/services/products";
import {
  Box,
  Paper,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const ProductsPrices = () => {
  const [requestState, setRequestState] = useState({
    page: 1,
    // ordering: "",
    // state: "",
    page_size: 5,
    // cooperative_company: "",
  });
  const { currentSearch, setCurrentSearch, debounceSearch } =
    useSearchByDelay();
  const {
    data: productFinalPricesList,
    isFetching,
    isLoading,
    isSuccess,
  } = useGetProductFinalPricesListQuery({
    ...requestState,
    search: debounceSearch,
  });

  const handleChangePage = (event, newPage) => {
    setRequestState((prev) => {
      const newState = { ...prev, page: newPage + 1 };
      return newState;
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRequestState((prev) => {
      const newState = {
        ...prev,
        page: 1,
        page_size: parseInt(event.target.value, 10),
      };
      return newState;
    });
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        <Paper sx={{ width: "90%", maxWidth: 1200, p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              value={currentSearch}
              onChange={(e) => {
                setRequestState((prev) => ({ ...prev, page: 1 }));
                setCurrentSearch(e.target.value);
              }}
              label="جستجو"
              size="small"
              placeholder=""
            />
            {/* <Button
              variant="outlined"
              sx={{ ml: 2, alignSelf: "center" }}
              onClick={() => {
                modalService.open(AddNewProductPrice);
              }}
              endIcon={<Add />}
            >
              ثبت قیمت جدید
            </Button> */}
            {/* {!isStateAdmin && (
              <TextField
                onChange={(e) =>
                  setRequestState((prev) => {
                    const newState = {
                      ...prev,
                      page: 1,
                      location: e.target.value,
                    };
                    newState.cooperative_company = "";
                    if (e.target.value) {
                      getCompanyProvider({ state: e.target.value });
                    }

                    trigger(newState);
                    return newState;
                  })
                }
                SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
                value={requestState.location}
                sx={{ width: 200 }}
                select
                size="small"
                label="فیلتر براساس استان"
              >
                <MenuItem value={""}>بدون فیلتر</MenuItem>
                {provinces?.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <TextField
              disabled={companies && !requestState.location}
              onChange={(e) =>
                setRequestState((prev) => {
                  const newState = {
                    ...prev,
                    page: 1,
                    cooperative_company: e.target.value,
                  };
                  trigger(newState);
                  return newState;
                })
              }
              SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
              value={requestState.cooperative_company}
              sx={{ width: 200 }}
              select
              size="small"
              label="فیلتر براساس مباشر"
            >
              <MenuItem value={""}>بدون فیلتر</MenuItem>
              {companies?.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              onChange={(e) =>
                setRequestState((prev) => {
                  const newState = {
                    ...prev,
                    page: 1,
                    state: e.target.value,
                  };
                  trigger(newState);
                  return newState;
                })
              }
              value={requestState.state}
              sx={{ width: 200 }}
              select
              size="small"
              label="فیلتر براساس وضعیت"
            >
              <MenuItem value="">بدون فیلتر</MenuItem>
              {states?.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>*/}
          </Box>

          <Box sx={{ pt: 4 }}>
            <Table
              rowsPerPage={requestState.page_size}
              page={requestState.page - 1}
              data={productFinalPricesList?.results}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isFetching={isFetching}
            />
            <TablePagination
              sx={{ mt: 2 }}
              rowsPerPageOptions={[5, 10]}
              component="div"
              count={productFinalPricesList?.total_items ?? 0}
              rowsPerPage={requestState.page_size}
              page={requestState.page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="ردیف در هر صفحه: "
              labelDisplayedRows={({ count, page }) =>
                count > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "14px" }}>
                      تعداد کل ردیف ها: {count}
                    </Typography>
                    <Typography sx={{ ml: 4, fontSize: "14px" }}>
                      صفحه: {page + 1} از{" "}
                      {Math.ceil(count / requestState.page_size)}
                    </Typography>
                  </Box>
                )
              }
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ProductsPrices;
